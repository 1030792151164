'use client';

import React from 'react';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import { IconName } from '../icon/icon-names';
import Icon from '../icon/icon';
import { countries } from '@weski-monorepo/utils';

interface Props {
  countryCode: string;
}

export const CountryIcon = ({ countryCode }: Props) => {
  const icon = upperFirst(camelCase(countries.getCountryNameById(countryCode))) as IconName;
  return <Icon icon={icon} isMultiColor />;
};
