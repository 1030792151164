'use client';

import React, { useState } from 'react';
import styles from './accordion.module.scss';
import * as RadixAccordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import Icon from '../icon/icon';

interface AccordionProps {
  items: Array<{
    value: string;
    header: React.ReactNode;
    content: React.ReactNode;
    onOpen?: () => void;
  }>;
}

export const Accordion = ({ items }: AccordionProps) => {
  const [openItem, setOpenItem] = useState('');

  return (
    <RadixAccordion.Root
      type="single"
      className={classNames(styles.AccordionRoot)}
      collapsible
      value={openItem}
      onValueChange={(value) => {
        if (value) {
          const clickedItem = items.find((item) => item.value === value);
          if (clickedItem?.onOpen) clickedItem?.onOpen();
        }
        setOpenItem(value);
      }}
    >
      {items.map((item, index) => (
        <RadixAccordion.Item key={index} value={item.value} className={classNames(styles.AccordionItem)}>
          <AccordionTrigger>{item.header}</AccordionTrigger>
          <AccordionContent>{item.content}</AccordionContent>
        </RadixAccordion.Item>
      ))}
    </RadixAccordion.Root>
  );
};

const AccordionTrigger = React.forwardRef<HTMLButtonElement, RadixAccordion.AccordionTriggerProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <RadixAccordion.Header className={classNames(styles.AccordionHeader)}>
      <RadixAccordion.Trigger className={classNames(styles.AccordionTrigger, className)} {...props} ref={forwardedRef}>
        {children}
        <Icon icon="ChevronDown" className={classNames(styles.AccordionChevron)} />
      </RadixAccordion.Trigger>
    </RadixAccordion.Header>
  )
);

const AccordionContent = React.forwardRef<HTMLDivElement, RadixAccordion.AccordionContentProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <RadixAccordion.Content className={classNames(styles.AccordionContent, className)} {...props} ref={forwardedRef}>
      <div className="AccordionContentText">{children}</div>
    </RadixAccordion.Content>
  )
);
