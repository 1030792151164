import React, { useCallback } from 'react';
import merge from 'lodash/merge';
import styles from './by-resort-preferences.module.scss';
import { aboutYouChipMapper, countryChipMapper, importantPropertiesChipMapper } from './value-mappers';
import {
  destinationsResortPreferencesAboutYouOptions,
  destinationsResortPreferencesCountryOptions,
  destinationsResortPreferencesImportantPropertiesOptions,
} from './constants';
import {
  DestinationsResortPreferencesAboutYouOption,
  DestinationsResortPreferencesCountryOptions,
  DestinationsResortPreferencesImportantPropertiesOption,
  ResortPreferences,
} from '../../types';
import { useIsMobile } from '../../../../../../../hooks/use-is-mobile/use-is-mobile';
import { Typography, HorizontalRule, ChipSelectionSegment } from '../../../../../../core';

interface ByResortPreferencesProps {
  className?: string;
  preferences: ResortPreferences;
  onChange: (preferences: ResortPreferences) => void;
}

const ByResortPreferences = ({ className, preferences, onChange }: ByResortPreferencesProps) => {
  const setResortPreferences = useCallback(
    (p: any) => {
      const newResortPreference = merge(preferences, p);
      onChange(newResortPreference);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  const isMobile = useIsMobile();
  const columns = isMobile ? 2 : 3;

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.section}>
        <Typography variant="H100">Which countries interest you?</Typography>
        <ChipSelectionSegment
          options={destinationsResortPreferencesCountryOptions.map((value) => ({
            label: countryChipMapper[value].label,
            icon: countryChipMapper[value].icon,
            value,
            multiColorIcon: value !== 'flexible',
            selected: preferences.country[value],
          }))}
          maxVisible={isMobile ? undefined : 3}
          enableShowLess={false}
          columns={columns}
          onOptionClick={(value: DestinationsResortPreferencesCountryOptions) => {
            if (!preferences.country[value]) {
              setResortPreferences({
                country: {
                  ...Object.fromEntries(destinationsResortPreferencesCountryOptions.map((c) => [c, false])),
                  [value]: true,
                },
              });
            }
          }}
        />
      </div>

      <HorizontalRule />

      <div className={styles.section}>
        <Typography variant="H100">Tell us a little about you (optional)</Typography>
        <ChipSelectionSegment
          options={destinationsResortPreferencesAboutYouOptions.map((value) => ({
            label: aboutYouChipMapper[value].label,
            icon: aboutYouChipMapper[value].icon,
            value,
            selected: preferences.aboutYou[value],
          }))}
          columns={columns}
          onOptionClick={(value: DestinationsResortPreferencesAboutYouOption) => {
            setResortPreferences({
              aboutYou: {
                [value]: !preferences.aboutYou[value],
              },
            });
          }}
        />
      </div>

      <HorizontalRule />

      <div className={styles.section}>
        <Typography variant="H100">{`What's most important? (optional)`}</Typography>
        <ChipSelectionSegment
          options={destinationsResortPreferencesImportantPropertiesOptions.map((value) => ({
            label: importantPropertiesChipMapper[value].label,
            icon: importantPropertiesChipMapper[value].icon,
            value,
            selected: preferences.importantProperties[value],
          }))}
          columns={columns}
          onOptionClick={(value: DestinationsResortPreferencesImportantPropertiesOption) => {
            setResortPreferences({
              importantProperties: {
                [value]: !preferences.importantProperties[value],
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default ByResortPreferences;
