import React, { useMemo } from 'react';
import compact from 'lodash/compact';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { ResortOption, ResortSelectionOption, SkiAreaOption, VillageOption } from './types';
import ResortThumbnail from './resort-thumbnail/resort-thumbnail';
import { Resort } from '../use-resorts';
import { CountryIcon, Icon } from '../../../../../../core';
import { countries } from '@weski-monorepo/utils';
import { DestinationType } from '@weski-monorepo/types';
import { getDataTestId, TestPage } from '../../../../../../../utils/data-test-id';

const countryOrder = [
  'FR' /* France */,
  'BG' /* Bulgaria */,
  'AT' /* Austria */,
  'AD' /* Andorra */,
  'IT' /* Italy */,
  'CH' /* Switzerland */,
  'CA' /* Canada */,
  'US' /* USA */,
  'GE' /* Georgia */,
];

export function useResortOptions(resorts: Resort[]) {
  return useMemo(() => {
    const optionsArray: ResortSelectionOption[] = [];

    optionsArray.push({
      type: 'flexible',
      id: 0,
      name: "I'm flexible",
      thumbnail: <Icon icon="Globe" isMultiColor />,
      adminOnly: false,
      dataTestId: getDataTestId({ page: TestPage.SearchForm, component: 'destination-picker-option-flexible' }),
    });

    const resortOptions: ResortSelectionOption[] = [
      ...resorts
        .filter((resort) => resort.type === DestinationType.Resort)
        .map(
          (resort): ResortOption => ({
            id: resort.id,
            name: resort.name,
            thumbnail: <ResortThumbnail src={resort.images.thumbnail} alt={resort.name} />,
            adminOnly: resort.adminOnly,
            type: 'resort',
            aliases: compact([resort.alias, resort.country.name]),
            subtitle: resort.subtitle,
            resortId: resort.id,
            dataTestId: getDataTestId({
              page: TestPage.SearchForm,
              component: `destination-picker-option-${resort.name}`,
            }),
          })
        ),
      ...resorts
        .filter((resort) => resort.type === DestinationType.Village)
        .map(
          (village): VillageOption => ({
            id: village.id,
            villageName: village.name,
            name: village.name,
            thumbnail: <ResortThumbnail />,
            adminOnly: village.adminOnly,
            type: 'village',
            aliases: compact([village.country.name]),
            subtitle: village.subtitle,
            resortId: village.resortId,
            dataTestId: getDataTestId({
              page: TestPage.SearchForm,
              component: `destination-picker-option-${village.name}`,
            }),
          })
        ),
      ...resorts
        .filter((resort) => resort.type === DestinationType.SkiArea)
        .map(
          (skiArea): SkiAreaOption => ({
            id: skiArea.id,
            name: skiArea.name,
            thumbnail: <ResortThumbnail />,
            adminOnly: skiArea.adminOnly,
            type: 'skiArea',
            aliases: compact([skiArea.country.name]),
            subtitle: skiArea.subtitle,
            dataTestId: getDataTestId({
              page: TestPage.SearchForm,
              component: `destination-picker-option-${skiArea.name}`,
            }),
          })
        ),
    ];

    const resortOptionsByCountryCode = groupBy(
      resortOptions,
      (resortOption) => resorts.find((r) => r.id === resortOption.id)!.country.code
    );

    const countryCodesSorted = sortBy(
      Object.keys(resortOptionsByCountryCode),
      (countryCode) => countryOrder.indexOf(countryCode.toUpperCase()) + 1 || 1000
    );

    for (const countryCode of countryCodesSorted) {
      const thisCountryResortOptions = sortBy(resortOptionsByCountryCode[countryCode], (r) => r.name);
      optionsArray.push({
        thumbnail: <CountryIcon countryCode={countryCode} />,
        id: countryCode,
        type: 'country',
        name: countries.getCountryNameById(countryCode),
        adminOnly: thisCountryResortOptions.every((r) => r.adminOnly),
        subtitle: `Country`,
        aliases: thisCountryResortOptions.flatMap((r) => [r.name, ...(r.aliases || [])]),
      });

      optionsArray.push(...thisCountryResortOptions);
    }

    return optionsArray;
  }, [resorts]);
}
