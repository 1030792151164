import { InOrOutboundElement } from '../../flight/flight';

export interface FlightChange {
  bookingRef?: string;
  supplierBookingRef?: string;
  provider?: 'kiwi' | 'travelFusion' | 'webhook';
  cancelled: boolean;
  newFlightBooked: boolean;
  error: string;
  flightUpdates: Partial<InOrOutboundElement>[];
}

export interface FlightChangeRequest {
  flightChange: FlightChange;
}
