'use client';

import { FC } from 'react';
import styles from './service-highlight.module.scss';
import { Icon, Typography } from '..';
import { IconName } from '../icon/icon-names';
import classNames from 'classnames';

export interface ServiceHighlightComponentData {
  title: string;
  description: string;
  iconName: IconName;
  className?: string;
  onClickLink?: {
    text: string;
    onClick: () => void;
  };
  link?: {
    text: string;
    href: string;
  };
}

const ServiceHighlight: FC<ServiceHighlightComponentData> = ({
  title,
  description,
  iconName,
  link,
  onClickLink,
  className,
}) => {
  return (
    <div className={classNames(styles.serviceHighlightContainer, className)}>
      <div className={styles.relatedIconContainer}>
        <Icon height={60} width={60} isMultiColor={true} icon={iconName} />
      </div>
      <div className={styles.body}>
        <h3 className={styles.title}>{title}</h3>
        <Typography variant="P200" className={styles.description}>
          {description}
        </Typography>
        {link && (
          <a href={link.href} className={styles.linkContainer}>
            <Typography className={styles.link} variant="H200">
              {link.text}
            </Typography>
          </a>
        )}
        {onClickLink && (
          <div className={styles.onClickLink} onClick={onClickLink.onClick}>
            <Typography className={styles.link} variant="H200">
              {onClickLink.text}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceHighlight;
