'use client';

import React, { ReactNode, useCallback, useState } from 'react';
import styles from './segmented-group.module.scss';

interface Segment<T extends string> {
  value: T;
  label: string | ReactNode;
  dataTestId?: string;
}

interface Props<T extends string> {
  segments: Segment<T>[];
  onChoose?: (value: T) => void;
  defaultValue?: T;
  className?: string;
}

function SegmentedGroup<T extends string>({ segments, onChoose, defaultValue, className }: Props<T>) {
  const [selected, setSelected] = useState<T>(defaultValue || segments[0].value);

  const onValueChange = useCallback(
    (value: T) => {
      setSelected(value);
      onChoose?.(value);
    },
    [onChoose]
  );

  return (
    <div className={`${styles.container} ${className}`}>
      {segments.map((segment) => (
        <div
          className={`${styles.segment} ${selected === segment.value ? styles.selected : ''}`}
          onClick={() => {
            onValueChange(segment.value);
          }}
          key={segment.value}
          data-testid={segment.dataTestId}
        >
          {segment.label}
        </div>
      ))}
    </div>
  );
}

export default SegmentedGroup;
