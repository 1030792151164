import { ImageOverlayData } from '../../../core/image-with-overlay/image-with-overlay';

export const skiHolidayAccommodationTypes: ImageOverlayData[] = [
  {
    desktopOverlay: <h3>APARTMENTS</h3>,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722946154/App%20Assets/Homepage/New%20Home%20page%202024/Accommodation%20types/Apartment.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1724755976/App%20Assets/Homepage/New%20Home%20page%202024/Accommodation%20types/Mobile/Apartments.webp',
    link: '/ski-holidays/accommodation-types/ski-apartments',
    imageAlt: 'Snow-covered apartment buildings in a ski resort with a clear blue sky',
  },
  {
    desktopOverlay: <h3>SKI CHALETS</h3>,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722946154/App%20Assets/Homepage/New%20Home%20page%202024/Accommodation%20types/Chalet.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1724755977/App%20Assets/Homepage/New%20Home%20page%202024/Accommodation%20types/Mobile/Chalets.webp',

    link: '/ski-holidays/accommodation-types/ski-chalets',
    imageAlt: 'Cozy chalet with large windows and snow-covered surroundings in a ski resort',
  },
  {
    desktopOverlay: <h3>HOTELS</h3>,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722946153/App%20Assets/Homepage/New%20Home%20page%202024/Accommodation%20types/Hotel.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1724755977/App%20Assets/Homepage/New%20Home%20page%202024/Accommodation%20types/Mobile/Hotels.webp',
    link: '/ski-holidays/accommodation-types/ski-hotels',
    imageAlt: 'Ski resort hotel with snow-covered surroundings in a ski resort',
  },
];
