'use client';

import styles from './form-section-text-value.module.scss';
import Typography from '../../typography/typography';
import React from 'react';

interface Props {
  id: string;
  value?: string;
}
export const FormSectionTextValue = ({ id, value }: Props) => {
  return (
    <div className={styles.inputContainer}>
      <Typography variant="B400" mobileVariant="H100" id={id} className={styles.input}>
        {value}
      </Typography>
    </div>
  );
};
