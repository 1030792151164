'use client';

import { FC } from 'react';
import styles from './about-us.module.scss';
import {
  LegacyBreadcrumbs,
  Button,
  CdnImage,
  ImageWithOverlay,
  InfoSection,
  InlineVideo,
  Responsive,
  Separator,
} from '../../core';
import { AboutUsContent } from './about-us-content';
import classNames from 'classnames';
import HighlightNumbersSection from './highlight-numbers-section/highlight-numbers-section';
import TrustPilotSection from '../trust-pilot-section/trust-pilot-section';

const AboutUsSeparator = () => <Separator className={styles.aboutUsPageSeparator} />;

const AboutUs: FC<{ pathname: string; goToSkiDealsHref?: string }> = ({
  pathname,
  goToSkiDealsHref = '/ski-resorts',
}) => {
  const { heyThere, endlessPossibilities, createdBySkiersForSkiers } = AboutUsContent;

  return (
    <div className={styles.container}>
      <Responsive.DesktopOnly className={styles.imageOverlayContainer}>
        <ImageWithOverlay
          options={{ height: 300, width: 1920 }}
          height={300}
          width={'100%'}
          alt="Ski resort at night"
          containerClassName={styles.themeImageContainer}
          overlayClassName={styles.themeImageOverlay}
          src="https://res.cloudinary.com/ht4mr7djk/image/upload/v1724152377/App%20Assets/About/About%20us%202024/Cover/Cover_desktop_3.webp"
        >
          <h1>
            WE MAKE SKI <br /> HOLIDAYS EASY
          </h1>
        </ImageWithOverlay>
      </Responsive.DesktopOnly>
      <Responsive.MobileOnly className={styles.imageOverlayContainer}>
        <ImageWithOverlay
          height={220}
          width={'100%'}
          alt="Ski resort at night"
          containerClassName={styles.themeImageContainer}
          overlayClassName={styles.themeImageOverlay}
          src="https://res.cloudinary.com/ht4mr7djk/image/upload/v1724064588/App%20Assets/About/About%20us%202024/Cover/About_us_-_cover_mobile.webp"
        >
          <h1 className={styles.headTitle}>
            WE MAKE SKI <br /> HOLIDAYS EASY
          </h1>
        </ImageWithOverlay>
      </Responsive.MobileOnly>
      <div className={styles.body}>
        <div className={styles.row}>
          <div className={styles.col}>
            <LegacyBreadcrumbs currentPathname={pathname} />
            <InfoSection
              titleClassName={styles.heyThereTitle}
              title={heyThere.title}
              content={heyThere.content()}
              isMainInfo={heyThere.isMainInfo}
            />
          </div>
          <div className={classNames(styles.col, styles.circleVideoContainer)}>
            <InlineVideo
              className={styles.circleVideo}
              mainVideo={
                'https://res.cloudinary.com/ht4mr7djk/video/upload/v1663075034/App%20Assets/Homepage/radar_small.mp4'
              }
              format={'mp4'}
            />
          </div>
        </div>
        <AboutUsSeparator />
        <div className={styles.row}>
          <Responsive.MobileOnly className={classNames(styles.col, styles.endlessPossibilitiesContainer)}>
            <InfoSection title={endlessPossibilities.title} content={endlessPossibilities.content()} />
          </Responsive.MobileOnly>
          <div className={styles.col}>
            <Responsive.DesktopOnly>
              <CdnImage
                alt="One platform, endless possibilities"
                options={{ width: 1062, height: 709 }}
                width={593}
                height={396}
                src="https://res.cloudinary.com/ht4mr7djk/image/upload/v1724064739/App%20Assets/About/About%20us%202024/Phone%20visual/Phone_visual_-_desktop.webp"
              />
            </Responsive.DesktopOnly>
            <Responsive.MobileOnly>
              <CdnImage
                alt="One platform, endless possibilities"
                width={'100%'}
                src="https://res.cloudinary.com/ht4mr7djk/image/upload/v1724064740/App%20Assets/About/About%20us%202024/Phone%20visual/Phone_visual_-_mobile.webp"
              />
            </Responsive.MobileOnly>
          </div>
          <Responsive.DesktopOnly className={styles.col}>
            <InfoSection
              className={styles.aboutUsInfoSectionRight}
              title={endlessPossibilities.title}
              content={endlessPossibilities.content()}
            />
          </Responsive.DesktopOnly>
        </div>
        <AboutUsSeparator />
        <div className={classNames(styles.row, styles.createdBySkiersContainer)}>
          <div className={classNames(styles.col, styles.createdBySkiersInfoSection)}>
            <InfoSection
              className={styles.aboutUsInfoSectionLeft}
              title={createdBySkiersForSkiers.title}
              content={createdBySkiersForSkiers.content()}
            />
          </div>
          <CdnImage
            options={{
              width: 592,
              height: 433,
            }}
            alt="Fun ski girl"
            className={styles.createdBySkiersImage}
            src="https://res.cloudinary.com/ht4mr7djk/image/upload/v1607006704/App%20Assets/About/mission-left.jpg"
          />
        </div>
        <AboutUsSeparator />
        <div className={styles.readyToTrySection}>
          <div className={styles.text}>
            <div className={styles.title}>Ready to go?</div>
            <div className={styles.subDescription}>Start your journey by finding your perfect ski resort today</div>
          </div>
          <a href={goToSkiDealsHref} style={{ textDecoration: 'none' }}>
            <Button variant="main" text="Explore destinations"></Button>
          </a>
        </div>
        <AboutUsSeparator />
        <HighlightNumbersSection />
        <AboutUsSeparator />
        <TrustPilotSection className={styles.trustPilotSection} isTitleSubHeadline={true} />
      </div>
    </div>
  );
};

export default AboutUs;
