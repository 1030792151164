import {
  destinationsResortPreferencesAboutYouOptions,
  destinationsResortPreferencesCountryOptions,
  destinationsResortPreferencesImportantPropertiesOptions,
} from './destination-popover/by-resort-preferences/constants';

export type DestinationsResortPreferencesAboutYouOption = (typeof destinationsResortPreferencesAboutYouOptions)[number];
export type DestinationsResortPreferencesImportantPropertiesOption =
  (typeof destinationsResortPreferencesImportantPropertiesOptions)[number];
export type DestinationsResortPreferencesCountryOptions = (typeof destinationsResortPreferencesCountryOptions)[number];

export enum DestinationType {
  ByPreference = 'byPreference',
  Specific = 'specific',
}

export type CountryResortPreferences = Record<DestinationsResortPreferencesCountryOptions, boolean>;

export type AboutYouResortPreferences = Record<DestinationsResortPreferencesAboutYouOption, boolean>;
export type ImportantPropertiesResortPreferences = Record<
  DestinationsResortPreferencesImportantPropertiesOption,
  boolean
>;

export interface ResortPreferences {
  country: CountryResortPreferences;
  aboutYou: AboutYouResortPreferences;
  importantProperties: ImportantPropertiesResortPreferences;
}

export enum PickType {
  Resort = 'resort',
  Village = 'village',
  SkiArea = 'skiArea',
  Country = 'country',
  Flexible = 'flexible',
}

export interface FlexiblePick {
  flexible: boolean;
}
export interface CountryPick {
  countryCode: string;
}
export interface ResortPick {
  resort: string | number;
  resortName?: string;
}
export interface VillagePick {
  resort: string | number;
  villageName?: string;
}
export interface SkiAreaPick {
  skiArea: string;
}

export type DestinationBySpecificPicks = (FlexiblePick | CountryPick | ResortPick | VillagePick | SkiAreaPick)[];

export interface DestinationSearch {
  type: DestinationType;
  pickType: PickType;
  bySpecificPicks: DestinationBySpecificPicks;
  resortPreferences: ResortPreferences;
}
