import styles from './group-size-popover.module.scss';
import React from 'react';
import { useIsMobile } from '../../../../../../hooks/use-is-mobile/use-is-mobile';
import { Button, MobileModalHeader, Select, SizeSelect, Typography } from '../../../../../core';
import { getDataTestId, TestPage, addDataTestAttribute } from '../../../../../../utils/data-test-id';

const MAX_GROUP_SIZE = 30;

export interface GroupSize {
  adults: number;
  children: number;
  childrenAges: (number | null)[];
}

const ChildrenAgeSelectOptions = [
  {
    value: '0',
    label: '< 1 year',
    dataTestId: getDataTestId({ page: TestPage.SearchForm, component: 'group-size-picker-child-age-option-0' }),
  },
  {
    value: '1',
    label: '1 year',
    dataTestId: getDataTestId({ page: TestPage.SearchForm, component: 'group-size-picker-child-age-option-1' }),
  },
  ...Array.from({ length: 16 }, (_, i) => ({
    value: (i + 2).toString(),
    label: `${i + 2} years`,
    dataTestId: getDataTestId({ page: TestPage.SearchForm, component: `group-size-picker-child-age-option-${i + 2}` }),
  })),
];

interface GroupSizePopoverProps {
  onDone: (isBack?: boolean) => void;
  groupSize: GroupSize;
  onChange: (groupSize: GroupSize) => void;
}

export const GroupSizePopover = ({ onDone, groupSize, onChange }: GroupSizePopoverProps) => {
  const isMobile = useIsMobile();

  const handleAdultChange = (size: number) => {
    onChange({ ...groupSize, adults: size });
  };

  const handleChildrenChange = (size: number) => {
    const childrenAges =
      size > groupSize.children ? [...groupSize.childrenAges, null] : groupSize.childrenAges.slice(0, -1);
    onChange({ ...groupSize, children: size, childrenAges });
  };

  const updateChildAge = (index: number) => (value: string) => {
    onChange({
      ...groupSize,
      childrenAges: [
        ...groupSize.childrenAges.slice(0, index),
        Number(value),
        ...groupSize.childrenAges.slice(index + 1),
      ],
    });
  };

  const isInvalid = groupSize.childrenAges.includes(null);
  return (
    <div className={styles.container}>
      {isMobile && (
        <MobileModalHeader
          onBackClick={() => {
            onDone(true);
          }}
          isBackDisabled={isInvalid}
        >{`Who's coming?`}</MobileModalHeader>
      )}
      <div className={styles.content}>
        <div className={styles.sizeRow}>
          <Typography variant="P300">Adults</Typography>
          <SizeSelect
            min={1}
            max={MAX_GROUP_SIZE - groupSize.children}
            size={groupSize.adults}
            onChange={handleAdultChange}
            dataTestPrefix={getDataTestId({ page: TestPage.SearchForm, component: 'group-size-picker-adults' })}
          />
        </div>
        <div className={styles.sizeRow}>
          <Typography variant="P300">Children</Typography>
          <SizeSelect
            min={0}
            max={MAX_GROUP_SIZE - groupSize.adults}
            size={groupSize.children}
            onChange={handleChildrenChange}
            dataTestPrefix={getDataTestId({ page: TestPage.SearchForm, component: 'group-size-picker-children' })}
          />
        </div>
        {Boolean(groupSize.children) && (
          <div className={styles.agesContainer}>
            <Typography variant="P100" className={styles.agesText}>{`CHILD'S AGE UPON RETURN`}</Typography>
            <div className={styles.agesSelects}>
              {groupSize.childrenAges.map((age, index) => (
                <Select
                  key={index}
                  onValueChange={updateChildAge(index)}
                  className={styles.ageSelect}
                  required
                  items={ChildrenAgeSelectOptions}
                  placeholder="Add age"
                  value={age?.toString()}
                  width="117px"
                  dataTestId={getDataTestId({
                    page: TestPage.SearchForm,
                    component: 'group-size-picker-child-age-select',
                  })}
                />
              ))}
            </div>
          </div>
        )}
        {isMobile && <div className={styles.fakeBottomMargin} />}
        <div className={styles.footer}>
          <Button
            className={styles.doneBtn}
            text="Done"
            textVariant="B400"
            variant="main"
            onClick={() => {
              onDone();
            }}
            disabled={isInvalid}
            {...addDataTestAttribute({ page: TestPage.SearchForm, component: 'group-size-picker-done-btn' })}
          />
        </div>
      </div>
    </div>
  );
};
