'use client';

import { FC } from 'react';
import styles from './separator.module.scss';
import classNames from 'classnames';

const Separator: FC<{ className?: string }> = ({ className }) => {
  return <div className={classNames(styles.separator, className)}></div>;
};

export default Separator;
