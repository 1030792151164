import { IInfoSection } from '../../core';
import React from 'react';

export const BookWithConfidenceContent: {
  bookWithConfidence: IInfoSection;
  completeFinancialProtection: IInfoSection;
  perfectlyConnectedTrips: IInfoSection;
  flexibleCancellation: IInfoSection;
  supportWhenYouNeedIt: IInfoSection;
  moreWaysToPay: IInfoSection;
  skiSatisfaction: IInfoSection;
} = {
  bookWithConfidence: {
    title: 'Book with confidence',
    content: () => (
      <>
        Every WeSki holiday comes with our worry-free protections, designed to give you total peace of mind from start
        to finish. Simply put, if there&apos;s an issue with any part of your trip, we&apos;ll sort it.
      </>
    ),
    isMainInfo: true,
  },
  completeFinancialProtection: {
    title: 'Complete financial protection',
    content: () => (
      <>
        When you book with us, you&apos;ll get the highest level of financial protection for total peace of mind.
        <br />
        <ul>
          <li>
            If you book a flight-inclusive package, you will be financially protected by our ATOL licence (number
            11341).
          </li>
          <li>
            If you book a package without a flight, you will be financially protected by ABTOT (licence number 5497).
          </li>
        </ul>
      </>
    ),
  },
  perfectlyConnectedTrips: {
    title: 'Perfectly connected trips',
    content: () => (
      <>
        <p>
          Our platform is designed to deliver your perfect ski holiday effortlessly, and at the best possible price.
        </p>
        <p>
          The smart trip builder scours hundreds of combinations of flights, transfers and accommodation instantly,
          offering you the best-value options that it knows will make for a smooth trip. You can choose whatever you
          like, knowing it&apos;s already going to flow perfectly. And, if you find the same holiday elsewhere for less,
          we&apos;ll beat the price!
        </p>
      </>
    ),
  },
  flexibleCancellation: {
    title: 'Flexible cancellation',
    content: () => (
      <>
        <p>
          Life happens, we get it - and we'll make sure you have all the information and support you need if it does.
        </p>
        <p>
          You'll be given cancellation terms with specific dates attached for your trip before you book, so you know
          what's possible if you can't travel. And if you need to move or make changes to your trip, we can help you do
          that too.
        </p>
      </>
    ),
  },
  supportWhenYouNeedIt: {
    title: 'Support when you need it',
    content: () => (
      <>
        <p>
          From the moment you make your booking, all the way to the slopes and home again, our dedicated ski experts
          will be there to help.
        </p>
        <ul>
          <li>
            Our general Customer Service team is available 7 days a week from 9am to 10pm via email, phone or web chat,
            for any questions you might have or changes you&apos;d like to make.
          </li>
          <li>
            Our On-Trip team will be available 24/7 while you&apos;re away for anything you might need. You&apos;ll be
            given all relevant contact details, including a dedicated WhatsApp number, in your trip documentation.
          </li>
        </ul>
      </>
    ),
  },
  moreWaysToPay: {
    title: 'More ways to pay',
    content: (args) => {
      return (
        <>
          <p>Our multi-pay options are designed to put you in control of your trip:</p>
          <ul>
            <li>
              {args!.fixedDepositValue ? `${args!.fixedDepositValue}pp` : 'Low'} deposits: our deposits are available to
              anyone booking more than {args!.daysToPay ?? 90} days in advance of their trip.
            </li>
            <li>
              Pay your way: once you&apos;ve paid the deposit, you can then settle the remaining balance however you
              like up to {args!.daysToPay ?? 90} days before your departure - whether it&apos;s a big chunk or little
              increments, the choice is yours.
            </li>
            <li>
              Group split payments: sign up the paying members of your squad and everyone is responsible for their own
              part. No more big deposits for the organisers!
            </li>
            <li>
              Interest-free options: split your payments into three manageable amounts with our friends at PayPal,
              completely interest-free.
            </li>
          </ul>
        </>
      );
    },
  },
  skiSatisfaction: {
    title: 'Ski satisfaction',
    content: () => (
      <>
        <p>Over 70,000 happy skiers have hit the slopes with us across hundreds of global resorts.</p>
        <p>Don&apos;t just take our word for it - we&apos;re proud members of Trustpilot for independent reviews:</p>
      </>
    ),
  },
};
