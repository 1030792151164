'use client';

import React, { ChangeEventHandler, forwardRef } from 'react';
import styles from './disguised-placeholder-input.module.scss';
import classNames from 'classnames';

export interface DisguisedPlaceholderInputProps {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  className?: string;
  id?: string;
}
const DisguisedPlaceholderInput = forwardRef<HTMLInputElement, DisguisedPlaceholderInputProps>(
  ({ value, onChange, placeholder, className, id }: DisguisedPlaceholderInputProps, ref) => {
    return (
      <input
        id={id}
        type="text"
        autoComplete="off"
        className={classNames(styles.inputElement, className)}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
      />
    );
  }
);

DisguisedPlaceholderInput.displayName = 'DisguisedPlaceholderInput';

export default DisguisedPlaceholderInput;
