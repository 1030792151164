import React, { useEffect } from 'react';
import { GroupSize, GroupSizePopover } from './group-size-popover/group-size-popover';
import styles from './group-size-form-section.module.scss';
import { GroupSizeFormSectionValue } from './group-size-form-section-value/group-size-form-section-value';
import classNames from 'classnames';
import { FormSection, Icon } from '../../../../core';

interface GroupSizeFormSectionProps {
  value: GroupSize;
  onChange: (value: GroupSize) => void;
  showIcon?: boolean;
  className?: string;
  onClose: (isBack?: boolean) => void;
  showLabel?: boolean;
  scrollToTopOnClose?: boolean;
  forceOpen?: boolean;
}

const TITLE = 'Guests';

export const GroupSizeFormSection = ({
  value,
  onChange,
  showIcon = true,
  showLabel = true,
  className,
  onClose,
  forceOpen = false,
  scrollToTopOnClose,
}: GroupSizeFormSectionProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    setIsOpen(Boolean(forceOpen));
  }, [forceOpen]);

  const setClosed = (isBack?: boolean) => {
    onClose(isBack);
    setIsOpen(false);
  };

  const setOpen = () => {
    setIsOpen(true);
  };

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen && value.childrenAges.includes(null)) {
      return;
    }

    if (isOpen) {
      setOpen();
      return;
    }

    setClosed();
  };

  return (
    <FormSection
      open={isOpen}
      onOpenChange={onOpenChange}
      dataTestName="group-size-picker"
      autoPopoverWidth
      className={classNames(styles.formSection, className)}
      {...(showIcon ? { icon: <Icon icon="Group" /> } : {})}
      {...(showLabel ? { label: TITLE } : {})}
      description={TITLE}
      scrollToTopOnClose={scrollToTopOnClose}
      tabIndex={0}
      value={<GroupSizeFormSectionValue adultsCount={value.adults} childrenCount={value.children} />}
    >
      <GroupSizePopover groupSize={value} onChange={onChange} onDone={setClosed} />
    </FormSection>
  );
};
