import React, { useCallback, useMemo } from 'react';
import styles from './by-specific-resort.module.scss';
import { autoCompletePredicate } from './auto-complete-predicate';
import EmptyState from './empty-state/empty-state';
import { SelectContent } from './select/select-content/select-content';
import { SelectOption } from './select/select-option/select-option';
import { DestinationBySpecificPicks, DestinationSearch, DestinationType, PickType } from '../../types';
import { Resort } from '../use-resorts';
import { useResortOptions } from './use-resort-options';
import { ResortSelectionOption } from './types';
import { HorizontalRule } from '../../../../../../core';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { AnalyticsEvents } from '@weski-monorepo/types';

interface BySpecificResortsProps {
  autoCompleteTextInput: string;
  canSearchByResortPreference: boolean;
  onChange: (value: DestinationSearch) => void;
  value: DestinationSearch;
  resorts: Resort[];
}

const BySpecificResorts = ({
  autoCompleteTextInput,
  onChange,
  value,
  resorts,
  canSearchByResortPreference,
}: BySpecificResortsProps) => {
  const analytics = useAnalytics();
  const resortOptions = useResortOptions(resorts);

  const selectedResortOption = useMemo(() => {
    return resortOptions.find((option) => {
      if (option.type === 'country') {
        return (value.bySpecificPicks[0] as any)?.countryCode === option.id;
      }
      if (option.type === 'flexible') {
        return (value.bySpecificPicks[0] as any)?.flexible;
      }
      if (option.type === 'skiArea') {
        return (value.bySpecificPicks[0] as any)?.skiArea === option.id;
      }
      if (option.type === 'village') {
        return (
          (value.bySpecificPicks[0] as any)?.resort === option.resortId &&
          (value.bySpecificPicks[0] as any)?.villageName &&
          (value.bySpecificPicks[0] as any)?.villageName === option.villageName
        );
      }
      if (option.type === 'resort' && !(value.bySpecificPicks[0] as any)?.villageName) {
        return (value.bySpecificPicks[0] as any)?.resort === option.id;
      }
      return null;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const setSelectedResortOption = useCallback(
    (option: ResortSelectionOption) => {
      let pick: DestinationBySpecificPicks[number];
      let pickType: PickType;
      if (option.type === 'country') {
        pick = { countryCode: option.id };
        pickType = PickType.Country;
        analytics(AnalyticsEvents.DESTINATION_PICKER_COUNTRY_SELECT, { countryCode: option.id });
      } else if (option.type === 'flexible') {
        pick = { flexible: true };
        pickType = PickType.Flexible;
      } else if (option.type === 'skiArea') {
        pick = { skiArea: option.id.toString() };
        pickType = PickType.SkiArea;
      } else if (option.type === 'village') {
        pick = { resort: option.resortId!, villageName: option.villageName };
        pickType = PickType.Village;
      } else {
        pick = { resort: option.id, resortName: option.name };
        pickType = PickType.Resort;
      }
      onChange({
        type: DestinationType.Specific,
        pickType,
        bySpecificPicks: [pick],
        resortPreferences: value.resortPreferences,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  const filteredResortOptions = useMemo(() => {
    return resortOptions.filter((r) => autoCompletePredicate(autoCompleteTextInput, r));
  }, [resortOptions, autoCompleteTextInput]);

  if (filteredResortOptions.length === 0) {
    return <EmptyState />;
  }

  return (
    <SelectContent
      value={selectedResortOption!}
      items={filteredResortOptions.map((option, index) => ({
        indentation: ['resort', 'village', 'skiArea'].includes(option.type) ? 1 : 0,
        value: option,
        canSelect: true,
        component: (props) => (
          <>
            {index !== 0 && option.type === 'country' && (
              <>
                <div className={`${styles.pad} ${styles.transparent}`} />
                <HorizontalRule className={styles.highZIndexHR} />
                <div className={`${styles.pad} ${styles.white}`} />
              </>
            )}
            <SelectOption
              {...props}
              option={option}
              canSearchByResortPreference={canSearchByResortPreference}
              sticky={['flexible', 'country'].includes(option.type)}
            />
          </>
        ),
        adminOnly: option.adminOnly,
      }))}
      onChange={(v: ResortSelectionOption) => {
        setSelectedResortOption(v);
      }}
    />
  );
};

export default BySpecificResorts;
