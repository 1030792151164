import { IconName } from '../../../core/icon/icon-names';

export interface SkiDestinationCardData {
  href: string;
  iconName: IconName;
  title: string;
  className?: string;
  numberOfResorts: number;
}

export const SkiDestinationCardsData: SkiDestinationCardData[] = [
  {
    title: 'France',
    href: '/ski-holidays/france',
    iconName: 'France',
    numberOfResorts: 27,
  },
  {
    title: 'Italy',
    href: '/ski-holidays/italy',
    iconName: 'Italy',
    numberOfResorts: 15,
  },
  {
    title: 'Switzerland',
    href: '/ski-holidays/switzerland',
    iconName: 'Switzerland',
    numberOfResorts: 10,
  },
  {
    title: 'Austria',
    href: '/ski-holidays/austria',
    iconName: 'Austria',
    numberOfResorts: 29,
  },
  {
    title: 'Andorra',
    href: '/ski-holidays/andorra',
    iconName: 'Andorra',
    numberOfResorts: 5,
  },
  {
    title: 'Bulgaria',
    href: '/ski-holidays/bulgaria',
    iconName: 'Bulgaria',
    numberOfResorts: 2,
  },
];
