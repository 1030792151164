'use client';

import React, { Children, PropsWithChildren } from 'react';
import styles from './breadcrumbs.module.scss';
import cx from 'classnames';
import Icon from '../icon/icon';

interface Props {
  className?: string;
}

const Root = (props: PropsWithChildren<Props>) => {
  const children = Children.toArray(props.children);
  return (
    <div className={cx(styles.container, props.className)}>
      {children.map((child, index) => (
        <>
          {index !== 0 && <Icon icon="ChevronRight" />}
          {child}
        </>
      ))}
    </div>
  );
};

const Item = ({
  bold,
  noPointer,
  className,
  children,
  href,
}: PropsWithChildren<{
  bold?: boolean;
  noPointer?: boolean;
  className?: string;
  href?: string;
}>) => (
  <a
    className={cx(styles.breadCrumbItem, { [styles.bold]: bold, [styles.noPointer]: noPointer }, className)}
    href={href}
  >
    {children}
  </a>
);

export default {
  Root,
  Item,
};
