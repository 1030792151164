'use client';

import * as Dialog from '@radix-ui/react-dialog';
import React, { PropsWithChildren } from 'react';
import styles from './modal.module.scss';
import cx from 'classnames';
import Icon from '../icon/icon';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';

type Mode = 'modal' | 'sideSheet' | 'bottomSheet' | 'topSheet';

export interface ModalProps {
  open: boolean;
  closable?: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
  className?: string;
  fullScreenOnMobile?: boolean;
  mode?: Mode;
}

export const Modal = ({
  open,
  closable = true,
  onClose = () => {},
  showCloseButton = true,
  children,
  className,
  fullScreenOnMobile = false,
  mode = 'modal',
}: PropsWithChildren<ModalProps>) => {
  const isSheet = mode !== 'modal';

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={styles.dialogOverlay}
          onClick={() => {
            if (closable) {
              /*
               there is a very weird behavior that causes the overlay to be clicked when the 'select' content pops up
               it happens only if  RadixSelect.Content.position="popper"
               I feel like this is a bug in RadixSelect
               anyway, this is the hack to solve this
               */
              if (document.querySelector('div[data-radix-popper-content-wrapper]')) {
                return;
              }
              onClose();
            }
          }}
        />
        <Dialog.Content
          className={cx(
            styles.dialogContent,
            {
              [styles.fullScreenOnMobile]: fullScreenOnMobile,
              [styles.sheet]: isSheet,
              [styles.sideSheet]: mode === 'sideSheet',
              [styles.bottomSheet]: mode === 'bottomSheet',
              [styles.topSheet]: mode === 'topSheet',
            },
            className
          )}
        >
          <VisuallyHidden.Root>
            <Dialog.Title></Dialog.Title>
            <Dialog.Description></Dialog.Description>
          </VisuallyHidden.Root>
          {closable && showCloseButton && (
            <Icon
              icon="X"
              className={cx(styles.closeButton, {
                [styles.sideSheet]: isSheet,
              })}
              width={isSheet ? 20 : 24}
              onClick={() => {
                onClose();
              }}
            />
          )}
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
