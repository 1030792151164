import { ResortSelectionOption } from './types';

export function autoCompletePredicate(autoCompleteInput: string, resortSelectionOption: ResortSelectionOption) {
  autoCompleteInput = autoCompleteInput.toLowerCase().trim();
  if (autoCompleteInput.length === 0) {
    return true;
  }

  if (namePredicate(autoCompleteInput, resortSelectionOption.name)) {
    return true;
  }

  return (resortSelectionOption.aliases || []).some((alias) => namePredicate(autoCompleteInput, alias));
}

function namePredicate(autoCompleteInput: string, name: string) {
  return name.toLowerCase().includes(autoCompleteInput);
}
