import React from 'react';
import styles from './destination-form-section-value.module.scss';
import { useTripDestinationsValueText } from './use-destination-value-text';
import { DestinationSearch, DestinationType } from '../types';
import DisguisedPlaceholderInput from '../../../../../core/disguised-placeholder-input/disguised-placeholder-input';
import { Destination } from '@weski-monorepo/types';
import { FormSectionTextValue, Responsive } from '../../../../../core';

interface DestinationFormSectionValueProps {
  value: DestinationSearch;
  autoCompleteTextInput: string;
  onAutoCompleteTextInputChange: (text: string) => void;
  destinations: Destination[];
  inputRef: React.RefObject<HTMLInputElement>;
}

const DestinationFormSectionValue = ({
  value,
  autoCompleteTextInput,
  onAutoCompleteTextInputChange,
  destinations,
  inputRef,
}: DestinationFormSectionValueProps) => {
  const isByPreference = value.type === DestinationType.ByPreference;
  const destinationDescription = useTripDestinationsValueText(value, destinations);

  if (isByPreference) {
    return <FormSectionTextValue id="destination-value" value={destinationDescription} />;
  }

  return (
    <>
      <Responsive.MobileOnly>
        <FormSectionTextValue id="destination-value" value={destinationDescription} />
      </Responsive.MobileOnly>
      <Responsive.DesktopOnly className={styles.desktopContainer}>
        <DisguisedPlaceholderInput
          value={autoCompleteTextInput}
          onChange={(e) => {
            onAutoCompleteTextInputChange(e.target.value);
          }}
          placeholder={destinationDescription}
          className={styles.disguisedInput}
          ref={inputRef}
          id="destination-value"
        />
      </Responsive.DesktopOnly>
    </>
  );
};

export default DestinationFormSectionValue;
