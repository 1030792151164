'use client';

import { FC } from 'react';
import styles from './popular-ski-resorts-section.module.scss';
import { PopularSkiResortItem } from './popular-ski-resort-item/popular-ski-resort-item';
import { PopularSkiResortData, PopularSkiResortsData } from './popular-ski-resorts-data';
import classNames from 'classnames';
import { Button, Responsive, SectionHeadline } from '../../../core';

const PopularSkiResortsSection: FC<{ seeAllLink: string; className?: string; data?: PopularSkiResortData[] }> = ({
  className,
  data,
  seeAllLink,
}) => {
  return (
    <div className={classNames(styles.sectionContainer, className)}>
      <SectionHeadline
        title="Popular ski resorts"
        className={styles.title}
        link={{ text: 'See all resorts', href: seeAllLink }}
      />
      <div className={styles.bodyContainer}>
        {(data ?? PopularSkiResortsData).map((skiResort, index) => {
          const { imageSrc, title, subTitle, href } = skiResort;
          return (
            <PopularSkiResortItem
              key={'PopularSkiResortItem' + index}
              imageSrc={imageSrc}
              title={title}
              subTitle={subTitle}
              href={href}
            />
          );
        })}
      </div>
      <Responsive.MobileOnly>
        <Button variant="outlined" className={styles.seeAllResortsButton} hrefLink="/ski-resorts">
          See all resorts
        </Button>
      </Responsive.MobileOnly>
    </div>
  );
};

export default PopularSkiResortsSection;
