import { IconName } from '../../../../../core/icon/icon-names';

interface Holiday {
  name: string;
  label: string;
  icon: IconName;
  optionExpiryDate: Date;
}

export const holidays: Holiday[] = [
  {
    name: 'christmas',
    label: 'Christmas',
    icon: 'ChristmasBall',
    optionExpiryDate: new Date('2024-12-22'),
  },
  {
    name: 'easter',
    label: 'Easter',
    icon: 'EasterEgg',
    optionExpiryDate: new Date('2025-04-10'), // TODO:
  },
  {
    name: 'halfTerm',
    label: 'Half Term',
    icon: 'HalfAlarm',
    optionExpiryDate: new Date('2025-02-12'), // TODO:
  },
  {
    name: 'newYear',
    label: 'New Year',
    icon: 'Confetti',
    optionExpiryDate: new Date('2024-12-22'), // TODO:
  },
];
