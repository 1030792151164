'use client';

import styles from './size-select.module.scss';
import { SizeSelectButton } from './size-select-btn/size-select-btn';
import Typography from '../typography/typography';

interface SizeSelectProps {
  min: number;
  max: number;
  size: number;
  onChange: (size: number) => void;
  dataTestPrefix?: string;
}

export const SizeSelect = ({ min, max, size, onChange, dataTestPrefix }: SizeSelectProps) => {
  const isDecreaseDisabled = size <= min;
  const isIncreaseDisabled = size >= max;

  const onSizeIncrease = () => {
    onChange(size + 1);
  };

  const onSizeDecrease = () => {
    onChange(size - 1);
  };

  return (
    <div className={styles.sizeSelect}>
      <SizeSelectButton
        onClick={onSizeDecrease}
        text="-"
        disabled={isDecreaseDisabled}
        {...(dataTestPrefix ? { 'data-testid': `${dataTestPrefix}-decrease-btn` } : {})}
      />
      <Typography className={styles.sizeText} variant="P300">
        {size}
      </Typography>
      <SizeSelectButton
        onClick={onSizeIncrease}
        text="+"
        disabled={isIncreaseDisabled}
        {...(dataTestPrefix ? { 'data-testid': `${dataTestPrefix}-increase-btn` } : {})}
      />
    </div>
  );
};
