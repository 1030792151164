'use client';

import * as Progress from '@radix-ui/react-progress';
import styles from './progress-bar.module.scss';

interface Props {
  value: number;
  max: number;
}

export const ProgressBar = ({ value, max }: Props) => (
  <Progress.Root value={value} max={max} className={styles.ProgressRoot}>
    <Progress.Indicator
      className={styles.ProgressIndicator}
      style={{ transform: `translateX(-${100 - (value * 100) / max}%)` }}
    />
  </Progress.Root>
);
