import { Typography } from '../../../core';
import { ImageOverlayData } from '../../../core/image-with-overlay/image-with-overlay';
import styles from './popular-ski-deals-section.module.scss';
import React from 'react';

const SkiDealOverlay: React.FC<{ title: string }> = ({ title }) => {
  return (
    <h3 className={styles.skiDealSubTitle}>
      <Typography variant="H650" className={styles.subtitle}>
        {title}
      </Typography>
      <Typography variant="H650" className={styles.subtitle}>
        SKI DEALS
      </Typography>
    </h3>
  );
};

export const skiDealsCategories: ImageOverlayData[] = [
  {
    desktopOverlay: <SkiDealOverlay title="FRANCE" />,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945464/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Desktop/France.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945644/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Mobile/France.webp',
    imageAlt: 'Scenic view of a ski resort in France',
    link: '/ski-deals/france-ski-deals',
  },
  {
    desktopOverlay: <SkiDealOverlay title="BULGARIA" />,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945463/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Desktop/Bulgaria.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945642/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Mobile/Bulgaria.webp',
    imageAlt: 'Scenic view of a ski resort in Bulgaria',
    link: '/ski-deals/bulgaria-ski-deals',
  },
  {
    desktopOverlay: <SkiDealOverlay title="ITALY" />,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945464/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Desktop/Italy.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945646/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Mobile/Italy.webp',
    imageAlt: 'Italian Dolomites snowy landscape',
    link: '/ski-deals/italy-ski-deals',
  },
  {
    desktopOverlay: <SkiDealOverlay title="AUSTRIA" />,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945463/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Desktop/Austria.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945642/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Mobile/Austria.webp',
    imageAlt: 'Snow-covered mountains in Austria ski resort',
    link: '/ski-deals/austria-ski-deals',
  },
  {
    desktopOverlay: <SkiDealOverlay title="SWITZERLAND" />,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945465/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Desktop/Switzerland.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945644/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Mobile/Switzerland.webp',
    imageAlt: 'Swiss Alps ski resort',
    link: '/ski-deals/switzerland-ski-deals',
  },
  {
    desktopOverlay: <SkiDealOverlay title="ANDORRA" />,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945463/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Desktop/Andora.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945641/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Mobile/Andora.webp',
    imageAlt: 'Andorra picturesque snow covered mountains',
    link: '/ski-deals/andorra-ski-deals',
  },
  {
    desktopOverlay: <SkiDealOverlay title="CANADA" />,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945463/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Desktop/Canda.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945643/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Mobile/Canda.webp',
    imageAlt: 'Canadian snowy peaks',
    link: '/ski-deals/canada-ski-deals',
  },
  {
    desktopOverlay: <SkiDealOverlay title="GEORGIA" />,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945464/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Desktop/Georgia.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945645/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Mobile/Georgia.webp',
    imageAlt: 'Scenic view of a ski resort in Georgia',
    link: '/ski-deals/georgia-ski-deals',
  },
  {
    desktopOverlay: <SkiDealOverlay title="USA" />,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945465/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Desktop/US.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722945646/App%20Assets/Homepage/New%20Home%20page%202024/Resorts%20for%20home%20page/Mobile/US.webp',
    imageAlt: 'Snowy peaks in the United States',
    link: '/ski-deals/united-states-ski-deals',
  },
];
