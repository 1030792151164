'use client';

import React, { forwardRef } from 'react';
import styles from './input.module.scss';
import cx from 'classnames';
import Icon from '../icon/icon';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}
export const Input = forwardRef<HTMLInputElement, InputProps>(({ className, error, ...props }: InputProps, ref) => {
  return (
    <>
      <input ref={ref} className={cx(styles.input, { [styles.error]: !!error }, className)} {...props} />
      {error && (
        <div className={styles.errorMessage}>
          <Icon icon="ExclamationMark" />
          {error}
        </div>
      )}
    </>
  );
});

Input.displayName = 'Input';
