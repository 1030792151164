'use client';

import React, { PropsWithChildren } from 'react';
import styles from './skeleton.module.scss';
import cx from 'classnames';

interface Props {
  className?: string;
  width?: number;
}

const Skeleton = ({ className, width, children }: PropsWithChildren<Props>) => {
  return (
    <div
      className={cx(styles.skeleton, className)}
      style={{
        ...(width
          ? {
              width: `${width}px`,
            }
          : {}),
      }}
    >
      {children}
    </div>
  );
};

export default Skeleton;
