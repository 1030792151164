import { DateType } from './date-picker-form-section';
import { capitalizeWords } from '../../../../../utils/string';
import { holidays } from './flexible-dates-picker/holidays';
import { getMonthName } from '../../../../../utils/dates';
import { DateRangePadding, DateRangeSearch, DatesSearch, FlexibleDatesSearch } from '../../types';

export const getLabel = (value?: DatesSearch): string => {
  if (!value) {
    return '';
  }

  return value.type === DateType.Specific
    ? specificInputLabel(value.dateRangeSearch)
    : flexibleInputLabel(value.flexibleDatesSearch!);
};

const specificInputLabel = (search?: DateRangeSearch) => {
  if (!search?.range) {
    return 'Choose Dates';
  }

  let label = `${search.range.from!.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} -`;

  if (search.range.to) {
    label = `${label} ${search.range.to!.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`;
  }

  if (search.padding === DateRangePadding.OneDay) {
    label = `${label} (±1)`;
  }

  return label;
};

const flexibleInputLabel = (search: FlexibleDatesSearch) => {
  const { duration, type, when } = search;

  if (type === 'lastMinute') {
    return `${capitalizeWords(duration)} - last minute`;
  }

  if (type === 'season') {
    return `${capitalizeWords(duration)} - anytime`;
  }

  if (type === 'holiday') {
    const holiday = holidays.find((h) => h.name === when.holiday!);
    return `${capitalizeWords(duration)} - ${holiday!.label}`;
  }

  return `${capitalizeWords(duration)} in ${getMonthName(when.month!)}`;
};
