import 'react-day-picker/dist/style.css';
import styles from './date-range-picker.module.scss';
import { DateRange, DayPicker } from 'react-day-picker';
import { dayDiff, WEEKDAYS_SHORT } from './date-helpers';
import { useIsMobile } from '../../../../../../hooks/use-is-mobile/use-is-mobile';

interface CalendarProps {
  onSelect: (dates?: DateRange) => void;
  selected?: DateRange;
  minNights: number;
  maxNights: number;
  firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  minDate?: Date;
  maxDate?: Date;
  defaultMonth?: Date;
}

export const DateRangePicker = ({
  onSelect,
  selected,
  minNights,
  maxNights,
  firstDayOfWeek = 1,
  minDate,
  maxDate,
  defaultMonth,
}: CalendarProps) => {
  const isMobile = useIsMobile();
  const handleOnSelect = (_range: DateRange | undefined, dayClicked: Date) => {
    const selectedFrom = selected?.from;
    const selectedTo = selected?.to;

    if (!selectedFrom || selectedTo || dayClicked < selectedFrom) {
      onSelect({ from: dayClicked });
      return;
    }

    if (dayDiff(dayClicked, selectedFrom) === 0) {
      onSelect();
      return;
    }

    onSelect({ from: selectedFrom, to: dayClicked });
  };

  const disabledMatcher = (day: Date): boolean => {
    const selectedFrom = selected?.from;
    const selectedTo = selected?.to;

    if (!selectedFrom || (selectedTo && maxDate)) {
      return dayDiff(day, maxDate!) < minNights;
    }

    if (day <= selectedFrom) {
      return false;
    }

    const diff = dayDiff(selectedFrom, day);
    return !(diff >= minNights && diff <= maxNights);
  };

  return (
    <div className={styles.dateRangePickerContainer}>
      <DayPicker
        className={styles.dayPicker}
        mode="range"
        selected={selected}
        onSelect={handleOnSelect}
        numberOfMonths={isMobile ? 1 : 2}
        showOutsideDays={false}
        formatters={{ formatWeekdayName: (date) => WEEKDAYS_SHORT[date.getDay()] }}
        weekStartsOn={firstDayOfWeek}
        fromDate={minDate}
        toDate={maxDate}
        defaultMonth={defaultMonth || selected?.from}
        disabled={disabledMatcher}
      />
    </div>
  );
};
