'use client';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import styles from './number-section.module.scss';
import { Stats } from './stats';

const NumbersSection = () => {
  return (
    <div className={styles.stats}>
      {Stats.map((item, idx) => (
        <div key={idx} className={styles.statContainer}>
          <VisibilitySensor partialVisibility>
            {({ isVisible }: { isVisible: boolean }) => (
              <>
                <span className={styles.stat}>
                  {isVisible ? (
                    <CountUp end={item.number} suffix={item.exactNumber ? '' : '+'} separator={','} />
                  ) : null}
                </span>
                <div className={styles.title}>{item.title}</div>
              </>
            )}
          </VisibilitySensor>
        </div>
      ))}
    </div>
  );
};

export default NumbersSection;
