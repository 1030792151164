'use client';

import { FC } from 'react';
import styles from './popular-ski-holidays-section.module.scss';
import classNames from 'classnames';
import { ImageOverlayCarousel, SectionHeadline } from '../../../core';
import { skiHolidayCategories } from './ski-holidays-categories';
import { ImageOverlayData } from '../../../core/image-with-overlay/image-with-overlay';

const PopularSkiHolidaysSection: FC<{ className?: string; data?: ImageOverlayData[] }> = ({ className, data }) => {
  return (
    <div className={classNames(styles.skiHolidaysSectionContainer, className)}>
      <SectionHeadline title="Popular ski holiday types" />
      <ImageOverlayCarousel
        hoverImageShadow={true}
        imageContainerClassName={styles.imageContainerClassName}
        content={data ?? skiHolidayCategories}
        pixelsToScroll={900}
      />
    </div>
  );
};

export default PopularSkiHolidaysSection;
