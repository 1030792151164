import React, { useEffect, useRef } from 'react';
import styles from './destination-popover.module.scss';
import BySpecificResorts from './by-specific-resort/by-specific-resort';
import ByResortPreferences from './by-resort-preferences/by-resort-preferences';
import { DestinationSearch, DestinationType, ResortPreferences } from '../types';
import { useResorts } from './use-resorts';
import { useIsMobile } from '../../../../../../hooks/use-is-mobile/use-is-mobile';
import { useIsFirstRender } from '../../../../../../hooks/use-is-first-render/use-is-first-render';
import { HorizontalRule, Button, SearchBar, SegmentedGroup, MobileModalHeader } from '../../../../../core';
import { AnalyticsEvents, Destination } from '@weski-monorepo/types';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { getDataTestId, TestPage } from '../../../../../../utils/data-test-id';

export const TripDestinationPickerElementId = 'trip-destination-picker';

interface DestinationPopoverProps {
  autoCompleteTextInput: string;
  onDone: () => void;
  onAutoCompleteTextInputChange: (text: string) => void;
  onSpecificResortChosen: () => void;
  destination: DestinationSearch;
  onChange: (value: DestinationSearch) => void;
  destinations: Destination[];
  isAdmin: boolean;
  canSearchByResortPreference: boolean;
}

const DestinationPopoverContent = ({
  autoCompleteTextInput,
  onAutoCompleteTextInputChange,
  onDone,
  onSpecificResortChosen,
  destination,
  onChange,
  destinations,
  isAdmin,
  canSearchByResortPreference,
}: DestinationPopoverProps) => {
  const isMobile = useIsMobile();
  const isFirstRender = useIsFirstRender();
  const shouldShowDoneButton = destination.type === DestinationType.ByPreference;
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const resorts = useResorts(destinations, isAdmin);
  const analytics = useAnalytics();

  useEffect(() => {
    if (destination.type === DestinationType.ByPreference) {
      setTimeout(() => {
        ref.current?.scrollTo({ top: 0 });
      }, 100);
    }
  }, [destination.type]);

  useEffect(() => {
    if (!isFirstRender) {
      setTimeout(() => {
        ref.current?.scrollTo({ top: 0 });
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCompleteTextInput]);

  const handleSpecificResortOnChange = (value: DestinationSearch) => {
    onChange(value);
    onSpecificResortChosen();
  };

  const handleResortPreferencesOnChange = (preferences: ResortPreferences) => {
    onChange({ ...destination, resortPreferences: preferences });
  };

  return (
    <div
      id={TripDestinationPickerElementId}
      ref={ref}
      className={`${styles.container} ${
        destination.type === DestinationType.ByPreference ? styles.byResortPreference : ''
      }`}
    >
      <div className={styles.top}>
        {isMobile && <MobileModalHeader onBackClick={onDone}>Where to?</MobileModalHeader>}

        {canSearchByResortPreference && (
          <div className={styles.segmentedGroupContainer}>
            <SegmentedGroup
              segments={[
                {
                  label: 'Browse all',
                  value: DestinationType.Specific,
                  dataTestId: getDataTestId({
                    page: TestPage.SearchForm,
                    component: `destination-picker-browse-all-tab`,
                  }),
                },
                {
                  label: 'Help me choose',
                  value: DestinationType.ByPreference,
                  dataTestId: getDataTestId({
                    page: TestPage.SearchForm,
                    component: `destination-picker-help-me-tab`,
                  }),
                },
              ]}
              defaultValue={destination.type}
              onChoose={(v) => {
                analytics(AnalyticsEvents.DESTINATION_PICKER_SEGMENTED_GROUP_CLICK, { type: v });
                onChange({ ...destination, type: v });
              }}
            />
          </div>
        )}
        {destination.type === DestinationType.Specific && isMobile && (
          <SearchBar
            value={autoCompleteTextInput}
            onChange={(e) => {
              onAutoCompleteTextInputChange(e.target.value);
            }}
            placeholder="Try: Val Thorens"
            className={styles.searchBar}
          />
        )}
      </div>
      <div className={styles.grow}>
        {destination.type === DestinationType.Specific ? (
          <BySpecificResorts
            autoCompleteTextInput={autoCompleteTextInput}
            value={destination}
            onChange={handleSpecificResortOnChange}
            canSearchByResortPreference={canSearchByResortPreference}
            resorts={resorts}
          />
        ) : (
          <ByResortPreferences preferences={destination.resortPreferences} onChange={handleResortPreferencesOnChange} />
        )}

        {shouldShowDoneButton && (
          <>
            {isMobile && <div className={styles.fakeBottomMargin} />}
            <div className={styles.bottom}>
              <HorizontalRule />
              <div className={styles.doneButtonContainer}>
                <Button onClick={onDone} text="Done" textVariant="B400" variant="main" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DestinationPopoverContent;
