import { FC } from 'react';
import styles from './trust-pilot-graphic.module.scss';
import { trustPilotImg, weSkiTrustPilotLink } from '../links';
import { Typography } from '../../../core';

const TrustPilotGraphic: FC<{ breakSection?: boolean }> = ({ breakSection }) => {
  return (
    <div className={styles.trustPilotContainer}>
      <a className={styles.styledLink} href={weSkiTrustPilotLink}>
        <img
          src={breakSection ? trustPilotImg.vertical : trustPilotImg.horizontal}
          alt={'trustpilot logo'}
          width={128}
          height={54}
        />
      </a>
      {breakSection && <div className={styles.verticalLineSeparator} />}
      <Typography variant="H400" className={styles.trustPilotRating}>
        4.9/5
      </Typography>
    </div>
  );
};

export default TrustPilotGraphic;
