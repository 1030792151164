'use client';

import React, { PropsWithChildren } from 'react';
import styles from './spinner.module.scss';
import cx from 'classnames';

interface Props {
  className?: string;
  size?: number;
  color?: string;
}

const Spinner = ({ children, className, size = 48, color = 'var(--border-accent)' }: PropsWithChildren<Props>) => {
  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderStyle: 'solid',
        borderWidth: `${Math.round(size / 9)}px`,
        borderColor: color,
        borderBottomColor: 'transparent',
      }}
      className={cx(styles.spinner, className)}
    >
      {children}
    </div>
  );
};

export default Spinner;
