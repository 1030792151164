import { ServiceHighlightComponentData } from '../../../core/service-highlight/service-highlight';

export const WhyBookWithWeskiComponentsData: (
  focusOnSearch?: () => void,
  fixedDepositValue?: string,
  skiDealsEnabled?: boolean
) => ServiceHighlightComponentData[] = (focusOnSearch = () => {}, fixedDepositValue, skiDealsEnabled) => [
  {
    title: 'Your one-stop ski shop',
    description: 'Absolutely everything you need in one place',
    iconName: 'OneStopIcon',
    ...(skiDealsEnabled
      ? { link: { text: 'See ski deals', href: '/ski-deals' } }
      : {
          onClickLink: {
            text: 'Find your trip',
            onClick: focusOnSearch,
          },
        }),
  },
  {
    title: 'Hassle-free booking',
    description: 'Build a dream trip in seconds with our easy platform',
    iconName: 'HassleFreeIcon',
    link: {
      text: 'More about us',
      href: '/about',
    },
  },
  {
    title: 'Your trip, your way',
    description: 'Unlimited combinations and fully customisable packages',
    iconName: 'YourTripYourWayIcon',
    ...(skiDealsEnabled
      ? {
          link: {
            text: 'Find your match',
            href: '/ski-deals',
          },
        }
      : {
          onClickLink: {
            text: 'Find your match',
            onClick: focusOnSearch,
          },
        }),
  },
  {
    title: 'Spread the Cost',
    description: `More ways to ski with ${fixedDepositValue || 'low'} deposits and flexible payments`,
    iconName: 'SpreadTheCostIcon',
    link: {
      text: 'Payment options',
      href: '/book-with-confidence',
    },
  },
  {
    title: 'Total peace of mind',
    description: '24/7 on-trip support and ABTA & ATOL protection',
    iconName: 'SkiWithConfidenceIcon',
    link: {
      text: 'Book with confidence',
      href: '/book-with-confidence',
    },
  },
];
