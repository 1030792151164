'use client';
import React, { useMemo, useState } from 'react';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import cx from 'classnames';
import Slider, { Settings as SliderSettings } from 'react-slick';
import styles from './image-carousel.module.scss';
import CarouselImage from './carousel-image/carousel-image';
import { Arrow } from './arrow';
import CarouselDots from './carousel-dots/carousel-dots';
import { useIsMobile } from '../../../hooks/use-is-mobile/use-is-mobile';

interface Props {
  images: string[];
  className?: string;
  preloadImageOffset?: number;
}

const ImageCarousel = React.memo(({ className, images, preloadImageOffset = 2 }: Props) => {
  const isMobile = useIsMobile();
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderSettings: SliderSettings = useMemo(
    () => ({
      dots: true,
      infinite: true,
      speed: 250,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: !isMobile,
      nextArrow: <Arrow />,
      prevArrow: <Arrow />,
      appendDots: (dots) => {
        // @ts-expect-error shitload
        return <CarouselDots dots={dots} numDotsToShow={5} dotWidth={20} />;
      },
    }),
    [isMobile]
  );

  return (
    <div className={cx(styles.imageCarousel, className)}>
      <Slider
        {...sliderSettings}
        afterChange={(index) => {
          setCurrentIndex(index);
        }}
      >
        {images.map((image, index) => {
          const shouldLoad =
            Math.min(Math.abs(index - currentIndex), Math.abs(index - images.length - currentIndex)) <
            preloadImageOffset;
          return <CarouselImage shouldLoad={shouldLoad} url={image} key={index} index={index} />;
        })}
      </Slider>
    </div>
  );
});

export default ImageCarousel;
