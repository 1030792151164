'use client';

import * as RadixSelect from '@radix-ui/react-select';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import styles from './select.module.scss';
import { useState } from 'react';
import classNames from 'classnames';
import Icon from '../icon/icon';

interface SelectProps {
  items: { label: string; value: string; dataTestId?: string }[];
  value?: string;
  required?: boolean;
  onValueChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  itemClassName?: string;
  width?: string;
  dataTestId?: string;
}

export const Select = ({
  items,
  value,
  onValueChange,
  placeholder,
  className,
  itemClassName,
  width,
  required,
  dataTestId,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const triggerClassNames = () => {
    return classNames(styles.trigger, className, { [styles.open]: isOpen, [styles.error]: required && !value });
  };
  return (
    <RadixSelect.Root value={value} onValueChange={onValueChange} onOpenChange={onOpenChange}>
      <RadixSelect.Trigger className={triggerClassNames()} style={{ width }} data-testid={dataTestId}>
        <div className={styles.valueContainer}>
          <RadixSelect.Value placeholder={placeholder} aria-label={value} />
        </div>
        <RadixSelect.Icon className={styles.triggerIcon}>
          <Icon icon="ChevronDown" cursor="pointer" />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Content
        className={styles.content}
        style={{ width }}
        position="popper"
        avoidCollisions={false}
        sideOffset={4}
      >
        <ScrollArea.Root type="auto">
          <RadixSelect.Viewport>
            <ScrollArea.Viewport>
              {items.map((item) => (
                <RadixSelect.Item
                  key={item.value}
                  className={classNames(styles.selectItem, itemClassName)}
                  value={item.value}
                  data-testid={item.dataTestId}
                >
                  <RadixSelect.ItemText>{item.label}</RadixSelect.ItemText>
                </RadixSelect.Item>
              ))}
            </ScrollArea.Viewport>
          </RadixSelect.Viewport>
          <ScrollArea.Scrollbar orientation="vertical">
            <ScrollArea.Thumb />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </RadixSelect.Content>
    </RadixSelect.Root>
  );
};
