'use client';

import React, { ForwardedRef, PropsWithChildren, forwardRef } from 'react';
import classNames from 'classnames';
import Typography, { TypographyVariant } from '../typography/typography';
import styles from './button.module.scss';
import Spinner from 'react-spinkit';

interface ButtonProps {
  variant: 'main' | 'outlined' | 'text' | 'ellipse';
  textVariant?: TypographyVariant;
  text?: string | React.JSX.Element;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  fullWidth?: boolean;
  className?: string;
  id?: string;
  selected?: boolean;
  hrefLink?: string;
}

const Button = forwardRef(
  (
    {
      variant,
      text,
      type,
      onClick,
      disabled,
      loading,
      fullWidth,
      className,
      textVariant = 'B500',
      backgroundColor,
      borderColor,
      textColor,
      id,
      selected,
      children,
      hrefLink,
      ...props
    }: PropsWithChildren<ButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const handler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!disabled && onClick) onClick(e);
    };

    const buttonClassNames = classNames(
      styles.button,
      styles[variant],
      {
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
        [styles.selected]: selected,
      },
      className
    );

    const buttonContent = (
      <>
        {loading && <Spinner name="three-bounce" color="#fff" fadeIn="none" className={styles.loader} />}
        <Typography
          className={classNames({ [styles.transparentWhenLoading]: loading, [styles.selectedText]: selected })}
          variant={textVariant}
        >
          {text}
          {children}
        </Typography>
      </>
    );

    return (
      <button
        type={type || 'button'}
        onClick={(e) => {
          handler(e);
        }}
        ref={ref}
        className={buttonClassNames}
        style={{
          backgroundColor: backgroundColor || undefined,
          borderColor: borderColor || undefined,
          color: textColor || undefined,
        }}
        disabled={disabled}
        id={id}
        {...props}
      >
        {hrefLink ? (
          <a href={hrefLink} className={styles.hrefLink}>
            {buttonContent}
          </a>
        ) : (
          buttonContent
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
