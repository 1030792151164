'use client';

import { FC, ReactNode } from 'react';
import styles from './info-section.module.scss';
import classNames from 'classnames';
import SectionHeadline from '../section-headline/section-headline';
import { HeadTitleOption } from '..';

interface InfoSectionProps {
  title: string;
  content: ReactNode | string;
  className?: string;
  isMainInfo?: boolean;
  titleClassName?: string;
  headTitleType?: HeadTitleOption;
}

const InfoSection: FC<InfoSectionProps> = ({
  title,
  content,
  isMainInfo,
  className,
  titleClassName,
  headTitleType,
}) => {
  return (
    <div className={classNames(isMainInfo ? styles.mainInfoContainer : styles.infoContainer, className)}>
      <SectionHeadline
        headTitle={headTitleType}
        titleClassName={titleClassName}
        title={title}
        isSubHeadline={!isMainInfo}
      />
      <div className={styles.description}>{content}</div>
    </div>
  );
};

export default InfoSection;
