import React, { useEffect, useRef } from 'react';
import styles from './select-option.module.scss';
import { SelectItemProps } from '../select-content/select-content';
import { Option } from '../../types';

type Props = SelectItemProps & {
  sticky: boolean;
  option: Option;
};

export const SelectOption = ({
  option,
  indentation,
  onSelect,
  isSelected,
  adminOnly,
  disabled,
  canSelect,
  sticky,
}: Props) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoView(true);
    }
  }, [ref, isSelected]);

  return (
    <li
      className={`${styles.container} ${canSelect ? styles.canSelect : ''} ${adminOnly ? styles.adminOnly : ''} ${
        isSelected ? styles.isSelected : ''
      } ${sticky ? styles.sticky : ''}`}
      style={{ ...(indentation ? { marginLeft: `${indentation * 20}px` } : {}) }}
      onClick={onSelect}
      ref={ref}
      data-testid={option.dataTestId}
    >
      {option.element ? (
        option.element
      ) : (
        <>
          {option.thumbnail && <div className={styles.thumbnailContainer}>{option.thumbnail}</div>}
          <div className={styles.label}>
            <div className={styles.labelText}>{option.name}</div>
            {option.subtitle && <div className={styles.subtitle}>{option.subtitle}</div>}
          </div>
        </>
      )}
    </li>
  );
};
