'use client';

import React from 'react';
import styles from './avatar.module.scss';
import cx from 'classnames';
import Icon from '../icon/icon';

interface Props {
  className?: string;
  src?: string;
}

export const Avatar = ({ className, src }: Props) => {
  if (!src) {
    return <Icon className={className} icon="AvatarFilled" width={28} />;
  }
  return <img className={cx(styles.avatar, className)} src={src} alt="avatar" />;
};
