import { FC } from 'react';
import styles from './ways-we-have-you-covered-section.module.scss';
import { SectionHeadline, ServiceHighlight, ServiceHighlightComponentData } from '../../../core';
import { WaysCoveredData } from './ways-covered-data';

const WaysWeHaveYouCoveredSection: FC<{ fixedDepositValue?: string }> = ({ fixedDepositValue }) => {
  return (
    <div className={styles.container}>
      <SectionHeadline title="Ways we have you covered" isSubHeadline={true} />
      <div className={styles.gridContainer}>
        {WaysCoveredData(fixedDepositValue).map((data: ServiceHighlightComponentData, index) => {
          const { title, description, iconName } = data;
          return (
            <ServiceHighlight
              key={'WaysCovered' + index}
              title={title}
              description={description}
              iconName={iconName}
              className={styles.serviceHighlight}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WaysWeHaveYouCoveredSection;
