import PopularResortsForSearch from './homepage/popular-resorts-for-search/popular-resorts-for-search';
import SocialProofBanner from './homepage/social-proof-banner/social-proof-banner';
import PopularSkiDealsSection from './homepage/popular-ski-deals-section/popular-ski-deals-section';
import PopularSkiHolidaysSection from './homepage/popular-ski-holidays-section/popular-ski-holidays-section';
import HolidaysByAccommodationTypeSection from './homepage/ski-holidays-by-accommodation-type/holidays-by-accommodation-type';
import WhyBookWithWeSkiSection from './homepage/why-book-with-weski-section/why-book-with-weski-section';
import ExploreSkiDestinationSection from './homepage/explore-ski-destinations-section/explore-ski-destinations-section';
import PopularSkiResortsSection from './homepage/popular-ski-resorts-section/popular-ski-resorts-section';
import SkiHolidaysGuidesSection from './homepage/ski-holidays-guides-section/ski-holidays-guides-section';
import NumbersSection from './number-section/number-section';
import BookWithConfidence from './book-with-confidence/book-with-confidence';
import AboutUs from './about-us/about-us';
import TrustPilotSection from './trust-pilot-section/trust-pilot-section';
import TrustBox from './trust-pilot-section/trust-box';
import NotificationBar from './notification-bar/notification-bar';

export * from './search-form';
export {
  PopularResortsForSearch,
  SocialProofBanner,
  PopularSkiDealsSection,
  PopularSkiHolidaysSection,
  HolidaysByAccommodationTypeSection,
  WhyBookWithWeSkiSection,
  ExploreSkiDestinationSection,
  PopularSkiResortsSection,
  SkiHolidaysGuidesSection,
  NumbersSection,
  BookWithConfidence,
  AboutUs,
  TrustPilotSection,
  TrustBox,
  NotificationBar,
};
