'use client';

import { FC, useRef, useState } from 'react';
import styles from './image-overlay-carousel.module.scss';
import ImageWithOverlay, { ImageOverlayData } from '../image-with-overlay/image-with-overlay';
import ScrollButton from './scroll-button';
import { DesktopOnly, Responsive } from '../responsive/responsive';
import classNames from 'classnames';

interface ImageOverlayCarouselProps {
  content: ImageOverlayData[];
  imageContainerClassName?: string;
  pixelsToScroll: number;
  scrollButtonHeight?: number;
  hoverImageShadow?: boolean;
  mobileLayout?: 'carousel' | 'column';
}

const ImageOverlayCarousel: FC<ImageOverlayCarouselProps> = ({
  content,
  imageContainerClassName,
  pixelsToScroll,
  scrollButtonHeight,
  hoverImageShadow,
  mobileLayout,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(true);

  const scrollBy = (distance: number) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: distance,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollLeft = () => {
    scrollBy(-pixelsToScroll);
    setCanScrollRight(true);
    if (containerRef.current && containerRef.current.scrollLeft <= pixelsToScroll) {
      setCanScrollLeft(false);
    }
  };

  const handleScrollRight = () => {
    scrollBy(pixelsToScroll);
    setCanScrollLeft(true);
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth - clientWidth);
    }
  };

  const ExtraItems = content.slice(4).map((imageOverlay, index) => {
    return (
      <div key={'ImageWithOverlay' + (index + 4)}>
        <ImageWithOverlay
          alt={imageOverlay.imageAlt}
          link={imageOverlay.link}
          src={imageOverlay.desktopImageSrc}
          containerClassName={classNames(styles.imageOverlayContainer, imageContainerClassName, {
            [styles.mobileColumnImageOverlayContainer]: mobileLayout === 'column',
          })}
          imageClassName={styles.image}
          overlayClassName={styles.overlay}
          hoverImageShadow={hoverImageShadow}
        >
          {imageOverlay.desktopOverlay}
        </ImageWithOverlay>
      </div>
    );
  });

  return (
    <div className={classNames(styles.carouselContainer)}>
      <Responsive.DesktopOnly>
        {canScrollLeft && (
          <ScrollButton side="left" scrollFunction={handleScrollLeft} buttonHeight={scrollButtonHeight} />
        )}
        {canScrollRight && (
          <ScrollButton side="right" scrollFunction={handleScrollRight} buttonHeight={scrollButtonHeight} />
        )}
      </Responsive.DesktopOnly>
      <div
        className={classNames(styles.body, { [styles.mobileColumnBody]: mobileLayout === 'column' })}
        ref={containerRef}
      >
        {content.slice(0, 4).map((imageOverlay, index) => {
          return (
            <div key={'ImageWithOverlay' + index}>
              <ImageWithOverlay
                alt={imageOverlay.imageAlt}
                link={imageOverlay.link}
                src={imageOverlay.desktopImageSrc}
                containerClassName={classNames(styles.imageOverlayContainer, imageContainerClassName, {
                  [styles.mobileColumnImageOverlayContainer]: mobileLayout === 'column',
                })}
                imageClassName={styles.image}
                overlayClassName={styles.overlay}
                hoverImageShadow={hoverImageShadow}
              >
                {imageOverlay.desktopOverlay}
              </ImageWithOverlay>
            </div>
          );
        })}
        {mobileLayout === 'column' ? (
          <DesktopOnly className={styles.extraItemsContainer}>{ExtraItems}</DesktopOnly>
        ) : (
          ExtraItems
        )}
      </div>
    </div>
  );
};

export default ImageOverlayCarousel;
