import React from 'react';
import styles from './empty-state.module.scss';
import { Icon, Typography } from '../../../../../../../core';

const EmptyState = () => {
  return (
    <div className={styles.container}>
      <div>
        <Icon icon="MagnifyingGlass" width={32} />
        <Typography variant="H200">{`We didn't find any matches`}</Typography>
        <Typography variant="P200">Try searching by country or resort</Typography>
      </div>
    </div>
  );
};

export default EmptyState;
