'use client';

import { FC } from 'react';
import styles from './popular-ski-deals-section.module.scss';
import classNames from 'classnames';
import { Button, ImageOverlayCarousel, MobileOnly, SectionHeadline } from '../../../core';
import { skiDealsCategories } from './ski-deals-categories';
import { ImageOverlayData } from '../../../core/image-with-overlay/image-with-overlay';

const SeeAllSkiDeals = 'See all ski deals';

const PopularSkiDealsSection: FC<{ seeAllLink: string; className?: string; data?: ImageOverlayData[] }> = ({
  seeAllLink,
  className,
  data,
}) => {
  return (
    <div className={classNames(styles.skiDealsSectionContainer, className)}>
      <SectionHeadline
        title="Popular ski deals"
        link={{ text: SeeAllSkiDeals, href: seeAllLink }}
        className={styles.title}
      />
      <ImageOverlayCarousel
        scrollButtonHeight={55}
        content={data ?? skiDealsCategories}
        pixelsToScroll={1218}
        hoverImageShadow={true}
        mobileLayout="column"
      />
      <MobileOnly className={styles.seeAllSkiDealsButton}>
        <Button variant="outlined" fullWidth hrefLink="/ski-deals">
          {SeeAllSkiDeals}
        </Button>
      </MobileOnly>
    </div>
  );
};

export default PopularSkiDealsSection;
