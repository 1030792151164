'use client';

import React, { CSSProperties } from 'react';
import styles from './icon.module.scss';
import * as Icons from './icons';
import { IconName } from './icon-names';

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'ref' | 'as'> {
  icon: IconName;
  className?: string;
  color?: string;
  width?: number;
  isMultiColor?: boolean;
  cursor?: CSSProperties['cursor'];
  onClick?: () => void;
  inline?: boolean;
}

const Icon: React.FC<Props> = ({
  icon,
  color,
  width = 24,
  className,
  isMultiColor,
  cursor,
  inline,
  ...rest
}: Props) => {
  const Component = (Icons[icon] as any).default;
  const iconContainerClassNames = [
    styles.iconContainer,
    inline && styles.inline,
    !isMultiColor && styles.singleColor,
    cursor && styles.customCursor,
    width === 24 && styles['width-24'],
    className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={iconContainerClassNames} style={{ width: `${width}px` }} {...rest}>
      {Component && <Component color={color} />}
    </div>
  );
};

export default Icon;
