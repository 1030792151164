'use client';

import React from 'react';
import styles from './search-bar.module.scss';
import DisguisedPlaceholderInput, {
  DisguisedPlaceholderInputProps,
} from '../disguised-placeholder-input/disguised-placeholder-input';
import Icon from '../icon/icon';
import classNames from 'classnames';

type SearchBarProps = DisguisedPlaceholderInputProps;

const SearchBar = (props: SearchBarProps) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      <Icon icon="MagnifyingGlass" />
      <DisguisedPlaceholderInput {...props} className={styles.input} />
    </div>
  );
};

export default SearchBar;
