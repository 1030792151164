'use client';

import React, { PropsWithChildren } from 'react';
import styles from './card.module.scss';
import cx from 'classnames';

interface Props {
  className?: string;
}

const Card = ({ children, className }: PropsWithChildren<Props>) => {
  return <div className={cx(styles.card, className)}>{children}</div>;
};

export default Card;
