import { useMemo } from 'react';
import compact from 'lodash/compact';
import Icon from '../../../../../core/icon/icon';
import { OriginAirport } from '@weski-monorepo/types';
import { getDataTestId, TestPage } from '../../../../../../utils/data-test-id';

export function useAirportOptions(airports: OriginAirport[]) {
  return useMemo(() => {
    return airports
      .sort((a, b) => (a.id.length === b.id.length ? a.name.localeCompare(b.name) : b.id.length - a.id.length))
      .map((airport) => ({
        id: airport.id,
        name: airport.name,
        aliases: compact([airport.id, airport.city]),
        subtitle: formatAirportCodes(airport.id),
        thumbnail: <Icon icon="TakeOff" />,
        dataTestId: getDataTestId({
          page: TestPage.SearchForm,
          component: `origin-airport-option-${formatAirportCodes(airport.id).replace(/,/g, '-')}`,
        }),
      }));
  }, [airports]);
}

function formatAirportCodes(airportCodes: string) {
  if (!airportCodes.startsWith('[')) {
    return airportCodes;
  }
  return airportCodes.slice(1, -1);
}
