'use client';

import { useEffect, useRef, useState } from 'react';
import styles from './inline-video.module.scss';

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes('safari') && !ua.includes('chrome');
};

const InlineVideo = ({
  mainVideo,
  format,
  width,
  height,
  className,
}: {
  format: 'mp4' | 'webm';
  mainVideo: string;
  width?: number;
  height?: number;
  className?: string;
}) => {
  const videoParentRef = useRef<HTMLDivElement>(null);
  const [shouldUseImage, setShouldUseImage] = useState(false);

  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0] as HTMLVideoElement | undefined;

      if (player) {
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute('muted', '');
        player.autoplay = true;

        setTimeout(() => {
          const promise = player.play();
          if (promise?.then) {
            promise
              .then(() => {})
              .catch(() => {
                setShouldUseImage(true);
                try {
                  if (videoParentRef.current) {
                    videoParentRef.current.style.display = 'none';
                  }
                } catch (e) {}
              });
          }
        }, 0);
      }
    }
  }, []);

  return shouldUseImage ? (
    <img src={mainVideo} alt="Muted Video" width={width} height={height} className={className} />
  ) : (
    <div
      ref={videoParentRef}
      className={`${styles.videoWrapper} ${className}`}
      dangerouslySetInnerHTML={{
        __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
          disableremoteplayback="true"
          width="${width}"
          height="${height}"
          class="${className}"
        >
        <source src="${mainVideo}" type="video/${format}" />
        </video>`,
      }}
    />
  );
};

export default InlineVideo;
