'use client';

import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './typography.module.scss';

const variants = [
  'H700',
  'H650',
  'H600',
  'H500',
  'H400',
  'H200',
  'H100',
  'H50',
  'P300',
  'P200',
  'P100',
  'B400',
  'B500',
  'LB500',
  'C100',
] as const;

export type TypographyVariant = (typeof variants)[number];

export type HeadTitleOption = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';

interface TypographyProps {
  variant: TypographyVariant;
  mobileVariant?: TypographyVariant;
  children: React.ReactNode;
  className?: string;
  id?: string;
  headTitle?: HeadTitleOption;
}

interface headingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: ReactNode;
}

const headLineOptions: Record<HeadTitleOption, ({ children, ...props }: headingProps) => ReactNode> = {
  h1: ({ children, ...props }: headingProps) => <h1 {...props}>{children}</h1>,
  h2: ({ children, ...props }: headingProps) => <h2 {...props}>{children}</h2>,
  h3: ({ children, ...props }: headingProps) => <h3 {...props}>{children}</h3>,
  h4: ({ children, ...props }: headingProps) => <h4 {...props}>{children}</h4>,
  h5: ({ children, ...props }: headingProps) => <h5 {...props}>{children}</h5>,
  h6: ({ children, ...props }: headingProps) => <h6 {...props}>{children}</h6>,
  span: ({ children, ...props }: headingProps) => <span {...props}>{children}</span>,
  p: ({ children, ...props }: headingProps) => <p {...props}>{children}</p>,
};

const Typography: React.FC<TypographyProps> = ({
  variant,
  mobileVariant = variant,
  children,
  className,
  id,
  headTitle = 'span',
  ...props
}) => {
  const HeadTag = headLineOptions[headTitle];

  const typographyClassNames = classNames(
    styles.default,
    styles[variant.toLowerCase()],
    styles[`m-${mobileVariant.toLowerCase()}`],
    className
  );

  return (
    <HeadTag className={typographyClassNames} id={id} {...props}>
      {children}
    </HeadTag>
  );
};

export default Typography;
