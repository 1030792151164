import { AboutYouResortPreferences, CountryResortPreferences, ImportantPropertiesResortPreferences } from '../../types';
import { IconName } from '../../../../../../core/icon/icon-names';

type ChipMapper<T extends string> = Record<T, { label: string; icon: IconName }>;
export const aboutYouChipMapper: ChipMapper<keyof AboutYouResortPreferences> = {
  beginners: { icon: 'Beginners', label: 'Beginners' },
  advanced: { icon: 'Experts', label: 'Advanced' },
  family: { icon: 'Family', label: 'Family' },
  group: { icon: 'Group', label: 'Group' },
  intermediates: { icon: 'Intermediates', label: 'Intermediates' },
  snowboarders: { icon: 'Snowboarder', label: 'Snowboarders' },
};

export const importantPropertiesChipMapper: ChipMapper<keyof ImportantPropertiesResortPreferences> = {
  apresSki: { icon: 'Beer', label: 'Apres ski' },
  bigSkiArea: { icon: 'MountianHeight', label: 'Big ski area' },
  luxuryTrip: { icon: 'Sparkles', label: 'Luxury trip' },
  shortDriving: { icon: 'Location', label: 'Short driving' },
  skiInOut: { icon: 'SkiMountain', label: 'Ski in/out' },
  snowSure: { icon: 'Snowflake', label: 'Snow sure' },
};

export const countryChipMapper: ChipMapper<keyof CountryResortPreferences> = {
  flexible: { icon: 'Globe', label: "I'm flexible" },
  andorra: { icon: 'Andorra', label: 'Andorra' },
  austria: { icon: 'Austria', label: 'Austria' },
  bulgaria: { icon: 'Bulgaria', label: 'Bulgaria' },
  france: { icon: 'France', label: 'France' },
  georgia: { icon: 'Georgia', label: 'Georgia' },
  italy: { icon: 'Italy', label: 'Italy' },
  switzerland: { icon: 'Switzerland', label: 'Switzerland' },
  usa: { icon: 'Usa', label: 'USA' },
  canada: { icon: 'Canada', label: 'Canada' },
};

export const mapperByKeyName: { [key: string]: ChipMapper<any> } = {
  aboutYou: aboutYouChipMapper,
  importantProperties: importantPropertiesChipMapper,
  country: countryChipMapper,
} as const;
