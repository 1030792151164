import React, { useMemo } from 'react';
import styles from './flexible-dates.module.scss';
import Typography from '../../../../../core/typography/typography';
import FlexibleOptionButton from '../flexible-option-btn/flexible-option-btn';
import { holidays } from './holidays';
import Button from '../../../../../core/button/button';
import { FlexibleDatesSearch, FlexibleDurationType, FlexibleType, FlexibleWhen } from '../../../types';
import { allOptionsFromRange } from '../../../../../../utils/dates';
import { SeasonDates } from '@weski-monorepo/types';

interface FlexibleDatesProps {
  value: FlexibleDatesSearch;
  onChange: (value: FlexibleDatesSearch) => void;
  seasonDates: {
    current: SeasonDates;
    next: SeasonDates;
  };
  minDaysAheadToBook: number;
}

const shouldShowLastMinuteOption = (endDate: string, minDaysAheadToBook: number): boolean => {
  /** we need to think when we want it to be enabled. Currently it should always be hidden */
  return false;
  // const seasonEndDate = new Date(endDate);
  // const today = new Date();
  // const minBookingDate = new Date();
  // minBookingDate.setDate(today.getDate() + minDaysAheadToBook);

  // return seasonEndDate > minBookingDate;
};

const FlexibleDates = ({ value, onChange, seasonDates, minDaysAheadToBook }: FlexibleDatesProps) => {
  const [showLastMinuteOption, flexibleDatesOptions, holidayOptions, anytimeOptionSeasonYear] = useMemo(() => {
    const now = new Date();
    const anytimeOptionSeasonYear = parseInt(seasonDates.next.end.split('-')[0]);
    return [
      shouldShowLastMinuteOption(seasonDates.current.end, minDaysAheadToBook),
      [
        ...allOptionsFromRange(seasonDates.current.start, seasonDates.current.end),
        ...allOptionsFromRange(seasonDates.next.start, seasonDates.next.end),
      ].filter(({ month }) => month !== 5),
      holidays.filter(({ optionExpiryDate: minDate }) => now < minDate),
      anytimeOptionSeasonYear,
    ];
  }, [minDaysAheadToBook, seasonDates]);

  const handleTypeChange = (type: FlexibleType, when?: FlexibleWhen) => {
    onChange({ type, duration: value.duration, when: when || { month: -1, year: -1 } });
  };

  const handleDurationChange = (duration: FlexibleDurationType) => {
    const newValue = { ...value };
    newValue.duration = duration;
    onChange(newValue);
  };

  return (
    <div className={styles.container}>
      <Typography variant="H200">How long is your trip?</Typography>
      <div className={styles.durationButtonContainer}>
        <Button
          variant="ellipse"
          textVariant="B400"
          selected={value.duration === FlexibleDurationType.Week}
          text="Week"
          onClick={() => {
            handleDurationChange(FlexibleDurationType.Week);
          }}
        />
        <Button
          variant="ellipse"
          textVariant="B400"
          text="Weekend"
          selected={value.duration === FlexibleDurationType.Weekend}
          onClick={() => {
            handleDurationChange(FlexibleDurationType.Weekend);
          }}
        />
      </div>
      <Typography variant="H200">When do you want to go?</Typography>
      <div className={styles.whenButtonContainer}>
        {showLastMinuteOption && (
          <FlexibleOptionButton
            isSelected={value.type === FlexibleType.LastMinute}
            type={FlexibleType.LastMinute}
            icon="Alarm"
            onClick={() => {
              handleTypeChange(FlexibleType.LastMinute);
            }}
          />
        )}
        <FlexibleOptionButton
          isSelected={value.type === FlexibleType.Season}
          option={{ season: anytimeOptionSeasonYear }}
          type={FlexibleType.Season}
          icon="Clock"
          onClick={() => {
            handleTypeChange(FlexibleType.Season, { season: anytimeOptionSeasonYear });
          }}
        />
        {flexibleDatesOptions.map(({ month, year }) => (
          <FlexibleOptionButton
            isSelected={value.type && value.when!.month === month && value.when!.year === year}
            option={{ month, year }}
            type={FlexibleType.Month}
            key={`flexible-month-${month}-${year}`}
            onClick={() => {
              handleTypeChange(FlexibleType.Month, { month, year });
            }}
          />
        ))}
        {holidayOptions.map(({ icon, name, label }) => (
          <FlexibleOptionButton
            isSelected={value.type === FlexibleType.Holiday && value.when!.holiday === name}
            label={label}
            type={FlexibleType.Holiday}
            icon={icon}
            key={`flexible-holiday-${name}`}
            onClick={() => {
              handleTypeChange(FlexibleType.Holiday, { month: -1, year: -1, holiday: name });
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default FlexibleDates;
