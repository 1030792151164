import { SearchFormFields } from '../index';
import { GroupSizeSearchField, SearchRequest } from '@weski-monorepo/types';
import { tripBuilderDestinationFromFormData } from './destination-format-helper';
import { tripBuilderDatesFromFormData } from './dates-format-helper';

export const formDataToSearchRequest = (data: SearchFormFields): SearchRequest => {
  return {
    ...data,
    destinations: tripBuilderDestinationFromFormData(data.destinations),
    departingFrom: [data.departingFrom],
    rooms: [data.rooms as GroupSizeSearchField],
    dates: tripBuilderDatesFromFormData(data.dates),
  };
};
