export const reviews = {
  default: [
    {
      id: '628cfeee045d025d3a9d6497',
      stars: 5,
      title: 'Everything worked perfectly',
      content:
        'I usually book flights, transfers, hotel, ski rental and lift passes myself but this year used WeSki for a trip to Morzine. It was so much easier. Everything worked perfectly - transfers arrived on time and there was plenty of feedback throughout the whole process giving you confidence the holiday would go smoothly.',
      consumer: {
        displayName: 'Sofia Taylor',
        picture:
          'https://res.cloudinary.com/ht4mr7djk/image/upload/v1664460792/App%20Assets/Homepage/Reviews/avatars/sofia.webp',
      },
    },
    {
      id: '5e5f9dd73c93ae0864ad2c64',
      stars: 5,
      title: 'I will use WeSki for all my ski holidays',
      content:
        'A really useful service that is so much easier to use than other ‘all-inclusive’ sites. It nicely bridges the gap between a travel agent and booking the trip yourself online. I will use WeSki every time I go skiing from now on.',
      consumer: {
        displayName: 'George Murphy',
        picture:
          'https://res.cloudinary.com/ht4mr7djk/image/upload/v1664460792/App%20Assets/Homepage/Reviews/avatars/george.webp',
      },
    },
    {
      id: '628dbfaa4b16276ac98fa0ab',
      stars: 5,
      title: 'The company was excellent',
      content:
        'We booked a late minute skiing trip to Morzine through we ski. We looked at booking the trip ourselves but could get anywhere near the price quoted by we ski. The company was excellent and we had no problems at all from start to finish. I would definitely use them to book another weekend skiing trip.',
      consumer: {
        displayName: 'Steve Cooper',
        picture:
          'https://res.cloudinary.com/ht4mr7djk/image/upload/v1664460792/App%20Assets/Homepage/Reviews/avatars/steve.webp',
      },
    },
    {
      id: '628c97214b16276ac98ea3a5',
      stars: 5,
      title: 'Seamless experience from start to finish',
      content:
        'Seamless experience from start to finish. I was spending ages trying to sort out a weekend break and managed to do it with we ski in minutes and for the same price as booking it all up yourself. Flight, transfer and accommodation was all as expected and faultless.',
      consumer: {
        displayName: 'Amanda Turner',
        picture:
          'https://res.cloudinary.com/ht4mr7djk/image/upload/v1664460792/App%20Assets/Homepage/Reviews/avatars/amanda.webp',
      },
    },
  ],
};
