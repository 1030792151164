import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './menu-item.module.scss';
import { Close } from '@radix-ui/react-popover';
import { IconName } from '../../icon/icon-names';
import Icon from '../../icon/icon';

export interface MenuItemProps {
  disabled?: boolean;
  icon?: IconName;
  onClick?: () => void;
  href?: string;
}

export const MenuItem = ({ children, onClick, disabled, icon, href }: PropsWithChildren<MenuItemProps>) => {
  return (
    <Close asChild>
      <a
        className={cx(styles.menuItem, { [styles.disabled]: disabled })}
        onClick={() => {
          if (!disabled) {
            onClick?.();
          }
        }}
        href={href}
      >
        {icon && <Icon icon={icon} />}
        {children}
      </a>
    </Close>
  );
};
