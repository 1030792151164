'use client';

import { FC } from 'react';
import { SkiDestinationCard } from './ski-destination-card/ski-destination-card';
import styles from './explore-ski-destinations-section.module.scss';
import { SkiDestinationCardData, SkiDestinationCardsData } from './explore-ski-destinations-data';
import { Button, Responsive, SectionHeadline } from '../../../core';
import classNames from 'classnames';

const createSkiDestinationCardToDisplay = (skiDestinationCardData: SkiDestinationCardData, index: number) => {
  return (
    <SkiDestinationCard
      key={'SkiDestinationCard' + index}
      href={skiDestinationCardData.href}
      title={skiDestinationCardData.title}
      iconName={skiDestinationCardData.iconName}
      numberOfResorts={skiDestinationCardData.numberOfResorts}
      className={styles.item}
    />
  );
};

const ExploreSkiDestinationSection: FC<{ seeAllLink: string; data?: SkiDestinationCardData[]; className?: string }> = ({
  data,
  seeAllLink,
  className,
}) => {
  return (
    <div className={classNames(styles.sectionContainer, className)}>
      <SectionHeadline title="Popular ski destinations" link={{ text: 'See all destinations', href: seeAllLink }} />
      <div className={styles.bodyContainer}>
        {(data ?? SkiDestinationCardsData)
          .slice(0, 4)
          .map((skiDestination, index) => createSkiDestinationCardToDisplay(skiDestination, index))}

        <Responsive.DesktopOnly className={styles.desktopOnlyItemsContainer}>
          {(data ?? SkiDestinationCardsData)
            .slice(4)
            .map((skiDestination, index) => createSkiDestinationCardToDisplay(skiDestination, index))}
        </Responsive.DesktopOnly>
      </div>
      <Responsive.MobileOnly className={styles.seeAllDestinations}>
        <Button variant="outlined" fullWidth hrefLink="/ski-holidays">
          See all destinations
        </Button>
      </Responsive.MobileOnly>
    </div>
  );
};

export default ExploreSkiDestinationSection;
