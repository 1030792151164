'use client';

import { FC } from 'react';
import styles from './why-book-with-weski-section.module.scss';
import classNames from 'classnames';
import { WhyBookWithWeskiComponentsData } from './components-data';
import { Responsive, ServiceHighlight, SectionHeadline, Separator } from '../../../core';
import { ServiceHighlightComponentData } from '../../../core/service-highlight/service-highlight';

const WhyBookWithWeSkiSection: FC<{
  className?: string;
  data?: ServiceHighlightComponentData[];
  focusOnSearch?: () => void;
  fixedDepositValue?: string;
  skiDealsEnabled?: boolean;
}> = ({ className, data, focusOnSearch = () => {}, fixedDepositValue, skiDealsEnabled }) => {
  return (
    <div className={classNames(styles.whyBookWithWeskiContainer, className)}>
      <SectionHeadline title="Why book with WeSki?" className={styles.title} />
      <div className={styles.body}>
        {(data ?? WhyBookWithWeskiComponentsData(focusOnSearch, fixedDepositValue, skiDealsEnabled)).map(
          ({ title, description, iconName, link, onClickLink }, index) => (
            <div key={'why-book-with-weski-component' + index}>
              <ServiceHighlight
                link={link}
                onClickLink={onClickLink}
                title={title}
                description={description}
                iconName={iconName}
              />
              <Responsive.MobileOnly>
                {index <
                  (data ?? WhyBookWithWeskiComponentsData(focusOnSearch, fixedDepositValue, skiDealsEnabled)).length -
                    1 && <Separator className={styles.separator} />}
              </Responsive.MobileOnly>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default WhyBookWithWeSkiSection;
