export const destinationsResortPreferencesCountryOptions = [
  'flexible',
  'austria',
  'france',
  'italy',
  'switzerland',
  'bulgaria',
  'georgia',
  'andorra',
  'usa',
  'canada',
] as const;

export const destinationsResortPreferencesAboutYouOptions = [
  'beginners',
  'intermediates',
  'advanced',
  'family',
  'group',
  'snowboarders',
] as const;

export const destinationsResortPreferencesImportantPropertiesOptions = [
  'skiInOut',
  'apresSki',
  'snowSure',
  'bigSkiArea',
  'shortDriving',
  'luxuryTrip',
] as const;
