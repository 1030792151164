'use client';

import React, { ComponentProps, PropsWithChildren } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import styles from './popover.module.scss';
import cx from 'classnames';

interface Props {
  trigger: React.ReactElement;
  side?: ComponentProps<typeof RadixPopover.Content>['side'];
  align?: ComponentProps<typeof RadixPopover.Content>['align'];
  className?: string;
}

export const Popover = ({ children, trigger, side, align, className }: PropsWithChildren<Props>) => {
  return (
    <RadixPopover.Root>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content side={side} align={align} className={cx(styles.popoverContent, className)} sideOffset={5}>
          {children}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};
