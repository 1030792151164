const isCloudinaryUrl = (url: string) => url && url.startsWith('https://res.cloudinary.com/');

const addTransformAttributesToUrl = (url: string, attributes: string, quality = 80, format = 'auto') => {
  if (!isCloudinaryUrl(url)) {
    return url;
  }
  const [prefix, rest] = url.split('/image/upload/');
  return `${prefix}/image/upload/${attributes}/f_${format}/q_${quality}/${rest}`;
};

export const cloudinaryUrlOptimize = (
  url: string,
  {
    width,
    height,
    quality,
    format,
  }: {
    width?: number;
    height?: number;
    quality?: number;
    format?: 'avif' | 'auto' | 'svg';
  }
) => {
  if (url === undefined || url === null) {
    return url;
  }
  const attributes = ['c_fill', width ? `,w_${width}` : '', height ? `,h_${height}` : ''].join('');
  const newUrl = addTransformAttributesToUrl(url, attributes, quality, format);
  return newUrl;
};
