import { useState } from 'react';
import styles from './black-friday.module.scss';
import cx from 'classnames';
import { Button, Modal, Typography } from '../../../core';
import { useIsMobile } from '../../../../hooks/use-is-mobile/use-is-mobile';

interface Props {
  currencySymbol: string;
  mode: 'giveaway' | 'coupon';
}

const BlackFriday = (props: Props) => {
  const isMobile = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalTitle = props.mode === 'giveaway' ? 'Ski for Free!' : 'Ski, Save and Win!';
  const modalContent =
    props.mode === 'giveaway' ? (
      <>
        Book a ski trip between 13/11/2024 and 02/12/2024 and you will automatically be entered into our ‘Ski for Free’
        raffle to win back the cost of your ski trip up to {props.currencySymbol}1500! Winner will be announced by
        14/12/2024.
      </>
    ) : (
      <>
        Our Black Friday sale is live - get up to {props.currencySymbol}500 off your ski trip today! Enter the code{' '}
        <strong>
          <i>‘BFSKI24’</i>
        </strong>{' '}
        at checkout to apply your unique discount. Plus, book your trip between 13/11/2024 and 02/12/2024 to be entered
        into a raffle to win back the cost of your holiday up to {props.currencySymbol}1500! Winner will be announced by
        14/12/2024.
      </>
    );
  return (
    <div className={cx(styles.container)}>
      {props.mode === 'giveaway' && (
        <>
          Black Friday: book now for the chance to ski for FREE with our <strong>{props.currencySymbol}1500</strong>{' '}
          giveaway!{' '}
        </>
      )}
      {props.mode === 'coupon' && (
        <>
          <strong>Black Friday Sale:</strong> up to <div className={styles.tilted}>{props.currencySymbol}500 OFF</div>{' '}
          today with 'BFSKI24'. Win our 'Ski for Free' raffle!{' '}
        </>
      )}

      <span
        className={styles.detailsButton}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Details
      </span>
      <Modal
        closable
        mode={isMobile ? 'bottomSheet' : 'modal'}
        open={isModalOpen}
        showCloseButton
        onClose={() => {
          setIsModalOpen(false);
        }}
        className={styles.modal}
      >
        <Typography variant="H500">Black Friday: {modalTitle}</Typography>
        <Typography variant="P300" className={styles.text}>
          {modalContent}
        </Typography>
        <div className={styles.bottom}>
          <Button
            variant="main"
            backgroundColor="black"
            onClick={() => {
              setIsModalOpen(false);
            }}
            className={styles.button}
          >
            Ok, got it!
          </Button>
          <a
            href={
              props.mode === 'coupon'
                ? 'https://terms.weski.com/docs/black-friday-2024-discount-code'
                : 'https://terms.weski.com/docs/black-friday-2024-raffle'
            }
          >
            Terms and conditions
          </a>
        </div>
      </Modal>
    </div>
  );
};

export default BlackFriday;
