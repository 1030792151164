'use client';

import { Typography } from '../../../core';
import styles from './fixed-deposit.module.scss';

interface FixedDepositProps {
  fixedDeposit: string;
}

const FixedDeposit = ({ fixedDeposit }: FixedDepositProps) => {
  return (
    <Typography variant={'P300'} mobileVariant={'B400'} className={styles.fixedDepositText}>
      <strong>NEW! Secure your trip today for just {fixedDeposit}pp</strong>
    </Typography>
  );
};

export default FixedDeposit;
