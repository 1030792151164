import { useEffect, useState } from 'react';
import {
  CountryPick,
  ResortPick,
  DestinationSearch,
  DestinationType,
  PickType,
  SkiAreaPick,
} from '../form-fields/destination-form-section/types';
import { getKeysWithTruthyValue } from '../../../../utils/object';
import { countries } from '@weski-monorepo/utils';
import { SearchInitData, SeasonsDates } from '@weski-monorepo/types';
const getFirstSaturdayOfJanuary = (from: Date, to: Date): Date | null => {
  const fromYear = from.getFullYear();
  const toYear = to.getFullYear();

  for (let year = fromYear; year <= toYear; year++) {
    for (let day = 1; day <= 31; day++) {
      const date = new Date(year, 0, day);
      if (date.getDay() === 6 && date >= from && date <= to) {
        return date;
      }
    }
  }

  return null;
};

const getSaturdayInTwoWeeks = (): Date => {
  const today = new Date();
  const twoWeeksLater = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
  const daysUntilSaturday = (6 - twoWeeksLater.getDay() + 7) % 7;
  twoWeeksLater.setDate(twoWeeksLater.getDate() + daysUntilSaturday);
  return twoWeeksLater;
};

const getFirstSaturday = (from: Date) => {
  const dayOfWeek = from.getDay();
  const daysUntilSaturday = (6 - dayOfWeek + 7) % 7;
  const firstSaturday = new Date(from);
  firstSaturday.setDate(from.getDate() + daysUntilSaturday + 1);
  return firstSaturday;
};

const getDestinationSeasonsDates = (destinationSearch: DestinationSearch, data: SearchInitData): SeasonsDates => {
  if (destinationSearch.type === DestinationType.ByPreference) {
    const country = getKeysWithTruthyValue(destinationSearch.resortPreferences.country)[0];
    const countryCode = country === 'flexible' ? 'flexible' : countries.getCountryCodeByName(country).toLowerCase();
    return {
      current: {
        start: data.destinationsAvailableDates[countryCode].current.start,
        end: data.destinationsAvailableDates[countryCode].current.end,
      },
      next: {
        start: data.destinationsAvailableDates[countryCode].next.start,
        end: data.destinationsAvailableDates[countryCode].next.end,
      },
    };
  }

  if (destinationSearch.pickType === PickType.Flexible) {
    return {
      current: {
        start: data.destinationsAvailableDates.flexible.current.start,
        end: data.destinationsAvailableDates.flexible.current.end,
      },
      next: {
        start: data.destinationsAvailableDates.flexible.next.start,
        end: data.destinationsAvailableDates.flexible.next.end,
      },
    };
  }

  if (destinationSearch.pickType === PickType.Country) {
    const countryCode = (destinationSearch.bySpecificPicks[0] as CountryPick).countryCode.toLowerCase();
    return {
      current: {
        start: data.destinationsAvailableDates[countryCode].current.start,
        end: data.destinationsAvailableDates[countryCode].current.end,
      },
      next: {
        start: data.destinationsAvailableDates[countryCode].next.start,
        end: data.destinationsAvailableDates[countryCode].next.end,
      },
    };
  }

  if (destinationSearch.pickType === PickType.SkiArea) {
    const destinationId = (destinationSearch.bySpecificPicks[0] as SkiAreaPick).skiArea;
    return data.destinations.find((destination) => destination.id === destinationId)!.seasonsDates;
  }

  const destinationId = (destinationSearch.bySpecificPicks[0] as ResortPick).resort;
  return data.destinations.find((destination) => destination.id === destinationId)!.seasonsDates;
};

export const useDateRangeOptions = (destinationSearch: DestinationSearch, data: SearchInitData) => {
  const [datesOptions, setDatesOptions] = useState<{
    seasonsDates: SeasonsDates;
    valueFrom: Date;
    valueTo: Date;
  }>();

  useEffect(() => {
    if (!data) {
      return;
    }

    const seasonsDates = getDestinationSeasonsDates(destinationSearch, data);

    // Todo: current/next season modification
    const { start: minDate, end: maxDate } = seasonsDates.next;

    const fromDate = new Date(
      Math.max(
        getFirstSaturdayOfJanuary(new Date(minDate), new Date(maxDate))?.getTime() || 0,
        getSaturdayInTwoWeeks().getTime(),
        getFirstSaturday(new Date(minDate)).getTime()
      )
    );

    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 7);

    if (JSON.stringify(seasonsDates) !== JSON.stringify(datesOptions?.seasonsDates)) {
      setDatesOptions({ seasonsDates, valueFrom: fromDate, valueTo: toDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationSearch, setDatesOptions, data]);

  return datesOptions;
};
