import React from 'react';
import styles from './resort-thumbnail.module.scss';
import { Icon } from '../../../../../../../core';
import { cloudinaryResize } from '../../../../../../../../utils/cloudinary';

interface Props {
  src?: string;
  alt?: string;
}

export const ResortThumbnail = React.memo(({ src, alt }: Props) => {
  if (!src) {
    return <Icon icon="SkiMountain" />;
  }
  const resizedSrc = resize(src);
  // eslint-disable-next-line
  return <img className={styles.img} src={resizedSrc} alt={alt}></img>;
});

function resize(url: string) {
  return cloudinaryResize(url, { width: 86 });
}

ResortThumbnail.displayName = 'ResortThumbnail';

export default ResortThumbnail;
