import React, { PropsWithChildren } from 'react';
import styles from './empty-state.module.scss';
import Icon from '../../../icon/icon';
import Typography from '../../../typography/typography';

const EmptyState = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div className={styles.container}>
      <div>
        <Icon icon="MagnifyingGlass" width={32} />
        <Typography variant="H200">{`We didn't find any matches`}</Typography>
        <Typography variant="P200">{children}</Typography>
      </div>
    </div>
  );
};

export default EmptyState;
