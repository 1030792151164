import { IconName } from '../../../../../core/icon/icon-names';
import styles from './component-field.module.scss';
import Icon from '../../../../../core/icon/icon';
import Typography from '../../../../../core/typography/typography';
import React from 'react';
import { ComponentsSearchField } from '@weski-monorepo/types';

const getComponentField = (component: string, icon: IconName) => (
  <div className={styles.container} key={component}>
    <Icon icon={icon} cursor="pointer" />
    <Typography className={styles.text} variant="B400" mobileVariant="P100">
      {component}
    </Typography>
  </div>
);

export const componentsFields: { [key in keyof ComponentsSearchField]: JSX.Element } = {
  accommodation: getComponentField('Stay', 'Bed'),
  flight: getComponentField('Flights', 'TakeOff'),
  transfer: getComponentField('Transfers', 'BusProfile'),
  skiPass: getComponentField('Ski pass', 'Card'),
};
