import { DestinationsSearchField } from '@weski-monorepo/types';
import { CountryPick, ResortPick, DestinationSearch, PickType } from '../index';
import { getKeysWithTruthyValue } from '../../../../utils/object';
import { countries } from '@weski-monorepo/utils';
import { SkiAreaPick } from '../form-fields/destination-form-section/types';

export const tripBuilderDestinationFromFormData = (destinationsSearch: DestinationSearch): DestinationsSearchField => {
  if (destinationsSearch.type === 'byPreference') {
    return resortByPreferenceFormDataToTripBuilderDestinations(destinationsSearch);
  }

  return specificResortPickFormDataToTripBuilderQuery(destinationsSearch);
};

const resortByPreferenceFormDataToTripBuilderDestinations = (
  destinationsSearch: DestinationSearch
): DestinationsSearchField => {
  const resortPreferences = destinationsSearch.resortPreferences;
  const countryNames = resortPreferences.country.flexible ? [] : getKeysWithTruthyValue(resortPreferences.country);
  const countryCodes = countryNames.map(countries.getCountryCodeFromName);
  const aboutYou = getKeysWithTruthyValue(resortPreferences.aboutYou);
  const importantProperties = getKeysWithTruthyValue(resortPreferences.importantProperties);
  return {
    type: 'byResortPreferences',
    ...(countryCodes.length > 0 ? { countryCodes } : {}),
    ...(aboutYou.length > 0 ? { aboutYou } : {}),
    ...(importantProperties.length > 0 ? { mostImportant: importantProperties } : {}),
  };
};

const specificResortPickFormDataToTripBuilderQuery = (
  destinationSearch: DestinationSearch
): DestinationsSearchField => {
  if (destinationSearch.pickType === PickType.Country) {
    return [{ type: 'country', id: (destinationSearch.bySpecificPicks as CountryPick[])[0].countryCode }];
  }
  if ([PickType.Village, PickType.Resort].includes(destinationSearch.pickType)) {
    return [Number(((destinationSearch.bySpecificPicks as ResortPick[])[0].resort as string).split('-')[1])];
  }
  if (destinationSearch.pickType === PickType.SkiArea) {
    return [
      {
        type: 'skiArea',
        id: Number(((destinationSearch.bySpecificPicks as SkiAreaPick[])[0].skiArea as string).split('-')[1]),
      },
    ];
  }
  if (destinationSearch.pickType === PickType.Flexible) {
    return [{ type: 'flexible', id: 0 }];
  }
  throw Error('flexible not yet supported');
};
