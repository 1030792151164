import React from 'react';
import { mapperByKeyName } from '../destination-popover/by-resort-preferences/value-mappers';
import { DestinationBySpecificPicks, DestinationSearch, DestinationType, ResortPreferences } from '../types';
import { countries } from '@weski-monorepo/utils';
import { Destination } from '@weski-monorepo/types';

export function useTripDestinationsValueText(
  search: DestinationSearch,
  destinations: Destination[],
  options: { appendCountryNameForResort?: boolean } = {}
) {
  const isByPreference = search.type === DestinationType.ByPreference;

  return React.useMemo(() => {
    if (!search || !destinations) {
      return '';
    }

    if (isByPreference) {
      return getStringDescriptionOfResortPreferences(search.resortPreferences);
    }
    const value = getStringDescriptionOfSpecificPick(search.bySpecificPicks, destinations);
    const specificPick = search.bySpecificPicks[0];
    if (options.appendCountryNameForResort) {
      if ('resort' in specificPick) {
        const resort = destinations.find((r) => r.id === specificPick.resort)!;
        return `${value}, ${resort.countryName}`;
      }
    }
    return value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
}

function getStringDescriptionOfResortPreferences(resortPreferences: ResortPreferences) {
  const elements = Object.entries(resortPreferences)
    .flatMap(([key, value]) => Object.entries(value).map(([k, v]) => [key, k, v] as const))
    .filter(([, , value]) => value)
    .map(([category, key]) => {
      const mapper = mapperByKeyName[category];
      return mapper[key].label;
    });
  return elements.join(' • ');
}

function getStringDescriptionOfSpecificPick([specificPick]: DestinationBySpecificPicks, destinations: Destination[]) {
  if ('countryCode' in specificPick) {
    return countries.getCountryNameById(specificPick.countryCode.toUpperCase());
  }
  if ('villageName' in specificPick) {
    return specificPick.villageName;
  }
  if ('resort' in specificPick) {
    return destinations.find((r) => r.id === specificPick.resort)?.name;
  }
  if ('skiArea' in specificPick) {
    return destinations.find((r) => r.id === specificPick.skiArea.toString())?.name;
  }

  return "I'm flexible";
}
