'use client';

import React, { ComponentProps, PropsWithChildren } from 'react';
import { Popover } from '../popover/popover';
import cx from 'classnames';
import styles from './menu.module.scss';
import { MenuItem } from './menu-item/menu-item';
import { MenuDivider } from './menu-divider/menu-divider';

export const Menu = ({ children, ...props }: PropsWithChildren<ComponentProps<typeof Popover>>) => {
  return (
    <Popover {...props} className={cx(styles.menu, props.className)}>
      {children}
    </Popover>
  );
};

Menu.Item = MenuItem;
Menu.Divider = MenuDivider;
