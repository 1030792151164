import { DateRange } from 'react-day-picker';
import { DateType } from './form-fields/date-picker-form-section/date-picker-form-section';
import { GroupSize } from './form-fields/group-size-form-section/group-size-popover/group-size-popover';
import { DestinationSearch } from './form-fields/destination-form-section/types';
import { ComponentsSearchField, OriginAirport, SearchInitData } from '@weski-monorepo/types';

export enum OriginType {
  Airport = 'airport',
}

export enum FlexibleDurationType {
  Week = 'week',
  Weekend = 'weekend',
}

export enum FlexibleType {
  Month = 'month',
  Season = 'season',
  LastMinute = 'lastMinute',
  Holiday = 'holiday',
}

export interface FlexibleWhen {
  month?: number;
  year?: number;
  season?: number;
  holiday?: string;
}

export interface FlexibleDatesSearch {
  type: FlexibleType;
  duration: FlexibleDurationType;
  when: FlexibleWhen;
}

export enum DateRangePadding {
  Exact,
  OneDay,
}

export interface DateRangeSearch {
  range?: DateRange;
  padding: DateRangePadding;
}

export interface DatesSearch {
  type: DateType;
  dateRangeSearch: DateRangeSearch;
  flexibleDatesSearch?: FlexibleDatesSearch;
}

export interface SearchFormFields {
  rooms: GroupSize;
  dates: DatesSearch;
  departingFrom: { type: OriginType; id: string };
  components: ComponentsSearchField;
  destinations: DestinationSearch;
  disableFlights: boolean;
  loadedFromRecent?: boolean;
}

export interface SearchFormExtendedInitData extends SearchInitData {
  nearestAirport: OriginAirport | null;
}
