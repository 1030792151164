import React, { useMemo } from 'react';
import styles from './flexible-option-btn.module.scss';
import { IconName } from '../../../../../core/icon/icon-names';
import Icon from '../../../../../core/icon/icon';
import classNames from 'classnames';
import Typography from '../../../../../core/typography/typography';
import { DateTime } from 'luxon';
import { FlexibleType, FlexibleWhen } from '../../../types';

interface Props {
  isSelected: boolean;
  type: FlexibleType;
  option?: FlexibleWhen;
  icon?: IconName;
  onClick: () => void;
  label?: string;
}

const FlexibleOptionButton = ({ isSelected, option, onClick, label, type, icon = 'Calendar' }: Props) => {
  const optionLabel = useMemo(() => label || getOptionLabel(type, option), [label, type, option]);

  return (
    <div className={classNames(styles.container, { [styles.selected]: isSelected })} onClick={onClick}>
      <Icon icon={icon} />
      <Typography variant="B400" className={classNames({ [styles.textSelected]: isSelected })}>
        {optionLabel}
      </Typography>
    </div>
  );
};

const getOptionLabel = (type: FlexibleType, option?: FlexibleWhen) => {
  if (type === FlexibleType.Season) {
    return 'Anytime';
  }

  if (type === FlexibleType.LastMinute) {
    return 'Last minute';
  }

  const date = DateTime.now().startOf('month').set({ month: option!.month! });
  const month = date.toJSDate().toLocaleString('default', { month: 'short' });

  return `${month}‘ ${option!.year! % 1000}`;
};

export default FlexibleOptionButton;
