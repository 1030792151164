'use client';

import React, { PropsWithChildren } from 'react';
import styles from './chip.module.scss';
import cx from 'classnames';

interface Props {
  className?: string;
  selected?: boolean;
}

const Chip = ({ children, className, selected = false }: PropsWithChildren<Props>) => {
  return <div className={cx(styles.chip, className, { [styles.selected]: selected })}>{children}</div>;
};

export default Chip;
