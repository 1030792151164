'use client';

import { FC } from 'react';
import styles from './ski-holidays-guides-section.module.scss';
import { ArticleCard, Button, Responsive, SectionHeadline } from '../../../core';
import classNames from 'classnames';
import { SkiHolidayGuideData, SkiHolidaysGuidesData } from './ski-holidays-guides-data';
import { cloudinaryUrlOptimize } from '../../../core/cdn-image/cloudinary';

interface SkiHolidaysGuidesSectionProps {
  seeAllLink: string;
  data?: SkiHolidayGuideData[];
  className?: string;
}

const createGuideToDisplay = (guideData: SkiHolidayGuideData, index: number) => {
  const { title, description, href, imgSrc, minTimeToRead } = guideData;
  return (
    <ArticleCard
      imageAlt={guideData.imageAlt}
      key={'article' + index}
      title={title}
      imgSrc={cloudinaryUrlOptimize(imgSrc, { width: 600, quality: 90 })}
      description={description}
      minTimeToRead={minTimeToRead}
      href={href}
      className={styles.articleContainer}
    />
  );
};

const SkiHolidaysGuidesSection: FC<SkiHolidaysGuidesSectionProps> = ({ className, data, seeAllLink }) => {
  return (
    <div className={classNames(styles.sectionContainer, className)}>
      <SectionHeadline title="Ski holiday guides" link={{ text: 'See all guides', href: seeAllLink }} />
      <Responsive.DesktopOnly>
        <div className={styles.body}>
          {(data ?? SkiHolidaysGuidesData).map((guide, index) => createGuideToDisplay(guide, index))}
        </div>
      </Responsive.DesktopOnly>
      <Responsive.MobileOnly>
        <div className={styles.body}>
          {(data ?? SkiHolidaysGuidesData).slice(0, 2).map((guide, index) => createGuideToDisplay(guide, index))}
          <Button variant="outlined" className={styles.seeAllGuides} hrefLink="/guides">
            See all guides
          </Button>
        </div>
      </Responsive.MobileOnly>
    </div>
  );
};

export default SkiHolidaysGuidesSection;
