export const Stats = [
  {
    number: 70000,
    title: 'happy customers',
  },
  {
    number: 350,
    title: 'great partners',
  },
  {
    number: 65000,
    title: 'listed stays',
  },
  {
    number: 223,
    title: 'beautiful resorts',
    exactNumber: true,
  },
];
