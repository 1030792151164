'use client';

import TopBanner from '../../core/top-banner/top-banner';
import Generic from './notification-bar-types/generic';
import FixedDeposit from './notification-bar-types/fixed-deposit';
import BlackFriday from './notification-bar-types/black-friday';

interface NotificationBarProps {
  variant: 'generic' | 'fixedDeposit' | 'none' | 'blackFridayGiveaway' | 'blackFridayCoupon';
  fixedDeposit: string;
  openWhatsappChat: () => void;
  currencySymbol: string;
  className?: string;
}

const NotificationBar = ({
  variant,
  fixedDeposit,
  openWhatsappChat,
  currencySymbol,
  className,
}: NotificationBarProps) => {
  return (
    <TopBanner shouldShow={variant !== 'none'} className={className}>
      <>
        {variant === 'fixedDeposit' && <FixedDeposit fixedDeposit={fixedDeposit} />}
        {variant === 'generic' && <Generic openWhatsappChat={openWhatsappChat} />}
        {variant === 'blackFridayGiveaway' && <BlackFriday currencySymbol={currencySymbol} mode="giveaway" />}
        {variant === 'blackFridayCoupon' && <BlackFriday currencySymbol={currencySymbol} mode="coupon" />}
      </>
    </TopBanner>
  );
};

export default NotificationBar;
