import React from 'react';
import { avatarPlaceholder, reviewsSection, weSkiTrustPilotLink } from '../links';

import styles from './card.module.scss';
import { Typography } from '../../../core';

interface ReviewCardProps {
  stars: number;
  title: string;
  content: string;
  consumer: {
    displayName: string;
    picture: string | null;
  };
}

const ReviewCard: React.FC<ReviewCardProps> = ({ stars, title, content, consumer: { displayName, picture } }) => {
  return (
    <a className={styles.cardContainer} href={weSkiTrustPilotLink} target={'_blank'} rel="noopener noreferrer">
      <div className={styles.personContainer}>
        <div className={styles.avatar} style={{ backgroundImage: `url(${picture ?? avatarPlaceholder})` }} />
        <div className={styles.nameAndDate}>
          <Typography variant="H100" className={styles.name}>
            {displayName}
          </Typography>
        </div>
      </div>
      <Typography variant="H200" className={styles.title} headTitle={'h3'}>
        {title}
      </Typography>
      <Typography variant="B400" className={styles.review} headTitle={'p'}>
        {content}
      </Typography>

      <div className={styles.rating}>
        {Array.from(Array(stars)).map((_, i) => (
          <img key={i} className={styles.star} src={reviewsSection.starRating} alt={'review star'} />
        ))}
      </div>
    </a>
  );
};

export default ReviewCard;
