import React, { useEffect, useState } from 'react';
import styles from './carousel-image.module.scss';
import LazyLoad from 'react-lazyload';

interface Props {
  url: string | null;
  index: number;
  shouldLoad: boolean;
}

const CarouselImage = ({ url, index, shouldLoad }: Props) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (shouldLoad) {
      setLoaded(true);
    }
  }, [shouldLoad]);

  return (
    <LazyLoad once={true} height={200} offset={1000}>
      {/* // TODO: FUTURE: hide picture */}
      {/* {isAdmin && (
          <div
            className={classNames('hide-button', 'admin-data', 'right')}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.hidePicture(e, url, itemId, itemType);
            }}
          >
            Hide photo
          </div>
        )} */}

      <div className={styles.shit}>
        <img
          className={styles.carouselImage}
          src={loaded && url ? toHttps(url) : undefined}
          onError={(e) => {
            (e.target as HTMLImageElement).src = placeholder;
          }}
          alt=""
          data-fetchpriority={index !== 0 ? 'Lowest' : ''}
        />
      </div>
    </LazyLoad>
  );
};

export default CarouselImage;

const toHttps = (url: string) => {
  return url?.replace(/^http:\/\//i, 'https://');
};

const placeholder = `https://res.cloudinary.com/ht4mr7djk/image/upload/v1607006951/App%20Assets/placeholderSmall.webp`;
