import SearchForm, { SearchFormLayout } from './search-form';
import SearchFormProvider from './search-form-provider';
import {
  SearchFormFields,
  DatesSearch,
  FlexibleDatesSearch,
  DateRangeSearch,
  FlexibleType,
  FlexibleWhen,
  DateRangePadding,
  FlexibleDurationType,
  OriginType,
  SearchFormExtendedInitData,
} from './types';
import { DateType } from './form-fields/date-picker-form-section/date-picker-form-section';
import {
  CountryPick,
  ResortPick,
  DestinationSearch,
  PickType,
  DestinationType,
} from './form-fields/destination-form-section/types';
import {
  destinationsResortPreferencesAboutYouOptions,
  destinationsResortPreferencesImportantPropertiesOptions,
  destinationsResortPreferencesCountryOptions,
} from './form-fields/destination-form-section/destination-popover/by-resort-preferences/constants';
import { formDataToSearchRequest } from './formaters/form-data-to-search-request';
import type { ActiveComponentFilters } from './filters/types';
import { searchRequestToSearchFormValues } from './formaters/search-request-to-form-values';
import { useTripDestinationsValueText } from './form-fields/destination-form-section/destination-form-section-value/use-destination-value-text';
import { getLabel as getDatesLabel } from './form-fields/date-picker-form-section/date-picker-input-label';
import { useFormContext } from 'react-hook-form';

export const useSearchFormContext = () => useFormContext<SearchFormFields>();

export {
  SearchForm,
  SearchFormLayout,
  FlexibleDurationType,
  FlexibleType,
  OriginType,
  DateRangePadding,
  DateType,
  PickType,
  DestinationType,
  destinationsResortPreferencesAboutYouOptions,
  destinationsResortPreferencesImportantPropertiesOptions,
  destinationsResortPreferencesCountryOptions,
  formDataToSearchRequest,
  SearchFormProvider,
  searchRequestToSearchFormValues,
  useTripDestinationsValueText,
  getDatesLabel,
};

export type {
  SearchFormFields,
  DatesSearch,
  FlexibleDatesSearch,
  DateRangeSearch,
  FlexibleWhen,
  CountryPick,
  ResortPick,
  DestinationSearch,
  ActiveComponentFilters,
  SearchFormExtendedInitData,
};
