'use client';

import React, { useEffect } from 'react';

const dataPropsPerWidget = {
  reviewsDesktop: {
    'data-locale': 'en-US',
    'data-template-id': '53aa8912dec7e10d38f59f36',
    'data-businessunit-id': '60b0da4ce1f09a00017d6041',
    'data-style-height': '140px',
    'data-style-width': '100%',
    'data-theme': 'light',
    'data-tags': 'Home-Page',
    'data-stars': '5',
    'data-review-languages': 'en',
  },
  smallStrip: {
    'data-locale': 'en-US',
    'data-template-id': '5419b732fbfb950b10de65e5',
    'data-businessunit-id': '60b0da4ce1f09a00017d6041',
    'data-style-height': '20px',
    'data-style-width': '270px',
    'data-theme': 'light',
  },
  miniMobile: {
    'data-locale': 'en-US',
    'data-template-id': '53aa8807dec7e10d38f59f32',
    'data-businessunit-id': '60b0da4ce1f09a00017d6041',
    'data-style-height': '90px',
    'data-style-width': '150px',
    'data-theme': 'light',
  },
  miniDesktop: {
    'data-locale': 'en-US',
    'data-template-id': '53aa8807dec7e10d38f59f32',
    'data-businessunit-id': '60b0da4ce1f09a00017d6041',
    'data-style-height': '108px',
    'data-style-width': '180px',
    'data-theme': 'light',
  },
  miniCarousel: {
    'data-locale': 'en-US',
    'data-template-id': '539ad0ffdec7e10e686debd7',
    'data-businessunit-id': '60b0da4ce1f09a00017d6041',
    'data-style-height': '350px',
    'data-style-width': '100%',
    'data-theme': 'light',
    'data-stars': '5',
    'data-review-languages': 'en',
  },
};

const TrustBox = ({ widgetType }: { widgetType: keyof typeof dataPropsPerWidget }) => {
  const trustBoxRef = React.useRef(null);

  useEffect(() => {
    const loadTrustpilotScript = () => {
      const script = document.createElement('script');
      script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      script.async = true;
      script.onload = () => {
        if ((window as any).Trustpilot) {
          (window as any).Trustpilot.loadFromElement(trustBoxRef.current, true);
        }
      };
      document.body.appendChild(script);
    };

    if (typeof window !== 'undefined') {
      loadTrustpilotScript();
    }
  }, []);

  return (
    <div ref={trustBoxRef} className="trustpilot-widget" {...dataPropsPerWidget[widgetType]}>
      <a href="https://www.trustpilot.com/review/weski.com" target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
};

export default TrustBox;
