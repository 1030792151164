import { ResortPick, DestinationType, SearchFormFields } from '../index';
import mapValues from 'lodash/mapValues';

export const searchDataToAnalytics = (data: SearchFormFields) => {
  const { departingFrom, rooms, dates, destinations } = data;
  return {
    'Resort Name': (destinations.bySpecificPicks[0] as ResortPick)?.resortName,
    'Group Size': rooms.adults + rooms.children,
    Children: rooms.children,
    'Children Ages': rooms.childrenAges,
    'Origin Airport': departingFrom.id.toUpperCase(),
    'Trip Start Date': dates.dateRangeSearch.range?.from,
    'Trip End Date': dates.dateRangeSearch.range?.to,
    'Flexible Dates - type': dates.type,
    'Flexible Dates - options': dates.type !== 'specific' ? JSON.stringify(dates.flexibleDatesSearch) : undefined,
    'Flexible Dates - plusMinusXDays': 0,
    destination: {
      ...destinations,
      bySpecificPicks: undefined,
      bySpecificPick: destinations.type === DestinationType.Specific ? destinations.bySpecificPicks[0] : undefined,
      resortPreferences:
        destinations.type === DestinationType.ByPreference
          ? mapValues(destinations.resortPreferences, (v: any) => Object.keys(v).filter((k) => v[k]))
          : undefined,
    },
    'Trip Components': 'Stay, Flight, Transfer',
  };
};
