import { RefObject } from 'react';

export const scrollToIfNotVisible = (ref: RefObject<HTMLElement>) => {
  setTimeout(() => {
    if (!ref?.current) {
      return;
    }

    const r = ref.current.getBoundingClientRect();
    const isFullyVisible = r.top >= 0 && r.bottom + 50 <= (window.innerHeight || document.documentElement.clientHeight);

    if (!isFullyVisible) {
      window.scrollTo({
        top: window.scrollY + r.bottom - window.innerHeight + 50,
        behavior: 'smooth',
      });
    }
  }, 0);
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
