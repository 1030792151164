'use client';

import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import * as Dialog from '@radix-ui/react-dialog';
import styles from './form-section.module.scss';
import { useIsMobile } from '../../../hooks/use-is-mobile/use-is-mobile';
import Typography from '../typography/typography';
import { useDisableBackgroundScrolling } from '../../../hooks/use-disable-background-scrolling/use-disable-background-scrolling';
import classNames from 'classnames';
import Icon from '../icon/icon';
import { Responsive } from '../../../components';
import { scrollToIfNotVisible, scrollToTop } from '../../../utils/scroll';
import { TestPage, addDataTestAttribute } from '../../../utils/data-test-id';

interface FormSectionProps {
  value: React.ReactNode;
  className?: string;
  open?: boolean;
  onOpenChange?: (v: boolean) => void;
  label?: string;
  forceLabel?: boolean;
  icon?: React.JSX.Element;
  description?: string;
  autoPopoverWidth?: boolean;
  autoTriggerHeight?: boolean;
  onTriggerClick?: () => void;
  disableMobileFullScreen?: boolean;
  showArrow?: boolean;
  scrollToTopOnClose?: boolean;
  tabIndex?: number;
  dataTestName?: string;
}

const FormSection = (props: PropsWithChildren<FormSectionProps>) => {
  const isMobile = useIsMobile();
  const isMobileFullScreen = isMobile && !props.disableMobileFullScreen;
  const BaseComponent = isMobileFullScreen ? Dialog : Popover;
  const triggerRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
  const [triggerWidth, setTriggerWidth] = useState(0);

  useEffect(() => {
    if (triggerRef.current) {
      setTriggerWidth(triggerRef.current.offsetWidth);
    }
    // eslint-disable-next-line
  }, [triggerRef.current]);

  const onOpenChange = useCallback(
    (open: boolean) => {
      props.onOpenChange && props.onOpenChange(open);
    },
    // eslint-disable-next-line
    [props.onOpenChange]
  );

  useEffect(() => {
    if (!isMobile) {
      props.open ? scrollToIfNotVisible(contentRef) : props.scrollToTopOnClose && scrollToTop();
    }
  }, [isMobile, props.open, props.scrollToTopOnClose]);

  useDisableBackgroundScrolling((isMobileFullScreen && props.open) || false);

  return (
    <div className={classNames(styles.container, props.className)} tabIndex={props.tabIndex}>
      <Responsive.DesktopOnly>
        {props.label && (
          <Typography variant="C100" className={styles.label}>
            {props.label.toUpperCase()}
          </Typography>
        )}
      </Responsive.DesktopOnly>
      <Responsive.MobileOnly>
        {props.label && props.forceLabel && (
          <Typography variant="C100" className={styles.label}>
            {props.label}
          </Typography>
        )}
      </Responsive.MobileOnly>
      <BaseComponent.Root open={props.open} onOpenChange={onOpenChange}>
        <BaseComponent.Trigger
          ref={triggerRef}
          asChild
          {...(props.dataTestName
            ? addDataTestAttribute({ page: TestPage.SearchForm, component: `${props.dataTestName}-trigger` })
            : {})}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.onTriggerClick?.();
            if (!props.open) {
              onOpenChange(true);
            }
          }}
        >
          <div
            className={classNames(styles.trigger, {
              [styles.open]: props.open,
              [styles.autoHeight]: props.description || props.autoTriggerHeight,
            })}
          >
            {props.icon || null}
            <div className={styles.valueAndDescriptionContainer}>
              <Responsive.MobileOnly>
                {props.description && <Typography variant="P100">{props.description}</Typography>}
              </Responsive.MobileOnly>
              <div className={classNames(styles.value, { [styles.noDescriptionValue]: !props.description })}>
                {props.value}
              </div>
            </div>
            {props.showArrow && (
              <div className={styles.arrowIconContainer}>
                <Icon icon="ChevronDown" cursor="pointer" />
              </div>
            )}
          </div>
        </BaseComponent.Trigger>
        <>
          {/* for a very annoying and unknown reason redixUI's overlay does not work well with the shadow dom */}
          {/* thats why I use  useDisableBackgroundScrollings */}
          {/* {BaseComponent === Dialog && <Dialog.Overlay />} */}
          <BaseComponent.Content
            onOpenAutoFocus={(e) => {
              e.preventDefault();
            }}
            align="start"
            asChild
            {...(isMobileFullScreen ? {} : { avoidCollisions: false })}
          >
            <div
              ref={contentRef}
              className={classNames(styles.content, { [styles.disableMobileView]: !isMobileFullScreen })}
              style={{
                ...(isMobileFullScreen
                  ? { minHeight: '100vh', maxHeight: 'auto' }
                  : { width: triggerWidth && !props.autoPopoverWidth ? `${triggerWidth}px` : 'auto' }),
              }}
            >
              {props.children}
            </div>
          </BaseComponent.Content>
        </>
      </BaseComponent.Root>
    </div>
  );
};

export default FormSection;
