import NumbersSection from '../../number-section/number-section';
import { SectionHeadline } from '../../../core';
import styles from './highlight-numbers-section.module.scss';

const HighlightNumbersSection = () => {
  return (
    <div className={styles.highlightNumbersSection}>
      <SectionHeadline title="The numbers speak for themselves" isSubHeadline={true} />
      <NumbersSection />
    </div>
  );
};

export default HighlightNumbersSection;
