export interface SkiHolidayGuideData {
  title: string;
  imgSrc: string;
  description: string;
  minTimeToRead: number;
  href: string;
  imageAlt: string;
}

export const SkiHolidaysGuidesData: SkiHolidayGuideData[] = [
  {
    title: "A complete guide to Val d'Isère",
    imgSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1684063072/App%20Assets/WeSki%20guides/Val_d_Ise%CC%80re_piste_map-min.jpg',
    description:
      'The perfect ski resort for all levels of skier and boarder, with some of the greatest lift-served off-piste in the French Alps.',
    minTimeToRead: 8,
    href: '/guides/ski-resorts/france/val-d-isere',
    imageAlt: "Snow-covered ski resort village in Val d'Isère with illuminated buildings at dusk",
  },
  {
    title: 'A complete guide to Val Thorens',
    imgSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1684063072/App%20Assets/WeSki%20guides/Val_Thorens-min.jpg',
    description: 'The highest ski resort in Europe, with one of the liveliest après-ski in the Alps.',
    minTimeToRead: 8,
    href: '/guides/ski-resorts/france/val-thorens',
    imageAlt: 'Snow-covered ski resort village in Val Thorens with illuminated buildings at dusk',
  },
  {
    title: 'A complete guide to Avoriaz',
    imgSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1684063069/App%20Assets/WeSki%20guides/Avoriaz_ski_resort_guide_-_Hero_image-min.jpg',
    description: 'The highest and easily accessible ski resort in the vast Portes du Soleil ski area.',
    minTimeToRead: 8,
    href: '/guides/ski-resorts/france/avoriaz',
    imageAlt: 'Snow-covered ski resort village in Avoriaz at daylight',
  },
];
