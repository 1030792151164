import { useMemo } from 'react';
import { Destination, DestinationType } from '@weski-monorepo/types';

export function useResorts(destinations: Destination[], isAdmin: boolean): Resort[] {
  return useMemo(
    () =>
      destinations
        .map((destination) => ({
          id: destination.id,
          name: destination.name,
          adminOnly: destination.adminOnly,
          alias: destination.alias || '',
          country: {
            code: destination.countryCode.toUpperCase(),
            name: destination.countryName,
          },
          images: {
            thumbnail: destination.thumbnail,
          },
          type: destination.type,
          subtitle: destination.subtitle,
          resortId: destination.resortId,
        }))
        .filter((resort) => {
          return resort.adminOnly ? isAdmin : true;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [destinations]
  );
}

export interface Resort {
  adminOnly?: boolean;
  id: string;
  name: string;
  alias: string;
  country: {
    code: string;
    name: string;
  };
  images: {
    thumbnail: string;
  };
  type: DestinationType;
  subtitle: string;
  resortId?: string;
}
