import _compact from 'lodash/compact';
import { kebabCase } from 'change-case';

export enum TestPage {
  Home = 'home',
  SearchResult = 'search-result',
  SearchForm = 'search-form',
}

interface TestComponentData {
  page?: TestPage;
  device?: 'desktop' | 'mobile';
  component: string;
}

export const getDataTestId = ({ device, page, component }: TestComponentData): string => {
  return _compact([device, page, component])
    .map((item) => kebabCase(item))
    .join(':');
};
export const addDataTestAttribute = (testComponentData: TestComponentData) => ({
  'data-testid': getDataTestId(testComponentData),
});
