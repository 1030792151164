import React, { useEffect, useRef } from 'react';
import styles from './origin-airport-select-form-section.module.scss';
import OriginAirportPopover from './mobile-origin-airport-popover/origin-airport-popover';
import classNames from 'classnames';
import { FormSection, Icon } from '../../../../core';
import { OriginAirport } from '@weski-monorepo/types';
import { OriginAirportFormSectionValue } from './origin-airport-form-section-value/origin-airport-form-section-value';

interface OriginAirportSelectProps {
  airports: OriginAirport[];
  value: string;
  onChange: (value: string) => void;
  showIcon?: boolean;
  className?: string;
  showLabel?: boolean;
  scrollToTopOnClose?: boolean;
  forceOpen?: boolean;
  onClose?: (isBack?: boolean) => void;
}

const TITLE = 'Leaving from';

export const OriginAirportSelectFormSection = ({
  airports,
  value,
  onChange,
  showIcon = true,
  showLabel = true,
  scrollToTopOnClose,
  forceOpen = false,
  className,
  onClose,
}: OriginAirportSelectProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsOpen(Boolean(forceOpen));
  }, [forceOpen]);

  const resetInput = () => {
    setInputValue('');
  };

  const onOpenChange = (isOpen: boolean, isBack?: boolean) => {
    setIsOpen(isOpen);
    if (!isOpen) {
      onClose && onClose(isBack);
      resetInput();
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOnSelect = (value: string) => {
    onChange(value);
    onOpenChange(false);
  };

  const valueLabel = airports.find((option) => option.id === value)?.name;

  return (
    <FormSection
      open={isOpen}
      onOpenChange={onOpenChange}
      autoPopoverWidth
      className={classNames(styles.formSection, className)}
      {...(showIcon ? { icon: <Icon icon="TakeOff" /> } : {})}
      {...(showLabel ? { label: TITLE } : {})}
      description={TITLE}
      scrollToTopOnClose={scrollToTopOnClose}
      dataTestName="origin-airport-picker"
      value={
        <OriginAirportFormSectionValue
          valueLabel={valueLabel}
          inputValue={inputValue}
          inputRef={inputRef}
          onInputChange={onInputChange}
        />
      }
      onTriggerClick={() => inputRef.current?.focus()}
    >
      <OriginAirportPopover
        autoCompleteTextInput={inputValue}
        onAutoCompleteTextInputChange={onInputChange}
        airport={value}
        onChange={handleOnSelect}
        airports={airports}
        onDone={() => {
          onOpenChange(false, true);
        }}
      />
    </FormSection>
  );
};
