import { FC } from 'react';
import styles from './scroll-button.module.scss';
import Icon from '../icon/icon';

interface ScrollButtonProps {
  side: 'left' | 'right';
  scrollFunction: (number: number) => void;
  buttonHeight?: number;
}

const ScrollButton: FC<ScrollButtonProps> = ({ side, scrollFunction, buttonHeight }) => {
  return (
    <div
      style={{ top: buttonHeight ? `${buttonHeight}%` : '50%' }}
      className={side === 'right' ? styles.scrollRightButtonsContainer : styles.scrollLeftButtonsContainer}
    >
      <div
        className={styles.scrollButton}
        onClick={() => {
          scrollFunction(side === 'right' ? 1400 : 0);
        }}
      >
        <Icon icon={side === 'right' ? 'ChevronRight' : 'ChevronLeft'} cursor="pointer" />
      </div>
    </div>
  );
};
export default ScrollButton;
