'use client';

import { FC } from 'react';
import { HeadTitleOption, Responsive, Typography } from '../';
import styles from './section-headline.module.scss';
import classNames from 'classnames';

interface SectionHeadlineProps {
  title: string;
  link?: {
    text: string;
    href: string;
  };
  isSubHeadline?: boolean;
  titleClassName?: string;
  headTitle?: HeadTitleOption;
  className?: string;
}

const SectionHeadline: FC<SectionHeadlineProps> = ({
  title,
  link,
  isSubHeadline,
  titleClassName,
  className,
  headTitle = 'h2',
}) => {
  return (
    <div className={classNames(styles.headline, className)}>
      <Typography
        className={titleClassName}
        variant={isSubHeadline ? 'H500' : 'H600'}
        mobileVariant={isSubHeadline ? 'H400' : 'H500'}
        headTitle={headTitle}
      >
        {title}
      </Typography>
      {link && (
        <Responsive.DesktopOnly className={styles.linkContainer}>
          <a className={styles.link} href={link.href}>
            <Typography variant="LB500">{link.text}</Typography>
          </a>
        </Responsive.DesktopOnly>
      )}
    </div>
  );
};

export default SectionHeadline;
