import {
  DateRangePadding,
  FlexibleDurationType,
  FlexibleType,
  OriginType,
  SearchFormExtendedInitData,
  SearchFormFields,
} from './types';
import { DateType } from './form-fields/date-picker-form-section/date-picker-form-section';
import {
  DestinationSearch,
  DestinationType as DestinationSearchType,
  PickType,
} from './form-fields/destination-form-section/types';
import { Destination, DestinationType } from '@weski-monorepo/types';

const DEFAULT_SPECIFIC_RESORT = 'resort-1';
const DEFAULT_ORIGIN_AIRPORT_ID = '[LCY,LGW,LHR,LTN,SEN,STN]';

export const getMergedDefaultValues = (
  disableFlights: boolean,
  data?: SearchFormExtendedInitData,
  destinationId?: string,
  defaultValues?: Partial<SearchFormFields>
): SearchFormFields => {
  const baseDefaultValues = getSearchFormDefaultValues(disableFlights);

  const mergedDefaultValues = {
    ...baseDefaultValues,
    ...defaultValues,
    dates: { ...baseDefaultValues.dates, ...defaultValues?.dates },
    destinations: {
      ...baseDefaultValues.destinations,
      ...defaultValues?.destinations,
      ...getDestinationsDefaultValue(data?.destinations || [], destinationId),
    },
  };

  if (data?.originAirports.options.length === 1) {
    mergedDefaultValues.departingFrom = { type: OriginType.Airport, id: data.originAirports.options[0].id };
  } else if (data?.nearestAirport && !mergedDefaultValues.loadedFromRecent) {
    mergedDefaultValues.departingFrom = { type: OriginType.Airport, id: data.nearestAirport.id };
  }

  return mergedDefaultValues;
};

const getSearchFormDefaultValues = (disableFlights: boolean): SearchFormFields => ({
  disableFlights,
  departingFrom: { type: OriginType.Airport, id: DEFAULT_ORIGIN_AIRPORT_ID },
  dates: {
    type: DateType.Specific,
    dateRangeSearch: {
      range: {
        // Todo: current/next season modification
        from: new Date(2025, 2, 8),
        to: new Date(2025, 2, 15),
      },
      padding: DateRangePadding.Exact,
    },
    flexibleDatesSearch: {
      type: FlexibleType.Month,
      duration: FlexibleDurationType.Week,
      // Todo: current/next season modification
      when: { month: 1, year: 2025 },
    },
  },
  rooms: { adults: 2, children: 0, childrenAges: [] },
  components: disableFlights
    ? { accommodation: true, flight: false, transfer: false, skiPass: true }
    : {
        accommodation: true,
        flight: true,
        transfer: true,
        skiPass: false,
      },
  destinations: {
    type: DestinationSearchType.Specific,
    pickType: PickType.Resort,
    bySpecificPicks: [{ resort: DEFAULT_SPECIFIC_RESORT }],
    resortPreferences: {
      country: {
        flexible: true,
        andorra: false,
        austria: false,
        bulgaria: false,
        france: false,
        georgia: false,
        italy: false,
        switzerland: false,
        usa: false,
        canada: false,
      },
      aboutYou: {
        advanced: false,
        beginners: false,
        family: false,
        group: false,
        intermediates: false,
        snowboarders: false,
      },
      importantProperties: {
        apresSki: false,
        bigSkiArea: false,
        luxuryTrip: false,
        shortDriving: false,
        skiInOut: false,
        snowSure: false,
      },
    },
  },
});

const getDestinationsDefaultValue = (
  destinations: Destination[],
  destinationId?: string
): Partial<DestinationSearch> => {
  if (destinationId && destinations.length > 0) {
    const destination = destinations.find((destination) => destination.id === destinationId);

    if (destination) {
      if (destination?.type === DestinationType.Village) {
        return { bySpecificPicks: [{ resort: destination.resortId!, villageName: destination.name }] };
      }

      if (destination?.type === DestinationType.SkiArea) {
        return { bySpecificPicks: [{ skiArea: destination.id }], pickType: PickType.SkiArea };
      }

      return { bySpecificPicks: [{ resort: destination.id }] };
    }
  }

  return {};
};
