import { DateTime } from 'luxon';

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

/**
 * generates all the flexible dates options (months) for a range of dates
 */
export function allOptionsFromRange(fromString: string, toString: string) {
  const from = DateTime.fromISO(fromString).startOf('month');
  const to = DateTime.fromISO(toString).endOf('month');
  const today = DateTime.now();

  const minFlexibleDatesOption = today.startOf('month');

  const monthsArray = [];

  let cursor = from;
  while (cursor <= to) {
    if (cursor >= minFlexibleDatesOption) {
      monthsArray.push({ month: cursor.month, year: cursor.year });
    }
    cursor = cursor.plus({ month: 1 });
  }

  return monthsArray;
}

export const getMonthName = (monthIndex: number): string => {
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
  const date = DateTime.now().startOf('month').set({ month: monthIndex });

  return formatter.format(date.toJSDate());
};
