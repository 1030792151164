'use client';

import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { getMergedDefaultValues } from './default-values';
import { SearchFormFields } from './types';

interface Props {
  disableFlights?: boolean;
  defaultOverrides?: Partial<SearchFormFields>;
  destinationId?: string;
}

export default function SearchFormProvider({
  children,
  disableFlights = false,
  defaultOverrides,
  destinationId,
}: PropsWithChildren<Props>) {
  const [defaultValues, setDefaultValues] = useState(
    getMergedDefaultValues(disableFlights, undefined, destinationId, defaultOverrides)
  );

  const methods = useForm<SearchFormFields>({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    const newDefaultValues = getMergedDefaultValues(disableFlights, undefined, destinationId, defaultOverrides);

    if (JSON.stringify(newDefaultValues) !== JSON.stringify(defaultValues)) {
      methods.reset(getMergedDefaultValues(disableFlights, undefined, destinationId, defaultOverrides));
      setDefaultValues(newDefaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOverrides]);

  return <FormProvider {...methods}>{children}</FormProvider>;
}
