'use client';

import React, { useState } from 'react';
import styles from './chip-selection-segment.module.scss';
import Icon from '../icon/icon';
import { IconName } from '../icon/icon-names';

interface ChipSelectionSegmentProps<Option> {
  options: {
    value: Option;
    label: string;
    selected?: boolean;
    icon?: IconName;
    multiColorIcon?: boolean;
  }[];
  onOptionClick: (option: Option) => void;
  maxVisible?: number;
  enableShowLess?: boolean;
  columns: number;
}

const ChipSelectionSegment = ({
  options,
  onOptionClick,
  columns,
  maxVisible,
  enableShowLess = true,
}: ChipSelectionSegmentProps<any>) => {
  const selectedOptionIndex = options.findIndex((o) => o.selected);
  const isExpandable = maxVisible && selectedOptionIndex < maxVisible && options.length > maxVisible;
  const [expanded, setExpanded] = useState(!isExpandable);
  return (
    <div className={styles.container} style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}>
      {options.slice(0, expanded || !isExpandable ? Infinity : maxVisible).map((option) => (
        <div
          key={option.value}
          onClick={() => {
            onOptionClick(option.value);
          }}
          className={`${styles.chip} ${option.selected ? styles.selected : ''}`}
        >
          {option.icon && <Icon icon={option.icon} isMultiColor={option.multiColorIcon} />}
          <div>{option.label}</div>
        </div>
      ))}
      {isExpandable && (enableShowLess || !expanded) && (
        <div
          className={styles.toggle}
          onClick={() => {
            setExpanded((e) => !e);
          }}
        >
          <span>See {expanded ? 'less' : 'more'}</span>
          <Icon icon={expanded ? 'ChevronUp' : 'ChevronDown'} />
        </div>
      )}
    </div>
  );
};

export default ChipSelectionSegment;
