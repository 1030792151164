import React from 'react';
import { FormSectionTextValue } from '../../../../../core';

interface GroupSizeFormSectionValueProps {
  adultsCount: number;
  childrenCount: number;
}

export const GroupSizeFormSectionValue = ({ adultsCount, childrenCount }: GroupSizeFormSectionValueProps) => {
  const totalCount = adultsCount + childrenCount;
  return (
    <FormSectionTextValue id="guests-count-value" value={`${totalCount} ${totalCount > 1 ? 'guests' : 'guest'}`} />
  );
};
