'use client';

import { LegacyBreadcrumbs, CdnImage, InfoSection, Responsive, Separator } from '../../core';
import styles from './book-with-confidence.module.scss';
import { BookWithConfidenceContent } from './book-with-confidence-content';
import InspirationCard from './inspiration-card/inspiration-card';
import WaysWeHaveYouCoveredSection from './ways-we-have-you-covered-section/ways-we-have-you-covered-section';
import { FC } from 'react';
import TrustPilotSection from '../trust-pilot-section/trust-pilot-section';

const BookWithConfidence: FC<{
  pathname: string;
  daysToPay?: number;
  ctaOnClick?: () => void;
  fixedDepositValue?: string;
}> = ({
  pathname,
  daysToPay = 90,
  ctaOnClick = () => {
    alert('cta on click');
  },
  fixedDepositValue,
}) => {
  const [mainSection, ...sections] = Object.values(BookWithConfidenceContent);
  const lastItem = sections.pop();
  return (
    <div className={styles.container}>
      <Responsive.DesktopOnly className={styles.themeImage}>
        <CdnImage
          alt="Ski mountains"
          className={styles.image}
          src="https://res.cloudinary.com/ht4mr7djk/image/upload/v1723989550/App%20Assets/Book%20with%20confidence/Cover%20image/Desktop.webp"
          height={270}
          width={'100%'}
          options={{ width: 1366, height: 270 }}
        />
      </Responsive.DesktopOnly>
      <Responsive.MobileOnly className={styles.themeImage}>
        <CdnImage
          alt="Ski mountains"
          src="https://res.cloudinary.com/ht4mr7djk/image/upload/v1723989550/App%20Assets/Book%20with%20confidence/Cover%20image/Mobile.webp"
          height={136}
          width={'100%'}
        />
      </Responsive.MobileOnly>
      <div className={styles.body}>
        <div className={styles.mainSection}>
          <LegacyBreadcrumbs currentPathname={pathname!} />
          <InfoSection
            headTitleType="h1"
            title={mainSection.title}
            content={mainSection.content()}
            isMainInfo={mainSection.isMainInfo}
          />
          <Responsive.DesktopOnly>
            <Separator />
          </Responsive.DesktopOnly>
          <Responsive.MobileOnly>
            <div className={styles.secondarySection}>
              <InspirationCard ctaOnClick={ctaOnClick} />
              <Separator />
            </div>
          </Responsive.MobileOnly>
          <WaysWeHaveYouCoveredSection fixedDepositValue={fixedDepositValue} />
          <Separator />
          {sections.map(({ title, content, isMainInfo }, index) => {
            return (
              <div key={'InfoSection' + index}>
                <InfoSection
                  title={title}
                  content={content({ daysToPay: daysToPay.toString(), fixedDepositValue })}
                  isMainInfo={isMainInfo}
                />
                {index < sections.length - 1 && <Separator />}
              </div>
            );
          })}
        </div>
        <Responsive.DesktopOnly>
          <div className={styles.secondarySection}>
            <InspirationCard ctaOnClick={ctaOnClick} />
          </div>
        </Responsive.DesktopOnly>
      </div>
      <div className={styles.bottomBody}>
        <Separator />
        <div key={'InfoSection_last_item'}>
          <InfoSection
            title={lastItem!.title}
            content={lastItem!.content({ daysToPay: daysToPay.toString() })}
            isMainInfo={lastItem!.isMainInfo}
          />
        </div>
        <TrustPilotSection className={styles.trustPilotSection} hideTitle />
      </div>
    </div>
  );
};

export default BookWithConfidence;
