'use client';
import React from 'react';
import ReactSwitch from 'react-switch';

interface Props {
  value: boolean;
  handleToggle: () => void;
  className?: string;
}

const Toggle = ({ value, handleToggle, className }: Props) => {
  return (
    <ReactSwitch
      className={className}
      onChange={handleToggle}
      checked={value}
      checkedIcon={false}
      uncheckedIcon={false}
      height={20}
      width={36}
      handleDiameter={18}
      offColor="#525D7A"
      onColor="#1F5CF1"
    />
  );
};

export default Toggle;
