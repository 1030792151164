import cx from 'classnames';
import styles from './menu-divider.module.scss';
import { HorizontalRule } from '../../horizontal-rule/horizontal-rule';

interface Props {
  className?: string;
}

export const MenuDivider = ({ className }: Props) => {
  return <HorizontalRule className={cx(styles.menuDivider, className)} />;
};
