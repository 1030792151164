'use client';

import styles from './confirmation-modal.module.scss';
import { FC, useState } from 'react';
import { Modal, ModalProps } from '../modal/modal';
import Typography from '../typography/typography';
import Button from '../button/button';

export interface ModalButton {
  text: string;
  onClick?: () => void;
  variant?: 'main' | 'outlined' | 'text' | 'ellipse';
}

interface ConfirmationModalProps extends ModalProps {
  headline?: string;
  description?: string;
  confirmationButton?: ModalButton;
  cancelButton?: ModalButton;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  headline,
  description,
  confirmationButton = { text: 'yes', onClick: () => {}, variant: 'main' },
  onClose,
  cancelButton = { text: 'no', onClick: onClose, variant: 'text' },
  ...restProps
}) => {
  const [confirmationLoading, setConfirmationLoading] = useState<boolean>(false);

  const handleClick = async (onClick: () => Promise<void> | void) => {
    setConfirmationLoading(true);
    try {
      await onClick();
    } finally {
      setConfirmationLoading(false);
    }
  };

  return (
    <Modal open={open} {...{ ...restProps, onClose }} className={styles.container} showCloseButton={false}>
      <Typography variant="H400" className={styles.headline}>
        {headline}
      </Typography>
      {description?.split('\n').map((text, index) => (
        <Typography key={`description-${index}`} variant="P300" className={styles.description}>
          {text}
        </Typography>
      ))}
      <div className={styles.optionsContainer}>
        <Button
          className={styles.optionButton}
          variant={confirmationButton.variant ?? 'main'}
          text={confirmationButton.text}
          fullWidth
          loading={confirmationLoading}
          onClick={async () => {
            await handleClick(confirmationButton.onClick ?? (() => {}));
          }}
        />
        <Button
          className={styles.optionButton}
          variant={cancelButton.variant ?? 'outlined'}
          text={cancelButton.text}
          fullWidth
          onClick={async () => {
            await handleClick(cancelButton.onClick ?? onClose ?? (() => {}));
          }}
        />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
