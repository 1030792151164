'use client';

import { FC } from 'react';
import styles from './social-proof-banner.module.scss';
import { DesktopOnly, Icon, Typography } from '../../../core';
import classNames from 'classnames';

const TitleTypo = () => (
  <Typography className={styles.titleText} variant="H200">
    featured in:
  </Typography>
);

const SocialProofBanner: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.body}>
        <TitleTypo />
        <div className={styles.iconContainer}>
          <Icon isMultiColor={true} icon="SkiftLogo" width={60}></Icon>
          <Icon isMultiColor={true} icon="SkiAwardsLogo" width={126}></Icon>
          <DesktopOnly className={styles.desktopOnlyIcons}>
            <Icon isMultiColor={true} icon="PhocuswrightLogo" width={127}></Icon>
            <Icon isMultiColor={true} icon="ForbsLogo" width={78} />
          </DesktopOnly>
          <Icon isMultiColor={true} icon="BritishLogo" width={60} />
        </div>
      </div>
    </div>
  );
};

export default SocialProofBanner;
