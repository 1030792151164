import { SearchFormFields } from '../index';
import { ActiveComponentFilters, ComponentType } from './types';

export function prefilledFiltersFromFormData(formData: SearchFormFields): ActiveComponentFilters[] {
  const filtersPerComponent: Record<ComponentType, ActiveComponentFilters['active']> = {
    accommodation: [],
    flight: [],
    transfer: [],
    skiPass: [],
    tripPackage: [],
  };

  if (formData.destinations.type === 'byPreference') {
    if (formData.destinations.resortPreferences.importantProperties.skiInOut) {
      filtersPerComponent.accommodation.push({
        name: 'by_stay_facilities',
        value: ['Ski-in / Ski-out'],
      });
    }
  }

  if ('villageName' in formData.destinations.bySpecificPicks[0]) {
    filtersPerComponent.accommodation.push({
      name: 'by_villages',
      value: formData.destinations.bySpecificPicks[0].villageName!,
    });
  }

  const filters: ActiveComponentFilters[] = [];
  for (const [componentType, active] of Object.entries(filtersPerComponent)) {
    if (active.length > 0) {
      filters.push({
        componentType: componentType as ComponentType,
        active,
      });
    }
  }

  return filters;
}
