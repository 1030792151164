import * as React from 'react';
import styles from './stars.module.scss';
import Icon from '../icon/icon';
import cx from 'classnames';

interface Props {
  number: number;
  space?: number;
  hideForLessThan?: number;
  className?: string;
}

function renderStars(number: number, space: number) {
  const stars = [];

  for (let i = 0; i < number; i++) {
    stars.push(
      <div key={i} style={{ marginLeft: space * 8 }}>
        <Icon className={styles.icon} icon="StarFilled" size={2} width={16} />
      </div>
    );
  }

  return stars;
}

export default ({ number, space = 0, hideForLessThan = 3, className }: Props) => {
  if (!number || number < hideForLessThan) {
    return null;
  }
  return <div className={cx(styles.stars, className)}>{renderStars(number, space)}</div>;
};
