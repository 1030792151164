'use client';

import React, { ReactNode } from 'react';
import { CdnImage, Props as CdnImageProps } from '../cdn-image/cdn-image';
import styles from './image-with-overlay.module.scss';
import classNames from 'classnames';

export interface ImageOverlayData {
  desktopOverlay: ReactNode;
  mobileOverlay?: ReactNode;
  desktopImageSrc: string;
  mobileImageSrc?: string;
  imageAlt?: string;
  link: string;
}

interface ImageWithOverlayProps extends CdnImageProps {
  children?: ReactNode;
  overlayClassName?: string;
  containerClassName?: string;
  imageClassName?: string;
  imageAlt?: string;
  onClick?: () => void;
  link?: string;
  hoverImageShadow?: boolean;
}

const ImageWithOverlay: React.FC<ImageWithOverlayProps> = ({
  src,
  options,
  children,
  overlayClassName,
  containerClassName,
  imageClassName,
  imageAlt,
  onClick,
  link,
  hoverImageShadow,
  ...props
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.imageContainer,
        containerClassName,
        hoverImageShadow ? styles.hoverImageShadow : null
      )}
    >
      <CdnImage src={src} options={options} {...props} className={classNames(imageClassName, containerClassName)} />
      {link ? (
        <a href={link} className={classNames(styles.overlay, overlayClassName)}>
          {children}
        </a>
      ) : (
        <div className={classNames(styles.overlay, overlayClassName)}>{children}</div>
      )}
    </div>
  );
};

export default ImageWithOverlay;
