'use client';

import styles from './generic.module.scss';
import { Button, Icon, Typography } from '../../../core';

const Generic = ({ openWhatsappChat }: { openWhatsappChat: () => void }) => {
  return (
    <>
      <Typography variant={'P300'} mobileVariant={'B400'}>
        Looking to book a ski trip? <strong>Chat with our ski experts.</strong>
      </Typography>
      <Button
        variant="outlined"
        borderColor="transparent"
        textColor="#46c856"
        onClick={openWhatsappChat}
        className={styles.button}
      >
        <Icon icon="Whatsapp" isMultiColor />
        <span>Chat now</span>
      </Button>
    </>
  );
};

export default Generic;
