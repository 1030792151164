import React, { useCallback, useRef, useState } from 'react';
import DestinationFormSectionValue from './destination-form-section-value/destination-form-section-value';
import DestinationPopoverContent from './destination-popover/destination-popover';
import { DestinationSearch, DestinationType } from './types';
import { CountryIcon, FormSection, Icon } from '../../../../core';
import { Destination } from '@weski-monorepo/types';

interface Props {
  className?: string;
  value: DestinationSearch;
  onChange: (value: DestinationSearch) => void;
  destinations: Destination[];
  showIcon?: boolean;
  isAdmin: boolean;
  showLabel?: boolean;
  scrollToTopOnClose?: boolean;
  canSearchByResortPreference: boolean;
}

const TITLE = 'Going to';

const DestinationsFormSection = ({
  className,
  value,
  onChange,
  destinations,
  isAdmin,
  scrollToTopOnClose,
  showIcon = true,
  showLabel = true,
  canSearchByResortPreference,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [autoCompleteTextInput, setAutoCompleteTextInput] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const onOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open);
      if (!open) {
        setAutoCompleteTextInput('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOpen, setAutoCompleteTextInput]
  );

  let icon: React.JSX.Element = <Icon icon="SkiMountain" />;
  const isFlexible = 'flexible' in value.bySpecificPicks[0];
  if (isFlexible && value.type === DestinationType.Specific) {
    icon = <Icon icon="Globe" />;
  }
  if ('countryCode' in value.bySpecificPicks[0] && value.type === DestinationType.Specific) {
    icon = <CountryIcon countryCode={value.bySpecificPicks[0].countryCode} />;
  }

  const focusOnInput = () => inputRef.current?.focus();

  const handleOnChange = (newValue: DestinationSearch) => {
    if (value.type === DestinationType.ByPreference && newValue.type === DestinationType.Specific) {
      setTimeout(focusOnInput, 0);
    }

    onChange(newValue);
  };

  return (
    <FormSection
      open={open}
      onOpenChange={onOpenChange}
      {...(showLabel ? { label: TITLE } : {})}
      description={TITLE}
      scrollToTopOnClose={scrollToTopOnClose}
      dataTestName="destination-picker"
      value={
        <DestinationFormSectionValue
          autoCompleteTextInput={autoCompleteTextInput}
          onAutoCompleteTextInputChange={setAutoCompleteTextInput}
          value={value}
          destinations={destinations}
          inputRef={inputRef}
        />
      }
      className={className}
      {...(showIcon ? { icon } : {})}
      autoPopoverWidth
      onTriggerClick={focusOnInput}
    >
      <DestinationPopoverContent
        canSearchByResortPreference={canSearchByResortPreference}
        autoCompleteTextInput={autoCompleteTextInput}
        onAutoCompleteTextInputChange={setAutoCompleteTextInput}
        onDone={() => {
          onOpenChange(false);
        }}
        onSpecificResortChosen={() => {
          onOpenChange(false);
        }}
        destination={value}
        onChange={handleOnChange}
        destinations={destinations}
        isAdmin={isAdmin}
      />
    </FormSection>
  );
};

export default DestinationsFormSection;
