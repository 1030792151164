export enum DestinationType {
  Resort = 'resort',
  Village = 'village',
  SkiArea = 'skiArea',
}

export interface SeasonDates {
  start: string;
  end: string;
}

export interface SeasonsDates {
  current: SeasonDates;
  next: SeasonDates;
}

export interface Destination {
  id: string;
  name: string;
  type: DestinationType;
  alias?: string;
  countryName: string;
  countryCode: string;
  thumbnail: string;
  adminOnly: boolean;
  seasonsDates: SeasonsDates;
  subtitle: string;
  resortId?: string;
  popularityRating?: number;
  slug?: string;
}

export interface Resort extends Destination {
  enabled: boolean;
  resortPageEnabled: boolean;
  slug: string;
  images: {
    mobile: {
      thumbnail: string;
      cover: string;
      hero: string;
    };
    desktop: {
      thumbnail: string;
      cover: string;
      hero: string;
    };
  };
}
