import React, { useEffect, useRef } from 'react';
import styles from './select-option.module.scss';
import cx from 'classnames';
import { SelectItemProps } from '../select-content/select-content';

export interface Option {
  name: string;
  aliases?: string[];
  subtitle?: string;
  adminOnly?: boolean;
  thumbnail: React.JSX.Element;
  dataTestId?: string;
}

type Props = SelectItemProps & {
  sticky: boolean;
  option: Option;
  canSearchByResortPreference: boolean;
};

export const SelectOption = ({
  option,
  indentation,
  onSelect,
  isSelected,
  adminOnly,
  disabled,
  canSelect,
  sticky,
  canSearchByResortPreference,
}: Props) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoView(true);
    }
  }, [ref, isSelected]);

  return (
    <li
      className={cx(styles.container, {
        [styles.canSelect]: canSelect,
        [styles.adminOnly]: adminOnly,
        [styles.isSelected]: isSelected,
        [styles.sticky]: sticky,
        [styles.canSearchByResortPreference]: canSearchByResortPreference,
      })}
      style={{ ...(indentation ? { marginLeft: `${indentation * 20}px` } : {}) }}
      onClick={onSelect}
      ref={ref}
      data-testid={option.dataTestId}
    >
      <div className={styles.thumbnailContainer}>{option.thumbnail}</div>
      <div className={styles.label}>
        <div>{option.name}</div>
        {option.subtitle && <div className={styles.subtitle}>{option.subtitle}</div>}
      </div>
    </li>
  );
};
