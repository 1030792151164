import { ServiceHighlightComponentData } from '../../../core';

export const WaysCoveredData: (fixedDepositValue?: string) => ServiceHighlightComponentData[] = (fixedDepositValue) => {
  return [
    {
      title: 'Complete financial protection',
      description: 'Our ATOL and ABTOT membership gives your holiday the highest level of financial protection',
      iconName: 'SkiWithConfidenceIcon',
    },
    {
      title: 'Perfectly connected trips',
      description:
        'Our platform connects all the elements of your ski trip seamlessly and at the lowest possible price',
      iconName: 'OneStopIcon',
    },
    {
      title: 'Flexible Cancellation',
      description: 'We understand that plans change, so we offer transparent cancellation terms that work for you',
      iconName: 'YourTripYourWayIcon',
    },
    {
      title: 'Support when you need it',
      description: "Access to our expert team throughout, and 24/7 assistance while you're on the mountain",
      iconName: 'SmileChat',
    },
    {
      title: 'More ways to pay',
      description: `Shred the powder not your wallet with ${
        fixedDepositValue ? `${fixedDepositValue}pp` : 'low'
      } deposits, group split payments, interest-free options, and more`,
      iconName: 'SpreadTheCostIcon',
    },
    {
      title: 'Ski satisfaction',
      description: "We've sent over 70,000 customers to the slopes, and are proud to be rated Excellent on Trustpilot",
      iconName: 'ThumbUp',
    },
  ];
};
