'use client';

import React, { useCallback, useMemo } from 'react';
import { autoCompletePredicate } from './auto-complete-predicate';
import EmptyState from './empty-state/empty-state';
import { SelectContent } from './select/select-content/select-content';
import { SelectOption } from './select/select-option/select-option';
import { Option as OptionBase } from './types';

interface SearchFormSelectSingleValueProps<Option extends OptionBase> {
  autoCompleteTextInput?: string;
  onChange: (value: Option) => void;
  value: Option;
  options: Option[];
  emptyStateText?: string;
}

const SearchFormSelectSingleValue = <Option extends OptionBase>({
  autoCompleteTextInput,
  onChange,
  value,
  options,
  emptyStateText,
}: SearchFormSelectSingleValueProps<Option>) => {
  const setSelectedOption = useCallback(
    (option: Option) => {
      onChange(option);
    },
    [onChange]
  );

  const filteredOptions = useMemo(() => {
    return autoCompleteTextInput ? options.filter((r) => autoCompletePredicate(autoCompleteTextInput, r)) : options;
  }, [options, autoCompleteTextInput]);

  if (filteredOptions.length === 0 && emptyStateText) {
    return <EmptyState>{emptyStateText}</EmptyState>;
  }

  return (
    <SelectContent
      value={value}
      items={filteredOptions.map((option, index) => ({
        indentation: 0,
        value: option,
        canSelect: true,
        adminOnly: option.adminOnly,
        component: (props) => <SelectOption {...props} option={option} sticky={false} />,
      }))}
      onChange={(v: Option) => {
        setSelectedOption(v);
      }}
    />
  );
};

export default SearchFormSelectSingleValue;
