'use client';

import styles from './horizontal-rule.module.scss';
import classNames from 'classnames';

interface HorizontalRuleProps {
  className?: string;
}

export const HorizontalRule = ({ className }: HorizontalRuleProps) => {
  return <div className={classNames(styles.container, className)}></div>;
};
