'use client';

import classNames from 'classnames';
import { FC } from 'react';
import styles from './holidays-by-accommodation-type.module.scss';
import { ImageWithOverlay, SectionHeadline } from '../../../core';
import { skiHolidayAccommodationTypes } from './accommodation-types';
import { ImageOverlayData } from '../../../core/image-with-overlay/image-with-overlay';

const HolidaysByAccommodationTypeSection: FC<{ className?: string; data?: ImageOverlayData[] }> = ({
  className,
  data,
}) => {
  return (
    <div className={classNames(styles.holidaysByAccommodationContainer, className)}>
      <SectionHeadline title="Popular ski holiday by accommodation type" />
      <div className={styles.body}>
        {(data ?? skiHolidayAccommodationTypes).map((accType, index) => (
          <div key={'holiday-by-acc-type' + index}>
            <ImageWithOverlay
              alt={accType.imageAlt}
              containerClassName={styles.imageOverlayContainer}
              overlayClassName={styles.overlay}
              src={accType.desktopImageSrc}
              link={accType.link}
              hoverImageShadow={true}
            >
              {accType.desktopOverlay}
            </ImageWithOverlay>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HolidaysByAccommodationTypeSection;
