'use client';

import React from 'react';
import { cloudinaryUrlOptimize } from './cloudinary';

export interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  options?: Parameters<typeof cloudinaryUrlOptimize>[1];
  lazy?: boolean;
}
export const CdnImage = ({ src, options = {}, ...props }: Props) => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img loading={props.lazy ? 'lazy' : undefined} src={cloudinaryUrlOptimize(src, options)} {...props} />;
};
