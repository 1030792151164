import { useEffect } from 'react';

export function useDisableBackgroundScrolling(disableScrolling: boolean) {
  useEffect(() => {
    const originalOverflow = document.body.style.overflowY;

    if (disableScrolling) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = originalOverflow;
    }

    // Cleanup function to reset the overflow style when the component is unmounted or disableScrolling changes
    return () => {
      document.body.style.overflowY = originalOverflow;
    };
  }, [disableScrolling]);
}
