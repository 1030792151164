'use client';

import { FC } from 'react';
import { Responsive, SectionHeadline } from '../../core';
import TrustBox from './trust-box';
import TrustPilotGraphic from './trust-pilot-graphic/trust-pilot-graphic';
import styles from './trust-pilot-section.module.scss';
import ReviewCard from './card/card';
import { reviews } from './reviews';

const TrustPilotSection: FC<{
  mobileType?: 'constantCards' | 'widgetDefault';
  className?: string;
  hideTitle?: boolean;
  isTitleSubHeadline?: boolean;
}> = ({ mobileType = 'widgetDefault', className, hideTitle, isTitleSubHeadline = false }) => {
  return (
    <div className={className}>
      {!hideTitle && (
        <SectionHeadline title="Years of experience in making customers happy" isSubHeadline={isTitleSubHeadline} />
      )}
      <Responsive.MobileOnly>
        {mobileType === 'constantCards' && (
          <>
            <TrustPilotGraphic breakSection={true} />
            <div className={styles.reviewsGrid}>
              {Array.from({ length: reviews.default.length }).map((_, idx) => (
                <ReviewCard {...reviews.default[idx]} key={idx} />
              ))}
            </div>
          </>
        )}
        {mobileType === 'widgetDefault' && <TrustBox widgetType="miniCarousel" />}
      </Responsive.MobileOnly>
      <Responsive.DesktopOnly>
        <TrustBox widgetType="reviewsDesktop" />
      </Responsive.DesktopOnly>
    </div>
  );
};

export default TrustPilotSection;
