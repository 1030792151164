import { FC } from 'react';
import styles from './popular-ski-resort-item.module.scss';
import { cloudinaryResize } from '../../../../../utils/cloudinary';
import { Typography } from '../../../../core';

interface PopularSkiResortItemProps {
  href: string;
  imageSrc: string;
  title: string;
  subTitle: string;
}

export const PopularSkiResortItem: FC<PopularSkiResortItemProps> = ({ href, title, imageSrc, subTitle }) => {
  return (
    <a href={href} className={styles.container}>
      <img className={styles.thumbnail} src={cloudinaryResize(imageSrc, {})} alt={`${title} ski resort`} />
      <div className={styles.content}>
        <Typography variant="H200" mobileVariant="H100">
          {title}
        </Typography>
        <Typography variant="P200" mobileVariant="P100" className={styles.subtitle}>
          {subTitle}
        </Typography>
      </div>
    </a>
  );
};
