import { FormSectionTextValue, Responsive } from '../../../../../core';
import styles from './origin-airport-form-section-value.module.scss';
import React, { Ref } from 'react';

interface Props {
  valueLabel?: string;
  inputValue: string;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef: Ref<HTMLInputElement>;
}

export const OriginAirportFormSectionValue = ({ valueLabel, inputValue, inputRef, onInputChange }: Props) => {
  return (
    <>
      <Responsive.DesktopOnly className={styles.desktopContainer}>
        <div className={styles.inputContainer}>
          <input
            onChange={onInputChange}
            className={styles.input}
            placeholder={valueLabel}
            value={inputValue}
            id="origin-airport-value"
            ref={inputRef}
          />
        </div>
      </Responsive.DesktopOnly>
      <Responsive.MobileOnly>
        <FormSectionTextValue id="origin-airport-value" value={valueLabel} />
      </Responsive.MobileOnly>
    </>
  );
};
