import FormSection from '../../../../core/form-section/form-section';
import React, { useMemo } from 'react';
import styles from './components-picker-form-section.module.scss';
import SearchFormSelectSingleValue from '../../../../core/search-form-select/search-form-select-single-value/search-form-select-single-value';
import { Option } from '../../../../core/search-form-select/search-form-select-single-value/types';
import { ComponentsSearchField } from '@weski-monorepo/types';
import { componentsFields } from './component-field/component-field';
import { Typography } from '../../../../core';
import { getDataTestId, TestPage } from '../../../../../utils/data-test-id';

const PlusComponent = (key: string) => (
  <Typography className={styles.plus} key={key} variant="B400" mobileVariant="P100">
    +
  </Typography>
);

const TITLE = 'Searching for';

const componentsOptions: ComponentsSearchField[] = [
  {
    accommodation: true,
    flight: true,
    transfer: true,
    skiPass: true,
  },
  {
    accommodation: true,
    flight: true,
    transfer: true,
    skiPass: false,
  },
  {
    accommodation: true,
    flight: true,
    transfer: false,
    skiPass: true,
  },
  {
    accommodation: true,
    flight: false,
    transfer: false,
    skiPass: true,
  },
];

interface ComponentsPickerFormSectionProps {
  className?: string;
  value: ComponentsSearchField;
  onChange: (value: ComponentsSearchField) => void;
  isAdmin: boolean;
  scrollToTopOnClose?: boolean;
}

interface ComponentsSelectOption extends Option {
  value: ComponentsSearchField;
}

const getComponentsSelectOptions = (isAdmin: boolean): ComponentsSelectOption[] => {
  const allOptions: ComponentsSelectOption[] = componentsOptions.map((option, index) => {
    const options: JSX.Element[] = [];
    (Object.keys(option) as (keyof ComponentsSearchField)[]).forEach((key) => {
      if (option[key]) {
        options.push(componentsFields[key], PlusComponent(key));
      }
    });

    if (options.length > 0) {
      options.pop();
    }

    const optionTestName = Object.keys(option)
      .filter((component) => option[component as keyof ComponentsSearchField])
      .join('-');

    return {
      element: (
        <div key={index} className={styles.option}>
          {options}
        </div>
      ),
      value: option,
      dataTestId: getDataTestId({
        page: TestPage.SearchForm,
        component: `components-picker-option-${optionTestName}`,
      }),
    };
  });

  if (isAdmin) {
    allOptions.push({
      element: (
        <div key={'stay-only'} className={styles.option}>
          {componentsFields.accommodation}
        </div>
      ),
      value: { accommodation: true, flight: false, skiPass: false, transfer: false },
      adminOnly: true,
      dataTestId: getDataTestId({
        page: TestPage.SearchForm,
        component: `components-picker-option-stay`,
      }),
    });
  }

  return allOptions;
};

export const ComponentsPickerFormSection = ({
  className,
  value,
  onChange,
  isAdmin,
  scrollToTopOnClose,
}: ComponentsPickerFormSectionProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const handleOnSelect = (selectedOption: ComponentsSelectOption) => {
    onChange(selectedOption.value);
    onOpenChange(false);
  };

  const componentsSelectOptions = useMemo(() => {
    return getComponentsSelectOptions(isAdmin);
  }, [isAdmin]);

  const valueOption = useMemo(() => {
    return componentsSelectOptions.find((option) => JSON.stringify(option.value) === JSON.stringify(value));
  }, [componentsSelectOptions, value]);

  return (
    <FormSection
      className={className}
      dataTestName="components-picker"
      value={valueOption?.element || componentsSelectOptions[0].element}
      open={isOpen}
      onOpenChange={onOpenChange}
      disableMobileFullScreen={true}
      label={TITLE}
      scrollToTopOnClose={scrollToTopOnClose}
      forceLabel
      showArrow
      autoTriggerHeight
    >
      <SearchFormSelectSingleValue onChange={handleOnSelect} value={valueOption!} options={componentsSelectOptions} />
    </FormSection>
  );
};
