'use client';

import React, { useEffect, useMemo } from 'react';
import { Controller, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';
import styles from './search-form.module.scss';
import { DateRangePadding, DatesSearch, SearchFormExtendedInitData, SearchFormFields } from './types';
import { useDateRangeOptions } from './hooks/useDateRangeOptions';
import classNames from 'classnames';
import { getMergedDefaultValues } from './default-values';
import {
  AnalyticsEvents,
  HandleAnalyticsCallback,
  IAnalyticPayload,
  IAnalyticsKey,
  SearchRequest,
} from '@weski-monorepo/types';
import { DestinationSearch, DestinationType, PickType, ResortPick } from './form-fields/destination-form-section/types';
import { DatePickerFormSection, DateType } from './form-fields/date-picker-form-section/date-picker-form-section';
import { OriginAirportSelectFormSection } from './form-fields/origin-airport-form-section/origin-airport-select-form-section';
import DestinationFormSection from './form-fields/destination-form-section/destination-form-section';
import { GroupSizeFormSection } from './form-fields/group-size-form-section/group-size-form-section';
import { Button, Icon, MobileOnly, ProgressBar, Typography } from '../../core';
import { formDataToSearchRequest } from './formaters/form-data-to-search-request';
import { prefilledFiltersFromFormData } from './filters/filters-from-form-data';
import { searchDataToAnalytics } from './formaters/form-data-to-analytics';
import { AnalyticsContext } from './hooks/useAnalytics';
import { ComponentsPickerFormSection } from './form-fields/components-picker-form-section/components-picker-form-section';
import { useIsMobile } from '../../../hooks/use-is-mobile/use-is-mobile';
import { TestPage, addDataTestAttribute } from '../../../utils/data-test-id';

export enum SearchFormLayout {
  V1 = 'v1',
  V2 = 'v2',
}

export interface SearchFormOptions {
  showIcons?: boolean;
  showComponentsField?: boolean;
  showLabel?: boolean;
  isAdmin?: boolean;
  scrollToTopOnClose?: boolean;
  isFirstTimer?: boolean;
  canSearchByResortPreference?: boolean;
}

interface SearchFormProps {
  data: SearchFormExtendedInitData;
  onSubmit: (data: SearchRequest, filters: any, rawSearchFormFields: SearchFormFields) => void;
  destinationId?: string;
  className?: string;
  layout: SearchFormLayout;
  onAnalytics?: HandleAnalyticsCallback;
  options?: SearchFormOptions;
  defaultValues?: Partial<SearchFormFields>;
  onFocus?: () => void;
  onBlur?: () => void;
  ctaVariant?: 'outlined' | 'main';
}

const defaultOptions = {
  showIcons: false,
  showLabel: true,
  showComponentsField: false,
  isAdmin: false,
  scrollToTopOnClose: false,
  canSearchByResortPreference: false,
};

enum WizardStep {
  Start,
  Group,
  Airport,
  Dates,
}

const SearchButtonContent = ({ layout }: { layout: SearchFormLayout }) => {
  return (
    <div className={styles.submitBtnContent}>
      <Icon icon="MagnifyingGlass" />
      <Typography variant="P300">{layout === SearchFormLayout.V2 ? 'Find Your Trip' : 'Search'}</Typography>
    </div>
  );
};

const SearchForm = ({
  data,
  onSubmit,
  destinationId,
  className,
  layout,
  onAnalytics,
  options,
  onFocus,
  onBlur,
  ctaVariant = 'main',
}: SearchFormProps) => {
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<SearchFormFields>();
  const isMobile = useIsMobile();
  const optionsWithDefaults = useMemo(() => ({ ...defaultOptions, ...options }), [options]);

  const { showIcons, showLabel, showComponentsField, isAdmin, scrollToTopOnClose, isFirstTimer } = optionsWithDefaults;

  const [wizardStepIndex, setWizardStepIndex] = React.useState(0);

  const formWatch = useWatch({ control });

  useEffect(() => {
    if (data) {
      const newMergedDefaultValues = getMergedDefaultValues(
        formWatch.disableFlights || false,
        data,
        destinationId,
        formWatch as SearchFormFields
      );
      reset(newMergedDefaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, destinationId]);

  const destinationsWatch = useWatch({ control, name: 'destinations' });
  const dateRangeWatch = useWatch({ control, name: 'dates' });
  const groupSizeWatch = useWatch({ control, name: 'rooms' });
  const componentsWatch = useWatch({ control, name: 'components' });

  const disableFlights = formWatch.disableFlights;

  const dateRangeOptions = useDateRangeOptions(destinationsWatch, data);

  const wizardSteps = useMemo(() => {
    if (componentsWatch.flight) {
      return [WizardStep.Start, WizardStep.Group, WizardStep.Airport, WizardStep.Dates];
    }

    return [WizardStep.Start, WizardStep.Group, WizardStep.Dates];
  }, [componentsWatch]);

  const currentWizardStep = wizardSteps[wizardStepIndex];

  const resetDatesToDefault = () => {
    setValue(
      'dates',
      {
        ...dateRangeWatch,
        dateRangeSearch: {
          range: { from: dateRangeOptions?.valueFrom, to: dateRangeOptions?.valueTo },
          padding: DateRangePadding.Exact,
        },
      },
      { shouldValidate: true }
    );
  };

  useEffect(() => {
    if (
      dateRangeOptions &&
      (!dateRangeWatch?.dateRangeSearch.range?.from ||
        !dateRangeWatch?.dateRangeSearch.range.to ||
        // Todo: current/next season modification
        dateRangeWatch.dateRangeSearch.range.from! < new Date(dateRangeOptions.seasonsDates.next.start) ||
        dateRangeWatch.dateRangeSearch.range.to! > new Date(dateRangeOptions.seasonsDates.next.end))
    ) {
      resetDatesToDefault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeOptions]);

  const handleAnalyticsCall = <E extends IAnalyticsKey>(eventName: E, data?: IAnalyticPayload<E>) => {
    if (onAnalytics) {
      onAnalytics(eventName, data);
    }
  };

  const handleOriginAirportChange = (field: ControllerRenderProps<SearchFormFields, 'departingFrom.id'>) => {
    return (value: string) => {
      handleAnalyticsCall(AnalyticsEvents.SEARCH_ELEMENTS_SELECT, {
        step: 'departure_airport',
        value,
        previous_value: formWatch.departingFrom?.id,
        ...(data?.nearestAirport ? { nearestAirport: data?.nearestAirport?.id } : {}),
      });
      field.onChange(value);
    };
  };

  const handleDestinationChange = (field: ControllerRenderProps<SearchFormFields, 'destinations'>) => {
    return (value: DestinationSearch) => {
      if (value.type === DestinationType.Specific && value.pickType === PickType.Resort) {
        handleAnalyticsCall(AnalyticsEvents.SEARCH_ELEMENTS_SELECT, {
          step: 'resort',
          value: (value.bySpecificPicks[0] as ResortPick)?.resortName,
        });
      }
      field.onChange(value);
    };
  };

  const handleGroupSizeClosed = (isBack?: boolean) => {
    handleWizardStepDone(isBack);
    handleAnalyticsCall(AnalyticsEvents.SEARCH_ELEMENTS_SELECT, {
      step: 'group_size',
      value: groupSizeWatch.adults + groupSizeWatch.children,
    });
  };

  const handleDatePickerClose = (isBack?: boolean) => {
    if (dateRangeWatch.type === DateType.Specific && !dateRangeWatch.dateRangeSearch.range?.from) {
      resetDatesToDefault();
    }

    handleWizardStepDone(isBack);
  };

  const handleDatesChange = (field: ControllerRenderProps<SearchFormFields, 'dates'>) => {
    return (value: DatesSearch) => {
      if (value.type === DateType.Specific) {
        value.dateRangeSearch.range?.to
          ? handleAnalyticsCall(AnalyticsEvents.SEARCH_ELEMENTS_SELECT, {
              step: 'to_date',
              value: value.dateRangeSearch.range.to,
            })
          : handleAnalyticsCall(AnalyticsEvents.SEARCH_ELEMENTS_SELECT, {
              step: 'from_date',
              value: value.dateRangeSearch.range?.from,
            });
      }

      field.onChange(value);
    };
  };

  const handleFormSubmit = async (data: SearchFormFields) => {
    handleAnalyticsCall(AnalyticsEvents.SEARCH_SUBMIT, searchDataToAnalytics(data));
    onSubmit(formDataToSearchRequest(data), prefilledFiltersFromFormData(data), data);
  };

  const shouldShowOriginAirportPicker =
    !disableFlights && componentsWatch.flight && (!data || data?.originAirports.options.length > 1);

  const shouldShowComponentsPicker = showComponentsField && !disableFlights;

  const handleSubmitBtnClick = () => {
    if (!isFirstTimer || !isMobile) {
      void handleSubmit(handleFormSubmit)();
      return;
    }

    handleWizardStepDone();
  };

  useEffect(() => {
    const handlePopState = (event: { state: { step: number } }) => {
      if (event.state?.step) {
        setWizardStepIndex(event.state.step);
      }
    };

    if (isFirstTimer) {
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isFirstTimer]);

  const handleWizardStepDone = (isBack?: boolean) => {
    if (!isFirstTimer || !isMobile) {
      return;
    }

    const isLastStep = wizardStepIndex === wizardSteps.length - 1;

    if (isLastStep && !isBack) {
      void handleSubmit(handleFormSubmit)();
      return;
    }

    const newStepIndex = isBack ? wizardStepIndex - 1 : wizardStepIndex + 1;

    window.history.pushState({ step: newStepIndex }, '');
    setWizardStepIndex(newStepIndex);
  };

  const isWizardModeStartStep = isFirstTimer && currentWizardStep === WizardStep.Start;
  const isWizardModeInProgress = isFirstTimer && currentWizardStep !== WizardStep.Start;

  return (
    <AnalyticsContext.Provider value={handleAnalyticsCall}>
      <MobileOnly>
        {isWizardModeInProgress && <ProgressBar max={wizardSteps.length - 1} value={wizardStepIndex} />}
      </MobileOnly>
      <form
        className={classNames(styles.formContainer, { [styles.v2]: layout === SearchFormLayout.V2 }, className)}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {shouldShowOriginAirportPicker && (
          <Controller
            name="departingFrom.id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <OriginAirportSelectFormSection
                airports={data?.originAirports.options || []}
                value={field.value}
                onChange={handleOriginAirportChange(field)}
                showIcon={showIcons}
                showLabel={showLabel}
                className={classNames(styles.originAirportFormSection, {
                  [styles.v2]: layout === SearchFormLayout.V2,
                  [styles.wizardModeMobileHide]: isWizardModeStartStep,
                })}
                scrollToTopOnClose={scrollToTopOnClose}
                forceOpen={currentWizardStep === WizardStep.Airport}
                onClose={handleWizardStepDone}
              />
            )}
          />
        )}
        <Controller
          name="destinations"
          control={control}
          render={({ field }) => (
            <DestinationFormSection
              value={field.value}
              onChange={handleDestinationChange(field)}
              destinations={data?.destinations || []}
              showIcon={showIcons}
              showLabel={showLabel}
              className={classNames(styles.destinationFormSection, {
                [styles.v2]: layout === SearchFormLayout.V2,
              })}
              isAdmin={isAdmin}
              scrollToTopOnClose={scrollToTopOnClose}
              canSearchByResortPreference={optionsWithDefaults.canSearchByResortPreference}
            />
          )}
        />
        <Controller
          name="dates"
          control={control}
          rules={{
            validate: (value) =>
              Boolean(
                value?.type === DateType.Flexible ||
                  (value?.dateRangeSearch.range?.from && value?.dateRangeSearch.range.to)
              ),
          }}
          render={({ field }) => (
            <DatePickerFormSection
              value={field.value}
              onChange={handleDatesChange(field)}
              minNights={data?.minTripDuration || 3}
              maxNights={data?.maxTripDuration || 21}
              seasonDates={dateRangeOptions?.seasonsDates}
              minDaysAheadToBook={data?.minDaysAheadToBook}
              showIcon={showIcons}
              showLabel={showLabel}
              className={classNames(styles.datePickerFormSection, {
                [styles.v2]: layout === SearchFormLayout.V2,
                [styles.wizardModeMobileHide]: isWizardModeStartStep,
              })}
              scrollToTopOnClose={scrollToTopOnClose}
              showSpecificDateRangePaddingOptions={
                destinationsWatch.type === DestinationType.Specific &&
                [PickType.Resort, PickType.Village].includes(destinationsWatch.pickType)
              }
              forceOpen={currentWizardStep === WizardStep.Dates}
              onClose={handleDatePickerClose}
            />
          )}
        />
        <Controller
          name="rooms"
          control={control}
          rules={{ validate: (value) => Boolean(!value.childrenAges.includes(null)) }}
          render={({ field }) => (
            <GroupSizeFormSection
              value={field.value}
              onChange={field.onChange}
              onClose={handleGroupSizeClosed}
              showIcon={showIcons}
              showLabel={showLabel}
              className={classNames(styles.groupSizeFormSection, {
                [styles.v2]: layout === SearchFormLayout.V2,
                [styles.wizardModeMobileHide]: isWizardModeStartStep,
              })}
              scrollToTopOnClose={scrollToTopOnClose}
              forceOpen={currentWizardStep === WizardStep.Group}
            />
          )}
        />
        {shouldShowComponentsPicker && (
          <Controller
            name="components"
            control={control}
            render={({ field }) => (
              <ComponentsPickerFormSection
                value={field.value}
                onChange={field.onChange}
                className={classNames(styles.componentsPickerFormSection, {
                  [styles.v2]: layout === SearchFormLayout.V2,
                })}
                isAdmin={isAdmin}
                scrollToTopOnClose={scrollToTopOnClose}
              />
            )}
          />
        )}
        <Button
          className={classNames(styles.submitBtn, { [styles.v2]: layout === SearchFormLayout.V2 })}
          id="submit-btn"
          disabled={!isValid}
          variant={ctaVariant}
          text={<SearchButtonContent layout={layout} />}
          onClick={handleSubmitBtnClick}
          {...addDataTestAttribute({ page: TestPage.SearchForm, component: 'search-btn' })}
        />
      </form>
    </AnalyticsContext.Provider>
  );
};

export default SearchForm;
