'use client';

import { PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './responsive.module.scss';

interface Props {
  className?: string;
  id?: string;
}

export const MobileOnly = ({ children, className, id }: PropsWithChildren<Props>) => {
  return (
    <div className={cx(styles.mobileOnly, className)} id={id}>
      {children}
    </div>
  );
};

export const DesktopOnly = ({ children, className, id }: PropsWithChildren<Props>) => {
  return (
    <div className={cx(styles.desktopOnly, className)} id={id}>
      {children}
    </div>
  );
};

export const Responsive = {
  MobileOnly,
  DesktopOnly,
};
