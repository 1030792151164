'use client';

import React, { useEffect } from 'react';
import styles from './date-picker-form-section.module.scss';
import { DateRange } from 'react-day-picker';
import { getLabel } from './date-picker-input-label';
import classNames from 'classnames';
import { DateRangePadding, DatesSearch, FlexibleDatesSearch } from '../../types';
import { useIsMobile } from '../../../../../hooks/use-is-mobile/use-is-mobile';
import FormSection from '../../../../core/form-section/form-section';
import Icon from '../../../../core/icon/icon';
import MobileModalHeader from '../../../../core/mobile-modal-header/mobile-modal-header';
import SegmentedGroup from '../../../../core/segmented-group/segmented-group';
import { DateRangePicker } from './date-range-picker/date-range-picker';
import FlexibleDates from './flexible-dates-picker/flexible-dates';
import Button from '../../../../core/button/button';
import { AnalyticsEvents, SeasonDates } from '@weski-monorepo/types';
import { useAnalytics } from '../../hooks/useAnalytics';
import { FormSectionTextValue } from '../../../../core';
import { getDataTestId, TestPage, addDataTestAttribute } from '../../../../../utils/data-test-id';

const POPOVER_TITLE = `When are you going?`;
const TITLE = 'Dates';
const COMPONENT_TEST_NAME = 'date-picker';

interface DateRangeFormSectionProps {
  onChange: (dateSearch: DatesSearch) => void;
  value: DatesSearch;
  minNights: number;
  maxNights: number;
  minDaysAheadToBook: number;
  showIcon?: boolean;
  seasonDates?: { current: SeasonDates; next: SeasonDates };
  className?: string;
  showSpecificDateRangePaddingOptions?: boolean;
  showLabel?: boolean;
  scrollToTopOnClose?: boolean;
  forceOpen?: boolean;
  onClose?: (isBack?: boolean) => void;
}

export enum DateType {
  Specific = 'specific',
  Flexible = 'flexible',
}

export const DatePickerFormSection = ({
  onChange,
  value,
  minNights,
  maxNights,
  seasonDates,
  minDaysAheadToBook,
  showIcon = true,
  showLabel = true,
  className,
  showSpecificDateRangePaddingOptions,
  scrollToTopOnClose,
  forceOpen,
  onClose,
}: DateRangeFormSectionProps) => {
  const analytics = useAnalytics();
  const [isOpen, setIsOpen] = React.useState(false);
  const isMobile = useIsMobile();

  const changeSpecificDatePadding = (padding: DateRangePadding) => {
    onChange({
      ...value,
      dateRangeSearch: { range: value.dateRangeSearch.range, padding },
    });
  };

  useEffect(() => {
    setIsOpen(Boolean(forceOpen));
  }, [forceOpen]);

  useEffect(() => {
    if (!showSpecificDateRangePaddingOptions) {
      changeSpecificDatePadding(DateRangePadding.Exact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSpecificDateRangePaddingOptions]);

  const onOpenChange = (isOpen: boolean, isBack?: boolean) => {
    onClose && onClose(isBack);

    if (!forceOpen) {
      setIsOpen(isOpen);
    }
  };

  const onDone = (isBack?: boolean) => {
    onOpenChange(false, isBack);
  };

  const handleDateTypeChange = (type: DateType) => {
    analytics(AnalyticsEvents.FLEX_MONTH_SEGMENTED_GROUP_CLICK, { type });
    onChange({ ...value, type });
  };

  const handleSpecificDateChange = (dateRange?: DateRange) => {
    onChange({
      ...value,
      dateRangeSearch: { range: dateRange, padding: value.dateRangeSearch.padding },
    });
  };

  const handleFlexibleDatesChange = (flexibleValue: FlexibleDatesSearch) => {
    onChange({ ...value, flexibleDatesSearch: flexibleValue });
  };

  const handleResetSpecificDates = () => {
    onChange({ ...value, dateRangeSearch: { range: undefined, padding: DateRangePadding.Exact } });
  };

  const handleSpecificDatePaddingChange = (padding: DateRangePadding) => () => {
    const plusMinusDays = padding === DateRangePadding.OneDay ? 1 : 0;
    const type = padding === DateRangePadding.OneDay ? 'plusMinusDays' : 'specific';
    analytics(AnalyticsEvents.FLEX_DAYS_CHIP_CLICK, { type, plusMinusDays });
    changeSpecificDatePadding(padding);
  };

  const isDoneBtnDisabled =
    value.type === DateType.Specific && value.dateRangeSearch.range?.from && !value.dateRangeSearch.range.to;

  return (
    <FormSection
      open={isOpen}
      dataTestName={COMPONENT_TEST_NAME}
      onOpenChange={onOpenChange}
      className={classNames(styles.formSection, className)}
      autoPopoverWidth
      {...(showLabel ? { label: TITLE } : {})}
      {...(showIcon ? { icon: <Icon icon="Calendar" /> } : {})}
      description={TITLE}
      tabIndex={0}
      scrollToTopOnClose={scrollToTopOnClose}
      value={<FormSectionTextValue id="date-picker-value" value={getLabel(value)} />}
    >
      {isMobile && (
        <MobileModalHeader
          onBackClick={() => {
            onDone(true);
          }}
        >
          {POPOVER_TITLE}
        </MobileModalHeader>
      )}
      <div className={styles.segmentedGroupContainer}>
        <SegmentedGroup
          segments={[
            {
              label: 'Specific dates',
              value: DateType.Specific,
              dataTestId: getDataTestId({
                page: TestPage.SearchForm,
                component: `${COMPONENT_TEST_NAME}-specific-dates-tab`,
              }),
            },
            {
              label: 'Flexible dates',
              value: DateType.Flexible,
              dataTestId: getDataTestId({
                page: TestPage.SearchForm,
                component: `${COMPONENT_TEST_NAME}-specific-dates-tab`,
              }),
            },
          ]}
          defaultValue={value.type}
          onChoose={handleDateTypeChange}
        />
      </div>
      <div>
        {value.type === DateType.Specific ? (
          <DateRangePicker
            selected={value.dateRangeSearch.range}
            onSelect={handleSpecificDateChange}
            minNights={minNights}
            maxNights={maxNights}
            // Todo: current/next season modification
            minDate={new Date(seasonDates?.next.start || '')}
            maxDate={new Date(seasonDates?.next.end || '')}
            defaultMonth={value.dateRangeSearch.range?.from}
          />
        ) : (
          <FlexibleDates
            onChange={handleFlexibleDatesChange}
            seasonDates={seasonDates!}
            minDaysAheadToBook={minDaysAheadToBook}
            value={value.flexibleDatesSearch!}
          />
        )}
        <div className={styles.footer}>
          {value.type === DateType.Specific && showSpecificDateRangePaddingOptions && (
            <div className={styles.paddingOptionsContainer}>
              <Button
                variant="ellipse"
                text="Exact dates"
                textVariant="B400"
                selected={value.dateRangeSearch.padding === DateRangePadding.Exact}
                onClick={handleSpecificDatePaddingChange(DateRangePadding.Exact)}
                {...addDataTestAttribute({
                  page: TestPage.SearchForm,
                  component: `${COMPONENT_TEST_NAME}-exact-dates-btn`,
                })}
              />
              <Button
                variant="ellipse"
                text="± 1 day"
                textVariant="B400"
                selected={value.dateRangeSearch.padding === DateRangePadding.OneDay}
                onClick={handleSpecificDatePaddingChange(DateRangePadding.OneDay)}
                {...addDataTestAttribute({
                  page: TestPage.SearchForm,
                  component: `${COMPONENT_TEST_NAME}-padding-dates-btn`,
                })}
              />
            </div>
          )}
          <div className={styles.footerActions}>
            {value.type === DateType.Specific && (
              <Button
                className={styles.resetBtn}
                text="Reset"
                textVariant="B400"
                variant="outlined"
                onClick={handleResetSpecificDates}
                {...addDataTestAttribute({ page: TestPage.SearchForm, component: `${COMPONENT_TEST_NAME}-reset-btn` })}
              />
            )}
            <Button
              className={styles.doneBtn}
              text="Done"
              textVariant="B400"
              variant="main"
              onClick={() => {
                onDone();
              }}
              disabled={isDoneBtnDisabled}
              {...addDataTestAttribute({ page: TestPage.SearchForm, component: `${COMPONENT_TEST_NAME}-done-btn` })}
            />
          </div>
        </div>
      </div>
    </FormSection>
  );
};
