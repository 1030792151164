import { DatesSearchField } from '@weski-monorepo/types';
import { formatDate } from '../../../../utils/dates';
import {
  DateRangePadding,
  DateRangeSearch,
  DatesSearch,
  DateType,
  FlexibleDatesSearch,
  FlexibleDurationType,
  FlexibleType,
} from '../index';

export const tripBuilderDatesFromFormData = (dates: DatesSearch): DatesSearchField => {
  return dates.type === DateType.Specific
    ? tripBuilderDatesRangeFromFormData(dates.dateRangeSearch)
    : tripBuilderFlexibleDatesFromFormData(dates.flexibleDatesSearch!);
};

const tripBuilderDatesRangeFromFormData = (search: DateRangeSearch): DatesSearchField => {
  if (search.padding === DateRangePadding.OneDay) {
    const fromWithPadding = getDateWithPadding(search.range!.from!);
    const toWithPadding = getDateWithPadding(search.range!.to!);

    return fromWithPadding.flatMap((fromDate) =>
      toWithPadding.map((toDate) => ({ from: formatDate(fromDate), to: formatDate(toDate) }))
    );
  }

  return [
    {
      from: formatDate(search.range!.from!),
      to: formatDate(search.range!.to!),
    },
  ];
};

const tripBuilderFlexibleDatesFromFormData = (flexibleDatesSearch: FlexibleDatesSearch): DatesSearchField => {
  return [
    {
      type: DateType.Flexible,
      duration: flexibleDatesSearch.duration === FlexibleDurationType.Week ? 7 : 4,
      periods:
        flexibleDatesSearch.type === FlexibleType.LastMinute
          ? FlexibleType.LastMinute
          : flexibleDatesSearch.type === FlexibleType.Season
          ? [{ season: flexibleDatesSearch.when.season! }]
          : flexibleDatesSearch.type === FlexibleType.Holiday
          ? [{ holiday: flexibleDatesSearch.when.holiday! }]
          : [flexibleDatesSearch.when as { year: number; month: number }],
    },
  ];
};

const getDateWithPadding = (date: Date): Date[] => {
  const dates = [];
  const oneDayInMs = 24 * 60 * 60 * 1000; // One day

  // This order is important, so the selected date range will be first
  dates.push(date);
  dates.push(new Date(date.getTime() - oneDayInMs));
  dates.push(new Date(date.getTime() + oneDayInMs));

  return dates;
};
