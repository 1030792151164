import { IInfoSection } from '../../core';

export const AboutUsContent: {
  heyThere: IInfoSection;
  endlessPossibilities: IInfoSection;
  createdBySkiersForSkiers: IInfoSection;
} = {
  heyThere: {
    title: 'Hey there 👋',
    content: () => (
      <>
        <p>
          We’re WeSki, your all-in-one ski holiday companion. Our mission is to make your ski trip experience
          effortless, so you can focus on the fun.
        </p>
        <p>
          Founded in 2015, we're a group of passionate snow lovers who wanted incredible ski adventures, but without the
          hassle or endless planning.
        </p>
        <p>
          So we created the world’s first self-service platform to build completely customisable trips in just a few
          clicks, delivered seamlessly by an expert team.
        </p>
      </>
    ),
    isMainInfo: false,
  },
  endlessPossibilities: {
    title: 'One platform, endless possibilities',
    content: () => (
      <>
        <p>
          Each time you search, our unique platform finds and compares thousands of ski trip combinations, giving you
          the best options in seconds.
        </p>
        <p>
          You choose the one that works best for you, assign lift passes, equipment and lessons to whoever needs them,
          and we'll handle the rest!
        </p>
      </>
    ),
  },
  createdBySkiersForSkiers: {
    title: 'Created by skiers, for skiers',
    content: () => (
      <>
        <p>
          From finding the perfect resort match, to assigning each member of your squad with whatever they need, it’s
          your trip, your way.
        </p>
        <p>
          Powered by what really matters, including 24/7 on-trip support, flexible cancellation and payment terms, and
          all the information you need in one easy, manageable place, we know what makes ski holidays epic and we’ve got
          you covered.
        </p>
      </>
    ),
  },
};
