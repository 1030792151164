import styles from './size-select-btn.module.scss';
import classNames from 'classnames';

interface SizeSelectButtonProps {
  disabled?: boolean;
  onClick: () => void;
  text: string;
}

export const SizeSelectButton = ({ disabled, onClick, text, ...props }: SizeSelectButtonProps) => {
  const handleOnClick = () => {
    !disabled && onClick();
  };

  return (
    <div
      className={classNames(styles.sizeSelectBtn, { [styles.disabled]: disabled })}
      onClick={handleOnClick}
      {...props}
    >
      {text}
    </div>
  );
};
