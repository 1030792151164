import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './origin-airport-popover.module.scss';
import { useAirportOptions } from './use-airport-options';
import { AirportSelectionOption } from './types';
import { useIsMobile } from '../../../../../../hooks/use-is-mobile/use-is-mobile';
import { useIsFirstRender } from '../../../../../../hooks/use-is-first-render/use-is-first-render';
import { SearchBar, MobileModalHeader, SearchFormSelect } from '../../../../../core';
import { OriginAirport } from '@weski-monorepo/types';

interface OriginAirportPopoverProps {
  autoCompleteTextInput: string;
  onDone: () => void;
  onAutoCompleteTextInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  airport: string;
  onChange: (value: string) => void;
  airports: OriginAirport[];
}

const OriginAirportPopoverContent = ({
  autoCompleteTextInput,
  onAutoCompleteTextInputChange,
  onDone,
  airport,
  onChange,
  airports,
}: OriginAirportPopoverProps) => {
  const isMobile = useIsMobile();
  const isFirstRender = useIsFirstRender();
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const options = useAirportOptions(airports);
  useEffect(() => {
    if (!isFirstRender) {
      ref.current?.scrollTo({ top: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCompleteTextInput]);

  const selectedOption = useMemo(() => {
    return options.find((option) => {
      return option.id === airport;
    })!;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airport]);

  const setSelectedOption = useCallback(
    (option: AirportSelectionOption) => {
      onChange(option.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  return (
    <div ref={ref} className={styles.container}>
      {isMobile && (
        <div className={styles.top}>
          <MobileModalHeader onBackClick={onDone}>Leaving from?</MobileModalHeader>
          <SearchBar
            value={autoCompleteTextInput}
            onChange={onAutoCompleteTextInputChange}
            placeholder="Choose your airport"
          />
        </div>
      )}
      <div className={styles.grow}>
        <SearchFormSelect
          autoCompleteTextInput={autoCompleteTextInput}
          value={selectedOption}
          onChange={setSelectedOption}
          options={options}
          emptyStateText="Try searching by airport or city"
        />
      </div>
    </div>
  );
};

export default OriginAirportPopoverContent;
