import Icon from '../icon/icon';

export function Arrow(props: { className?: string; onClick?: (e: React.MouseEvent) => void }) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      <Icon icon="ChevronRight" size={4} width={36} color="white" cursor="pointer" />
    </div>
  );
}
