export interface PopularSkiResortData {
  href: string;
  imageSrc: string;
  title: string;
  subTitle: string;
  imageAlt?: string;
}

export const PopularSkiResortsData: PopularSkiResortData[] = [
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1605002804/Resort%20Images/Thumbnails/3.jpg',
    title: 'Tignes',
    subTitle: 'France',
    href: '/ski-resorts/france/tignes',
    imageAlt: 'Snow-covered buildings and mountains at a ski resort during sunset',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1605002804/Resort%20Images/Thumbnails/1.jpg',
    title: 'Val Thorens',
    subTitle: 'France',
    href: '/ski-resorts/france/val-thorens',
    imageAlt: 'Ski resort buildings illuminated at night with a snowy mountain backdrop',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1605002804/Resort%20Images/Thumbnails/11.jpg',
    title: 'Avoriaz',
    subTitle: 'France',
    href: '/ski-resorts/france/avoriaz',
    imageAlt: 'Snow-covered buildings and mountains at a ski resort on a sunny day',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1605002804/Resort%20Images/Thumbnails/13.jpg',
    title: `Val d'Isère`,
    subTitle: 'France',
    href: '/ski-resorts/france/val-d-isere',
    imageAlt: 'Snowy ski resort village illuminated at dusk with mountains in the background',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1605002804/Resort%20Images/Thumbnails/26.jpg',
    title: 'Mayrhofen',
    subTitle: 'Austria',
    href: '/ski-resorts/austria/mayrhofen',
    imageAlt: 'Snow-covered village with mountain views on a clear day',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1605002804/Resort%20Images/Thumbnails/12.jpg',
    title: 'Méribel',
    subTitle: 'France',
    href: '/ski-resorts/france/meribel',
    imageAlt: 'Thumbnail image of a ski resort',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1605002804/Resort%20Images/Thumbnails/5.jpg',
    title: 'Chamonix',
    subTitle: 'France',
    href: '/ski-resorts/france/chamonix',
    imageAlt: 'Scenic gondola lift over a snowy valley with mountains in the background',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1605002804/Resort%20Images/Thumbnails/4.jpg',
    title: 'La Plagne',
    subTitle: 'France',
    href: '/ski-resorts/france/la-plagne',
    imageAlt: 'Snowy ski resort village illuminated under a full moon at night',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1629142556/Resort%20Images/Thumbnails/60.jpg',
    title: 'Bansko',
    subTitle: 'Bulgaria',
    href: '/ski-resorts/bulgaria/bansko',
    imageAlt: 'Modern ski resort buildings with snowy mountains in the background on a clear day',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1605002804/Resort%20Images/Thumbnails/10.jpg',
    title: 'Les Arcs',
    subTitle: 'France',
    href: '/ski-resorts/france/les-arcs',
    imageAlt: 'Ski resort buildings illuminated at sunset with a vibrant sky',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1658663463/Resort%20Images/Thumbnails/68.jpg',
    title: 'Bad Gastein',
    subTitle: 'Austria',
    href: '/ski-resorts/austria/bad-gastein',
    imageAlt: 'Modern building and footbridge on a snowy mountain slope with a clear blue sky',
  },
  {
    imageSrc: 'https://res.cloudinary.com/ht4mr7djk/image/upload/v1658664054/Resort%20Images/Thumbnails/82.jpg',
    title: 'Courmayeur',
    subTitle: 'Italy',
    href: '/ski-resorts/italy/courmayeur',
    imageAlt: 'Snow-covered village illuminated at night with mountains in the background',
  },
];
