export const countries: [code: string, nationality: string, country: string][] = [
  ['AF', 'Afghan', 'Afghanistan'],
  ['AL', 'Albanian', 'Albania'],
  ['AE', 'Emirati', 'United Arab Emirates'],
  ['DZ', 'Algerian', 'Algeria'],
  ['US', 'American', 'USA'],
  ['AD', 'Andorran', 'Andorra'],
  ['AO', 'Angolan', 'Angola'],
  ['AG', 'Antiguans', 'Antigua'],
  ['AR', 'Argentinean', 'Argentina'],
  ['AM', 'Armenian', 'Armenia'],
  ['AU', 'Australian', 'Australia'],
  ['AT', 'Austrian', 'Austria'],
  ['AZ', 'Azerbaijani', 'Azerbaijan'],
  ['BS', 'Bahamian', 'Bahamas'],
  ['BH', 'Bahraini', 'Bahrain'],
  ['BD', 'Bangladeshi', 'Bangladesh'],
  ['BB', 'Barbadian', 'Barbados'],
  ['BY', 'Belarusian', 'Belarus'],
  ['BE', 'Belgian', 'Belgium'],
  ['BZ', 'Belizean', 'Beliz'],
  ['BJ', 'Beninese', 'Benin'],
  ['BT', 'Bhutanese', 'Bhutan'],
  ['BO', 'Bolivian', 'Bolivia'],
  ['BA', 'Bosnian', 'Bosnia'],
  ['BW', 'Botswanan', 'Botswana'],
  ['BR', 'Brazilian', 'Brazil'],
  ['GB', 'British', 'UK'],
  ['BN', 'Bruneian', 'Brunei'],
  ['BG', 'Bulgarian', 'Bulgaria'],
  ['BF', 'Burkinabe', 'Burkina Faso'],
  ['BI', 'Burundian', 'Burundi'],
  ['KH', 'Cambodian', 'Cambodia'],
  ['CM', 'Cameroonian', 'Cameroon'],
  ['CA', 'Canadian', 'Canada'],
  ['CV', 'Cape Verdean', 'Cape Verde'],
  ['CF', 'Central African', 'Central Africa'],
  ['TD', 'Chadian', 'Chad'],
  ['CL', 'Chilean', 'Chile'],
  ['CN', 'Chinese', 'China'],
  ['CO', 'Colombian', 'Colombia'],
  ['KM', 'Comoran', 'Comoros'],
  ['CR', 'Costa Rican', 'Costa Rica'],
  ['HR', 'Croatian', 'Croatia'],
  ['CU', 'Cuban', 'Cuba'],
  ['CY', 'Cypriot', 'Cyprus'],
  ['CZ', 'Czech', 'Czech Republic'],
  ['DK', 'Danish', 'Denemark'],
  ['DJ', 'Djibouti', 'Djibouti'],
  ['DM', 'Dominican', 'Dominica'],
  ['NL', 'Dutch', 'Netherlands'],
  ['EC', 'Ecuadorean', 'Ecuador'],
  ['EG', 'Egyptian', 'Egypt'],
  ['EE', 'Estonian', 'Estonia'],
  ['ET', 'Ethiopian', 'Ethiopia'],
  ['FJ', 'Fijian', 'Fiji'],
  ['PH', 'Filipino', 'Philippines'],
  ['FI', 'Finnish', 'Finland'],
  ['FR', 'French', 'France'],
  ['GA', 'Gabonese', 'Gabon'],
  ['GM', 'Gambian', 'Gambia'],
  ['GE', 'Georgian', 'Georgia'],
  ['DE', 'German', 'Germany'],
  ['GH', 'Ghanaian', 'Ghana'],
  ['GR', 'Greek', 'Greece'],
  ['GD', 'Grenadian', 'Grenada'],
  ['GT', 'Guatemalan', 'Guatemala'],
  ['GN', 'Guinean', 'Guinea'],
  ['GY', 'Guyanese', 'Guyana'],
  ['HT', 'Haitian', 'Haiti'],
  ['HN', 'Honduran', 'Honduras'],
  ['HU', 'Hungarian', 'Hungaria'],
  ['HK', 'Hongkongese', 'Hong Kong'],
  ['IS', 'Icelander', 'Iceland'],
  ['IN', 'Indian', 'India'],
  ['ID', 'Indonesian', 'Indonesia'],
  ['IE', 'Irish', 'Ireland'],
  ['IL', 'Israeli', 'Israel'],
  ['IT', 'Italian', 'Italy'],
  ['JM', 'Jamaican', 'Jamaica'],
  ['JP', 'Japanese', 'Japan'],
  ['JO', 'Jordanian', 'Jordan'],
  ['KZ', 'Kazakhstani', 'Kazakhstan'],
  ['KE', 'Kenyan', 'Kenya'],
  ['KR', 'Korean', 'South Korea'],
  ['KW', 'Kuwaiti', 'Kuwait'],
  ['KG', 'Kyrgyz', 'Kyrgyzstan'],
  ['LA', 'Laotian', 'Laos'],
  ['LV', 'Latvian', 'Latvia'],
  ['LR', 'Liberian', 'Liberia'],
  ['LY', 'Libyan', 'Libya'],
  ['LI', 'Liechtensteiner', 'Liechtenstein'],
  ['LT', 'Lithuanian', 'Lithuania'],
  ['LU', 'Luxembourger', 'Luxembourg'],
  ['MK', 'Macedonian', 'Macedonia'],
  ['MG', 'Malagasy', 'Madagascar'],
  ['MW', 'Malawian', 'Malawi'],
  ['MY', 'Malaysian', 'Malaysia'],
  ['MV', 'Maldivan', 'Maldives'],
  ['ML', 'Malian', 'Mali'],
  ['MT', 'Maltese', 'Malta'],
  ['MH', 'Marshallese', 'Marshall Islands'],
  ['MR', 'Mauritanian', 'Mauritania'],
  ['MU', 'Mauritian', 'Mauritius'],
  ['MX', 'Mexican', 'Mexico'],
  ['FM', 'Micronesian', 'Micronesia'],
  ['MD', 'Moldovan', 'Moldova'],
  ['MC', 'Monacan', 'Monaco'],
  ['MN', 'Mongolian', 'Mongolia'],
  ['MA', 'Moroccan', 'Morocco'],
  ['MZ', 'Mozambican', 'Mozambic'],
  ['NA', 'Namibian', 'Namibia'],
  ['NP', 'Nepalese', 'Nepal'],
  ['NZ', 'New Zealander', 'New Zealand'],
  ['VU', 'Ni-Vanuatu', 'Vanuatu'],
  ['NI', 'Nicaraguan', 'Nicaragua'],
  ['NG', 'Nigerien', 'Nigeria'],
  ['NO', 'Norwegian', 'Norway'],
  ['OM', 'Omani', 'Oman'],
  ['PK', 'Pakistani', 'Pakistan'],
  ['PW', 'Palauan', 'Palau'],
  ['PA', 'Panamanian', 'Panama'],
  ['PG', 'Papua New Guinean', 'Papua New Guinea'],
  ['PY', 'Paraguayan', 'Paraguay'],
  ['PE', 'Peruvian', 'Peru'],
  ['PL', 'Polish', 'Poland'],
  ['PT', 'Portuguese', 'Portugal'],
  ['QA', 'Qatari', 'Qatar'],
  ['RO', 'Romanian', 'Romania'],
  ['RU', 'Russian', 'Russia'],
  ['RW', 'Rwandan', 'Rwanda'],
  ['SV', 'Salvadoran', 'El Salvador'],
  ['WS', 'Samoan', 'Samoa'],
  ['SM', 'San Marinese', 'San Marino'],
  ['ST', 'Sao Tomean', 'Sao Tome and Principe'],
  ['SA', 'Saudi', 'Saudi Arabia'],
  ['SN', 'Senegalese', 'Senegal'],
  ['RS', 'Serbian', 'Serbia'],
  ['SC', 'Seychellois', 'Seychelles'],
  ['SL', 'Sierra Leonean', 'Sierra Leon'],
  ['SG', 'Singaporean', 'Singapor'],
  ['SK', 'Slovakian', 'Slovakia'],
  ['SI', 'Slovenian', 'Slovenia'],
  ['SB', 'Solomon Islander', 'Solomon Islands'],
  ['SO', 'Somali', 'Somalia'],
  ['ZA', 'South African', 'South Africa'],
  ['ES', 'Spanish', 'Spain'],
  ['LK', 'Sri Lankan', 'Sri Lanka'],
  ['SD', 'Sudanese', 'Sudan'],
  ['SR', 'Surinamer', 'Surinam'],
  ['SZ', 'Swazi', 'Eswatini'],
  ['SE', 'Swedish', 'Sweden'],
  ['CH', 'Swiss', 'Switzerland'],
  ['TW', 'Taiwanese', 'Taiwan'],
  ['TJ', 'Tajik', 'Tajikistan'],
  ['TZ', 'Tanzanian', 'Tanzania'],
  ['TH', 'Thai', 'Thailand'],
  ['TG', 'Togolese', 'Togo'],
  ['TO', 'Tongan', 'Tonga'],
  ['TT', 'Trinidadian or Tobagonian', 'Trinidad and Tobago'],
  ['TN', 'Tunisian', 'Tunisia'],
  ['TR', 'Turkish', 'Turkey'],
  ['TM', 'Turkmenistani', 'Turkmenistan'],
  ['TV', 'Tuvaluan', 'Tuvalu'],
  ['UG', 'Ugandan', 'Uganda'],
  ['UA', 'Ukrainian', 'Ukraine'],
  ['UY', 'Uruguayan', 'Uruguay'],
  ['UZ', 'Uzbekistani', 'Uzbekistan'],
  ['VE', 'Venezuelan', 'Venezuela'],
  ['VN', 'Vietnamese', 'Vietnam'],
  ['ZM', 'Zambian', 'Zambia'],
  ['ZW', 'Zimbabwean', 'Zimbabwe'],
];

export const getCountryNameById = (id: string): string => {
  return countries.find((country) => country[0] === id)![2];
};

export const getCountryCodeByName = (name: string): string => {
  return countries.find((country) => country[2].toLowerCase() === name.toLowerCase())![0];
};

export const getCountryCodeFromName = (name: string): string => {
  return countries.find(([, , country]) => country.toLowerCase() === name)![0];
};
