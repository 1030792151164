'use client';

import React, { PropsWithChildren } from 'react';
import styles from './admin-chip.module.scss';
import cx from 'classnames';
import Chip from '../chip/chip';

interface Props {
  className?: string;
}

const AdminChip = ({ children, className }: PropsWithChildren<Props>) => {
  return <Chip className={cx(styles.adminChip, className)}>{children}</Chip>;
};

export default AdminChip;
