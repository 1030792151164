'use client';

import { FC } from 'react';
import styles from './rounded-label.module.scss';

interface RoundedLabelProps {
  text: string;
  onClick?: () => void;
}

export const RoundedLabel: FC<RoundedLabelProps> = ({ text, onClick }) => {
  return (
    <div
      className={styles.roundedLinkContainer}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {text}
    </div>
  );
};
