import { FC } from 'react';
import { Button, Card } from '../../../core';
import styles from './inspiration-card.module.scss';

interface InspirationCardProps {
  ctaOnClick: () => void;
}

const InspirationCard: FC<InspirationCardProps> = ({ ctaOnClick }) => {
  return (
    <Card className={styles.inspirationCard}>
      <div className={styles.contentContainer}>
        <h3 className={styles.title}>Ready to find your perfect match?</h3>
        <p className={styles.description}>
          Start building your dream ski trip today with our one-stop ski shop - hundreds of resorts, unlimited
          combinations.
        </p>
      </div>
      <Button onClick={ctaOnClick} fullWidth={true} text="Let's go!" variant="main"></Button>
    </Card>
  );
};

export default InspirationCard;
