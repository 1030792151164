import React from 'react';
import styles from './select-content.module.scss';

export interface SelectItemProps {
  isSelected: boolean;
  indentation?: number;
  adminOnly?: boolean;
  disabled?: boolean;
  canSelect?: boolean;
  onSelect: () => void;
}

interface SelectItem<T> {
  disabled?: boolean;
  indentation?: number;
  canSelect?: boolean;
  adminOnly?: boolean;
  value: T;
  component: (props: SelectItemProps) => React.JSX.Element;
}

interface Props<T> {
  value: T;
  className?: string;
  onChange: (value: T) => void;
  items: SelectItem<T>[];
}

export function SelectContent<T>(props: Props<T>) {
  return (
    <ul className={`${styles.container} ${props.className}`}>
      {props.items.map((item, index) => (
        <React.Fragment key={index}>
          {item.component({
            ...item,
            onSelect: () => {
              props.onChange(item.value);
            },
            isSelected: props.value === item.value,
          })}
        </React.Fragment>
      ))}
    </ul>
  );
}
