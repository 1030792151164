export const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const dayDiff = (date1: Date, date2: Date): number => {
  const oneDayInMs = 1000 * 60 * 60 * 24; // milliseconds in one day
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();

  const diffMs = Math.abs(date1Ms - date2Ms);

  return Math.floor(diffMs / oneDayInMs);
};
