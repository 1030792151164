export interface ActiveExperiments {
  allocation: ExperimentAllocation[];
}

export interface ExperimentAllocation {
  name: ExperimentName;
  variant: string;
}

export interface ExperimentSettings<T extends ExperimentName> {
  name: ExperimentName;
  type?: 'ab';
  startDate: Date;
  endDate: Date;
  audience?: string;
  override: {
    group: boolean;
    login: boolean;
  };
  variants: {
    [K in ExperimentVariants[T]]: number;
  };
}

export type ExperimentName = 'booking-hotel-only' | 'markup' | 'test';

interface ExperimentVariants {
  'booking-hotel-only': 'enable' | 'disable';
  markup: 'enable' | 'disable';
  test: 'red' | 'blue' | 'green';
}

export type Variant<T extends keyof ExperimentVariants> = ExperimentVariants[T];

export const EXPERIMENTS_COOKIE_KEY = 'experiments';
export const EXPERIMENTS_COOKIE_TTL = 31536000; // number of seconds the experiments value lives (1 years)
export const EXPERIMENTS_LOADED_COOKIE_KEY = 'experiments_loaded';
export const EXPERIMENTS_LOADED_COOKIE_TTL = 3600; // number of seconds experiments should reload again
