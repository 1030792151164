import {
  ActiveComponentFilters,
  DateType,
  DestinationType,
  FlexibleDurationType,
  FlexibleType,
  OriginType,
  PickType,
  SearchFormFields,
} from '../index';

import { resortPreferencesConstants, countries } from '@weski-monorepo/utils';

import { ByResortPreferencesSearchRequest, SearchRequest } from '@weski-monorepo/types';
import { uniq } from 'lodash';

/**
 * This temp function needs to exist as long as we use the old search form in weski client.
 * We need to save the recent search in a way that it can be modified by the new client and seen by the old client,
 * and vice versa.
 *
 * Since the old client does not now about SearchFormFields, we are storing SearchRequest in the cookie,
 * So
 *  the new client will parse the SearchRequest cookie to SearchFormFields
 *  the old client will parse the SearchRequest cookie to its own data structure
 *
 * After the old client will use the same searchForm as the newClient,
 * we will be able to store SearchFormFields in the cookie as is.
 */
export function searchRequestToSearchFormValues(
  searchRequest: SearchRequest,
  filters: ActiveComponentFilters[]
): SearchFormFields {
  const villageName = filters.flatMap((f) => f.active).find((f) => f.name === 'by_villages')?.value;
  const isSearchWithDatesPadding = (searchRequest?.dates || []).length > 1;
  const datesPadding = isSearchWithDatesPadding ? getFlexibleDaysFromDatesArray(searchRequest.dates as any) : 0;
  const mapDates = (dates: SearchRequest['dates'][0]): SearchFormFields['dates'] => {
    if ('type' in dates && dates.type === 'flexible') {
      // Flexible date mapping
      const { periods, duration } = dates;
      const flexibleDatesSearch: SearchFormFields['dates']['flexibleDatesSearch'] = {
        duration: (duration > 4 ? 'week' : 'weekend') as FlexibleDurationType,
        type: Array.isArray(periods) ? determineFlexibleDateType(periods) : ('lastMinute' as FlexibleType),
        when: {
          month: Array.isArray(periods) && 'month' in periods[0] ? periods[0].month : undefined,
          year: Array.isArray(periods) && 'year' in periods[0] ? periods[0].year : undefined,
          season: Array.isArray(periods) && 'season' in periods[0] ? periods[0].season : undefined,
          holiday: Array.isArray(periods) && 'holiday' in periods[0] ? periods[0].holiday : undefined,
        },
      };

      return {
        type: 'flexible' as DateType,
        flexibleDatesSearch,
        dateRangeSearch: {
          padding: datesPadding,
        },
      };
    } else {
      // Specific date mapping
      return {
        type: 'specific' as DateType,
        flexibleDatesSearch: {
          duration: 'week' as FlexibleDurationType,
          type: 'month' as FlexibleType,
          // Todo: current/next season modification
          when: { month: 1, year: 2025 },
        },
        dateRangeSearch: {
          padding: datesPadding,
          range: {
            from: new Date((dates as any).from),
            to: new Date((dates as any).to),
          },
        },
      };
    }
  };

  const mapDestinations = (destinations: SearchRequest['destinations']): SearchFormFields['destinations'] => {
    if (Array.isArray(destinations)) {
      // Handle specific destinations
      const bySpecificPicks = destinations.map((destination) => {
        if (typeof destination === 'object' && 'type' in destination) {
          if (destination.type === 'country') {
            return { countryCode: destination.id as string };
          }
          if (destination.type === 'skiArea') {
            return { skiArea: `skiArea-${destination.id as number}` };
          }
          if (destination.type === 'flexible') {
            return { flexible: true };
          }
        }

        if (villageName) {
          return { resort: `resort-${destination as number}`, villageName };
        }
        return { resort: `resort-${destination as number}` };
      }) as SearchFormFields['destinations']['bySpecificPicks'];

      let pickType = (villageName ? 'village' : 'resort') as PickType;
      if (typeof destinations[0] === 'object' && 'type' in destinations[0]) {
        if (['country', 'skiArea', 'flexible'].includes(destinations[0].type)) {
          pickType = destinations[0].type as PickType;
        }
      }
      return {
        type: 'specific' as DestinationType,
        bySpecificPicks,
        pickType,
        resortPreferences: {
          country: getRecordOfBooleansFromTwoArrays(
            resortPreferencesConstants.destinationsResortPreferencesCountryOptions,
            ['flexible']
          ),
          aboutYou: getRecordOfBooleansFromTwoArrays(
            resortPreferencesConstants.destinationsResortPreferencesAboutYouOptions,
            []
          ),
          importantProperties: getRecordOfBooleansFromTwoArrays(
            resortPreferencesConstants.destinationsResortPreferencesImportantPropertiesOptions,
            []
          ),
        },
      };
    }

    return {
      type: 'byPreference' as DestinationType,
      bySpecificPicks: [
        {
          resort: 'resort-3',
        },
      ],
      pickType: 'resort' as PickType,
      resortPreferences: mapResortPreferences(destinations),
    };
  };

  return {
    dates: mapDates(searchRequest.dates[0]),
    destinations: mapDestinations(searchRequest.destinations),
    components: searchRequest.components,
    departingFrom: searchRequest.departingFrom?.[0] as { type: OriginType; id: string },
    disableFlights: false,
    rooms: searchRequest.rooms[0],
  };
}

function mapResortPreferences(
  preferences: ByResortPreferencesSearchRequest
): SearchFormFields['destinations']['resortPreferences'] {
  const countryNames = (preferences.countryCodes || []).map((code) =>
    countries.getCountryNameById(code.toUpperCase()).toLowerCase()
  );

  if (countryNames.length === 0) {
    countryNames.push('flexible');
  }

  return {
    country: getRecordOfBooleansFromTwoArrays(
      resortPreferencesConstants.destinationsResortPreferencesCountryOptions,
      countryNames
    ),
    aboutYou: getRecordOfBooleansFromTwoArrays(
      resortPreferencesConstants.destinationsResortPreferencesAboutYouOptions,
      preferences.aboutYou || []
    ),
    importantProperties: getRecordOfBooleansFromTwoArrays(
      resortPreferencesConstants.destinationsResortPreferencesImportantPropertiesOptions,
      preferences.mostImportant || []
    ),
  };
}

const determineFlexibleDateType = (periods: any[]): FlexibleType => {
  if ('month' in periods[0]) return 'month' as FlexibleType;
  if ('season' in periods[0]) return 'season' as FlexibleType;
  if ('holiday' in periods[0]) return 'holiday' as FlexibleType;
  return 'lastMinute' as FlexibleType;
};

function getRecordOfBooleansFromTwoArrays<T extends string>(allValues: readonly T[], truthyValues: string[]) {
  return Object.fromEntries(allValues.map((option) => [option, truthyValues.includes(option)])) as Record<T, boolean>;
}

function getFlexibleDaysFromDatesArray(
  dates: {
    from: string; // 2023-12-09 (YYYY-MM-DD)
    to: string; // 2023-12-15
  }[]
) {
  const allFromDatesAreUnique = uniq(dates.map((d) => d.from)).length === dates.length;
  const fixedTripDuration = allFromDatesAreUnique;
  if (fixedTripDuration) {
    return (dates.length - 1) / 2;
  } else {
    return (Math.sqrt(dates.length) - 1) / 2;
  }
}
