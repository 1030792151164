'use client';

import { PropsWithChildren } from 'react';
import styles from './top-banner.module.scss';

interface TopBannerProps {
  shouldShow: boolean;
  className?: string;
}

const TopBanner = ({ shouldShow, className, children }: PropsWithChildren<TopBannerProps>) => {
  if (!shouldShow) {
    return null;
  }
  return <div className={`${styles.topBanner} ${className}`}>{children}</div>;
};

export default TopBanner;
