'use client';

import { FC } from 'react';
import { RoundedLabel, Typography } from '../../../core';
import styles from './popular-resorts-for-search.module.scss';
import classNames from 'classnames';

const PopularResortsForSearch: FC<{ className?: string }> = ({ className }) => {
  const resortsLinks: { name: string; link: string }[] = [
    { name: 'Val Thorens', link: '/' },
    { name: 'Chamonix', link: '/' },
    { name: 'La Plagne', link: '/' },
  ];
  return (
    <div className={classNames(styles.popularResortsForSearchContainer, className)}>
      <Typography variant="H100">Popular:</Typography>
      {resortsLinks.map(({ name, link }, index) => (
        <RoundedLabel
          key={name + index}
          text={name}
          onClick={() => {
            window.location.href = link;
          }}
        />
      ))}
    </div>
  );
};

export default PopularResortsForSearch;
