'use client';

import React from 'react';
import styles from './mobile-modal-header.module.scss';
import Typography from '../typography/typography';
import Icon from '../icon/icon';
import classNames from 'classnames';

interface MobileModalHeaderProps {
  children?: React.ReactNode;
  onBackClick?: () => void;
  isBackDisabled?: boolean;
}

const MobileModalHeader = ({ children, onBackClick, isBackDisabled }: MobileModalHeaderProps) => {
  return (
    <div className={styles.container}>
      <Icon
        icon="ArrowLeft"
        size={4}
        {...(isBackDisabled ? {} : { onClick: onBackClick })}
        className={classNames({ [styles.disabledBack]: isBackDisabled })}
      />
      <Typography variant="H100" className={styles.center}>
        {children}
      </Typography>
      <div></div>
    </div>
  );
};

export default MobileModalHeader;
