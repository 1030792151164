import { TOrigin } from '../weski-api';

export enum AnalyticsEvents {
  DESTINATION_PICKER_COUNTRY_SELECT = 'destination_picker.country_option_select',
  DESTINATION_PICKER_SEGMENTED_GROUP_CLICK = 'destination_picker.segmented_group_click',
  FLEX_MONTH_SEGMENTED_GROUP_CLICK = 'flexMonthSegmentedGroupClick',
  SEARCH_ELEMENTS_SELECT = 'search/element_select',
  SEARCH_SUBMIT = 'search/submit_search',
  FLEX_DAYS_CHIP_CLICK = 'flexDaysChipClick',
  ORIGIN_CHANGE = 'Change Origin',
  OPEN_MENU = 'Open A Menu',
  CLICK_SKI_MEGA_MENU_LINK = 'Click Ski Mega Menu Link',
  VISIT_PAGE = 'Loaded a Page',
  SIGN_UP_CLICK = 'signUpClick',
  RENDER_ERROR = 'RENDER_ERROR',
  CHOOSE_FILTER = 'chooseFilter',
  CHOOSE_COMPONENT = 'chooseComponent',
  SHOW_COMPONENT_OPTIONS = 'showComponentOptions',
  SHARE_BUTTON_CLICKED = 'shareButtonClicked',
  SORT = 'sort',
  WHATSAPP_CTA_LOADED = 'whatsappCtaLoaded',
  WHATSAPP_BUTTON_CLICK = 'whatsappButtonClick',
}

export interface IAnalytics {
  [AnalyticsEvents.ORIGIN_CHANGE]: {
    value: TOrigin | undefined;
    previousValue: TOrigin | undefined;
  };
  [AnalyticsEvents.VISIT_PAGE]: any;
  [AnalyticsEvents.DESTINATION_PICKER_COUNTRY_SELECT]: any;
  [AnalyticsEvents.DESTINATION_PICKER_SEGMENTED_GROUP_CLICK]: any;
  [AnalyticsEvents.FLEX_MONTH_SEGMENTED_GROUP_CLICK]: any;
  [AnalyticsEvents.SEARCH_ELEMENTS_SELECT]: any;
  [AnalyticsEvents.SEARCH_SUBMIT]: any;
  [AnalyticsEvents.FLEX_DAYS_CHIP_CLICK]: any;
  [AnalyticsEvents.OPEN_MENU]: { type: string; location: string };
  [AnalyticsEvents.CLICK_SKI_MEGA_MENU_LINK]: { link: string };
  [AnalyticsEvents.SIGN_UP_CLICK]: {
    type: 'email' | 'google' | 'facebook';
    mode: 'login' | 'signup';
  };
  [AnalyticsEvents.RENDER_ERROR]: {
    error: string;
  };
  [AnalyticsEvents.CHOOSE_FILTER]: {
    component: string;
    category: string;
    filter: string;
    option: any;
  };
  [AnalyticsEvents.CHOOSE_COMPONENT]: {
    component: string;
    index?: number;
    withFilters?: boolean;
    searchType?: string;
  };
  [AnalyticsEvents.SHOW_COMPONENT_OPTIONS]: {
    component: string;
  };
  [AnalyticsEvents.SHARE_BUTTON_CLICKED]: {
    sharedUrl: string;
  };
  [AnalyticsEvents.SORT]: {
    component: string;
    option: string;
  };
  [AnalyticsEvents.WHATSAPP_CTA_LOADED]: any;
  [AnalyticsEvents.WHATSAPP_BUTTON_CLICK]: { source: 'header_strip' | 'page_section'; url: string };
}

export type IAnalyticPayload<E extends IAnalyticsKey> = IAnalytics[E];

export type IAnalyticsKey = keyof IAnalytics;

export type HandleAnalyticsCallback = <E extends IAnalyticsKey>(eventName: E, data?: IAnalyticPayload<E>) => void;
