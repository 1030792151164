'use client';

import React from 'react';

import classNames from 'classnames';
import Typography from '../typography/typography';
import styles from './selectable-list.module.scss';

export interface SelectableListOption {
  value: string;
  label: string;
  category?: string;
}

interface SelectableListProps {
  options: SelectableListOption[];
  onSelect: (value: string) => void;
  className?: string;
}

export const SelectableList = ({ options, onSelect, className }: SelectableListProps) => {
  return (
    <div className={classNames(styles.optionsContainer, className)}>
      {options.length > 0 ? (
        options.map((option, index) => (
          <div
            className={styles.option}
            key={index}
            onClick={() => {
              onSelect(option.value);
            }}
          >
            <Typography variant="P200">{option.label}</Typography>
          </div>
        ))
      ) : (
        <Typography className={classNames(styles.option, styles.disabledOption)} variant="P200">
          No options
        </Typography>
      )}
    </div>
  );
};
