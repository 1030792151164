'use client';

import styles from './vertical-rule.module.scss';
import classNames from 'classnames';

interface VerticalRuleProps {
  className?: string;
}

export const VerticalRule = ({ className }: VerticalRuleProps) => {
  return <div className={classNames(styles.container, className)}></div>;
};
