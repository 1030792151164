import styles from './ski-destination-card.module.scss';
import { Icon, Typography } from '../../../../core';
import classNames from 'classnames';
import { FC } from 'react';
import { SkiDestinationCardData } from '../explore-ski-destinations-data';

interface SkiDestinationCardProp extends SkiDestinationCardData {}

export const SkiDestinationCard: FC<SkiDestinationCardProp> = ({
  href,
  iconName,
  title,
  className,
  numberOfResorts,
}: SkiDestinationCardProp) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Icon icon={iconName} isMultiColor width={48} cursor="pointer" />
      <div className={styles.contentContainer}>
        <a className={styles.link} href={href}>
          <h3>
            <Typography variant="H200">{title}</Typography>
          </h3>
        </a>
        <p>
          <Typography variant="P200">{`${numberOfResorts} resorts`}</Typography>
        </p>
      </div>
      <Icon icon="ChevronRight" width={36} cursor="pointer" />
    </div>
  );
};
