import { ImageOverlayData } from '../../../core/image-with-overlay/image-with-overlay';

export const skiHolidayCategories: ImageOverlayData[] = [
  {
    desktopOverlay: <h3>BEGINNERS</h3>,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722946186/App%20Assets/Homepage/New%20Home%20page%202024/Categoreis/Beginners.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1724849755/App%20Assets/Homepage/New%20Home%20page%202024/Categoreis/Mobile/Beginners.webp',
    imageAlt: 'Beginner skier skiing down a snowy slope with mountains in the background',
    link: '/ski-holidays/beginner-ski-holidays',
  },
  {
    desktopOverlay: <h3>FAMILIES</h3>,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722946187/App%20Assets/Homepage/New%20Home%20page%202024/Categoreis/Families.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1724849755/App%20Assets/Homepage/New%20Home%20page%202024/Categoreis/Mobile/Families.webp',
    imageAlt: 'Child learning to ski on a snowy slope with a lift in the background',
    link: '/ski-holidays/family-ski-holidays',
  },
  {
    desktopOverlay: <h3>GROUPS</h3>,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722946190/App%20Assets/Homepage/New%20Home%20page%202024/Categoreis/Groups.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1724849755/App%20Assets/Homepage/New%20Home%20page%202024/Categoreis/Mobile/Groups.webp',
    imageAlt: 'Group of skiers having fun',
    link: '/ski-holidays/group-ski-holidays',
  },
  {
    desktopOverlay: <h3>SNOWBOARDERS</h3>,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722946189/App%20Assets/Homepage/New%20Home%20page%202024/Categoreis/Snowboarders.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1724849755/App%20Assets/Homepage/New%20Home%20page%202024/Categoreis/Mobile/Snowboarders.webp',
    imageAlt: 'Snowboarder adjusting helmet on a snowy mountain with a scenic view',
    link: '/ski-holidays/snowboarding-holidays',
  },
  {
    desktopOverlay: <h3>LUXURY</h3>,
    desktopImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1722946188/App%20Assets/Homepage/New%20Home%20page%202024/Categoreis/Luxury.webp',
    mobileImageSrc:
      'https://res.cloudinary.com/ht4mr7djk/image/upload/v1724849755/App%20Assets/Homepage/New%20Home%20page%202024/Categoreis/Mobile/Luxury.webp',
    imageAlt: 'Luxury ski chalet in a snowy landscape',
    link: '/ski-holidays/luxury-ski-holidays',
  },
];
