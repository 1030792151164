import React from 'react';
import styles from './radio.module.scss';

interface Props {
  selected?: boolean;
}

const Radio: React.FC<Props> = (props) => {
  if (props.selected) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={styles.radio}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle cx="12" cy="12" r="9.5" stroke="#525D7A" />
        <circle cx="12" cy="12" r="10" fill="var(--content-accent)" />
        <circle cx="12.0001" cy="12" r="3.33333" fill="white" />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={styles.radio}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle cx="12" cy="12" r="9.5" stroke="#525D7A" />
      </svg>
    );
  }
};

export default Radio;
