export const trustPilotImg = {
  vertical:
    'https://res.cloudinary.com/ht4mr7djk/image/upload/v1663670158/App%20Assets/Homepage/trustpilotVertical.svg',
  horizontal:
    'https://res.cloudinary.com/ht4mr7djk/image/upload/v1663669946/App%20Assets/Homepage/trustpilotHorizontal.svg',
};

export const weSkiTrustPilotLink = 'https://www.trustpilot.com/review/www.weski.com';

export const assetsBasePath = `https://res.cloudinary.com/ht4mr7djk/image/upload/v1607006951/App%20Assets`;

export const avatarPlaceholder = `${assetsBasePath}/Homepage/Reviews/review-avatar-placeholder.png`;

export const reviewsSection = {
  trustpilotLogo: `${assetsBasePath}/Homepage/Reviews/trustpilot-logo.svg`,
  trustpilotRating: `${assetsBasePath}/Homepage/Reviews/stars-5.svg`,
  starRating: `${assetsBasePath}/Homepage/Reviews/star.webp`,
};
