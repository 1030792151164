'use client';

import styles from './legacy-breadcrumbs.module.scss';
import { capitalizeWords } from '../../../utils/string';
import classNames from 'classnames';
import Typography from '../typography/typography';

export default function LegacyBreadcrumbs({
  currentPathname,
  pageName,
  reloadDocument = true,
}: {
  currentPathname: string;
  pageName?: string;
  reloadDocument?: boolean;
}) {
  const pathSegments = currentPathname.split('/').filter((segment) => segment !== '');

  let currentPath = '';
  const allPaths = ['/'];

  for (const segment of pathSegments) {
    currentPath += '/' + segment;
    allPaths.push(currentPath);
  }

  return (
    <ol className={styles.BreadcrumbWrapper} itemScope itemType={'https://schema.org/BreadcrumbList'}>
      {allPaths.map((path, idx) => {
        const page = path.split('/').pop();
        const isCurrent = idx === allPaths.length - 1;
        const name = isCurrent && pageName ? pageName : idx === 0 ? 'Home' : capitalizeWords(page!.replace(/-/g, ' '));
        return (
          <div className={styles.BreadcrumbLinkContainer} key={path + idx}>
            <li
              key={idx}
              className={classNames(styles.BreadcrumbLink, isCurrent ? styles.current : null)}
              itemScope
              itemProp={'itemListElement'}
              itemType={'https://schema.org/ListItem'}
            >
              <a
                href={path}
                itemProp={'item'}
                onClick={
                  reloadDocument
                    ? undefined
                    : (e) => {
                        e.preventDefault();
                      }
                }
              >
                <span itemProp={'name'} itemScope>
                  <Typography variant="P200">{decodeURIComponent(name)}</Typography>
                </span>
              </a>
              <meta itemProp={'position'} content={(idx + 1).toString()} />
            </li>
            {!isCurrent && (
              <Typography variant="P200" className={styles.Separator}>
                ›
              </Typography>
            )}
          </div>
        );
      })}
    </ol>
  );
}
