'use client';

import { FC } from 'react';
import styles from './article-card.module.scss';
import Icon from '../icon/icon';
import classNames from 'classnames';

interface ArticleCardProps {
  title: string;
  imgSrc: string;
  description: string;
  minTimeToRead: number;
  href: string;
  imageAlt: string;
  className?: string;
}

const ArticleCard: FC<ArticleCardProps> = ({
  title,
  description,
  minTimeToRead,
  href,
  imgSrc,
  imageAlt,
  className,
}) => {
  return (
    <a className={classNames(styles.articlePreviewWrapper, className)} href={href}>
      <img loading="lazy" src={imgSrc} alt={imageAlt} />
      <div className={styles.contentWrapper}>
        <div className={styles.upperPart}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.description}>{description}</div>
        </div>
        <div className={styles.bottomPart}>
          <div className={styles.minReadSection}>
            <Icon icon="Clock" />
            {minTimeToRead} min read
          </div>
          <div className={styles.iconSection}>
            <Icon icon="ChevronRight" />
          </div>
        </div>
      </div>
    </a>
  );
};

export default ArticleCard;
